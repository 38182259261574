import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, maringSizes } from '../../../helpers/commons';
import { CircularProgress } from '../CircularProgress';

const ButtonStyled = styled.button`
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;

    &.fullwidth{
        width: 100%;
        justify-content: center;
    }
    &.small{
        padding: 0 20px;
        min-height: 39px;
        min-width: 100px;
        font-size: 12px;
        line-height: 12px;
        border-radius: 5px;
    }
    &.small .icon{
        font-size: 11px;
        border-radius: 6px;
    }
    &.medium{
        padding: 0 30px;
        min-height: 50px;
        min-width: 140px;
        font-size: 16px;
        line-height: 16px;
    }
    &.medium .icon{
        font-size: 13px;
    }
    &.large{
        padding: 0 50px;
        min-height: 65px;
        min-width: 210px;
        font-size: 25px;
        line-height: 25px;
    }
    &.large .icon{
        font-size: 14px;
    }
    .icon{
        display: flex;
        font-size: 14px;
        line-height: 14px;
        margin-right: 7px;
    }
    &.reverse{
        flex-direction: row-reverse;
    }
    &.reverse .icon{
        margin-right: 0;
        margin-left: 7px;
    }
    
    &.default{
        color: #FFFFFF;
        background-color: #151413;
        border: 1px solid #151413;
    }
    &.default:hover,
    &.default.active{
        color: #151413;
        background-color: rgba(255, 255, 255, 0.75);
    }
    &.default.invert{
        color: #151413;
        background-color: transparent;
    }
    &.default.invert:hover,
    &.default.invert.active{
        color: #FFFFFF;
        background-color: #151413;
    }
    &.important{
        color: #FFFFFF;
        background-color: #82BE00;
        border: 1px solid #82BE00;
    }
    &.important:hover,
    &.important.active{
        color: #82BE00;
        background-color: rgba(255, 255, 255, 0.75);
    }
    &.important.invert{
        color: #82BE00;
        background-color: transparent;
    }
    &.important.invert:hover,
    &.important.invert.active{
        color: #FFFFFF;
        background-color: #82BE00;
    }

    &.cancel{
        color: #FFFFFF;
        background-color: #CF0000;
        border: 1px solid #CF0000;
    }
    &.cancel:hover,
    &.cancel.active{
        color: #CF0000;
        background-color: rgba(255, 255, 255, 0.75);
    }

    &.cancel.invert{
        color: #CF0000;
        background-color: transparent;
    }
    &.cancel.invert:hover,
    &.cancel.invert.active{
        color: #FFFFFF;
        background-color: #CF0000;
    }
    &:disabled{
        background-color: ${props => `rgba(${props.colors.primary}, 0.08)` };
        border: 1px solid ${props => `rgba(${props.colors.primary}, 0.08)` };
        cursor: default;
        &:hover{
            background-color: ${props => `rgba(${props.colors.primary}, 0.08)` };
            border: 1px solid ${props => `rgba(${props.colors.primary}, 0.08)` };
        }
    }

    &.accept{
        color: #FFFFFF;
        background-color: #007ABE;
        border: 1px solid #007ABE;
    }
    &.accept:hover,
    &.accept.active{
        color: #007ABE;
        background-color: rgba(255, 255, 255, 0.75);
    }
    &.accept.invert{
        color: #007ABE;
        background-color: transparent;
    }
    &.accept.invert:hover,
    &.accept.invert.active{
        color: #FFFFFF;
        background-color: #007ABE;
    }

    &.primary{
        color: #FFFFFF;
        background-color: ${props => `rgba(${props.colors.primary}, 1)` };
        border: 1px solid ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &.primary:hover,
    &.primary.active{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
        background-color: rgba(255, 255, 255, 0.75);
    }
    &.primary.invert{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
        background-color: transparent;
    }
    &.primary.invert:hover,
    &.primary.invert.active{
        color: #FFFFFF;
        background-color: ${props => `rgba(${props.colors.primary}, 1)` };
    }

    &.primary{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }

    &.blocked{
        color: #FFFFFF!important;
    }
    
    margin-top: ${props => props.margins ? props.margins.top ? 5 * props.margins.top + "px" : 0 : 0}!important;
    margin-bottom: ${props => props.margins ? props.margins.bottom ? 5 * props.margins.bottom + "px" : 0 : 0}!important;
    margin-left: ${props => props.margins ? props.margins.left ? 5 * props.margins.left + "px" : 0 : 0}!important;
    margin-right: ${props => props.margins ? props.snapTo === 'left' ? 'auto' : props.margins.right ? 5 * props.margins.right + "px" : 0 : 0}!important;

`
export const Button = ({ active = false, type, label, size, icon, reverse, fullwidth, invert, onClick, className, snapTo, isloading, blockActions, margins = {top: 0, bottom: 0, left: 0, right: 0}, ...props }) => {

    const _reverse = reverse ? 'reverse' : '';
    const _fullwidth = fullwidth ? 'fullwidth' : '';
    const _invert = invert ? 'invert' : '';


    return (
      <ButtonStyled
        onClick={onClick}
        colors={colors}
        type="button"
        className={`${className} ${type} ${size} ${_reverse} ${_fullwidth} ${_invert} ${blockActions ? 'blocked' : ''} ${active ? 'active' : ''}`}
        margins={margins}
        snapTo={snapTo}
        disabled={isloading || blockActions}
        {...props}
      >
        {isloading ?
        <CircularProgress 
            type={(isloading && invert) ? type : "white"} 
            size={size}
        />
        :   
        <> 
            {icon ? <div className='icon'>{icon}</div> : "" }
            {label ? <div className='label'>{label}</div> : ""}
        </>
        }
        
      </ButtonStyled>
    );
  };

  Button.propTypes = {
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    icon: PropTypes.element,
    reverse: PropTypes.bool,
    fullwidth: PropTypes.bool,
    invert: PropTypes.bool,
    className: PropTypes.string,
    snapTo: PropTypes.string,
    isloading: PropTypes.bool,
    blockActions: PropTypes.bool,
    active: PropTypes.bool,

    margins: PropTypes.shape({
        top: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
        bottom: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
        left: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
        right: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
    }),
  };
  
  Button.defaultProps = {
    reverse: false,
    invert: false,
    type: 'default',
    size: 'medium',
    icon: null,
    onClick: undefined,
    fullwidth: false,
    isloading: false,
    blockActions: false,
    active: false,
  };
