import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../helpers/commons';

const IconStyled = styled.div`
    display: flex;
    line-height: 1px;
    /* background-color: pink; */
    &.error{
        color: ${props => `rgba(${props.colors.error}, 1)` }!important;
    }
    &.small{
        font-size: 13px;
        line-height: 13px;
    }
    &.medium{
        font-size: 25px;
        line-height: 25px;
    }
    &.large{
        font-size: 35px;
        line-height: 35px;
    }
    &.label{
        font-size: 17px;
        line-height: 17px;
    }
    &.input{
        font-size: 30px;
        line-height: 30px;
        &.small{
            font-size: 25px;
            line-height: 25px;
        }
        &.medium{
            font-size: 35px;
            line-height: 35px;
        }
        &.large{
            font-size: 40px;
            line-height: 40px;
        }
    }
    &.important{
        color: ${props => `rgba(${props.colors.important}, 1)` };
    }
    &.cancel{
        color: ${props => `rgba(${props.colors.cancel}, 1)` };
    }
    &.accept{
        color: ${props => `rgba(${props.colors.accept}, 1)` };
    }
    &.default{
        color: ${props => `rgba(${props.colors.default}, 1)` };
    }
    &.primary{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
`

export const Icon = ({ uuid, size, error, icon, type, className, _key, ...props }) => {
    return (
        <IconStyled
            key={uuid}
            colors={colors}
            className={`${className} icon ${size} ${error ? 'error' : ''} ${type}`}
        >
            {icon}
        </IconStyled>
    );
};

Icon.propTypes = {
    _key: PropTypes.string,
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'label', 'input']),
    icon: PropTypes.element,
    error: PropTypes.bool,
    className: PropTypes.string,
    scope: PropTypes.string,
    uuid: PropTypes.string,
};

Icon.defaultProps = {
    type: 'default',
    icon: null,
    size: 'medium',
    error: false,
    className: 'icon',
    scope: null,
};