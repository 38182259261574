import React, { useState, useEffect } from 'react';

import { WrapperStyled, CategoryContainer, CategoryLabel, SubcategoryList, SubcategoryItem} from './styles';
import { colors } from '../../helpers/commons';
import { useNavigate, useLocation } from 'react-router-dom';

const MenuNav = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const { menu,
            sidebarOpen,
            setSidebarOpen} = props;

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [ markCategorySelected, setMarkCategorySelected ] = useState("");
    const [ markSubCategorySelected, setMarkSubCategorySelected ] = useState("");

    const handleCategoryClick = (categoryId, neverClose, blind) => {
        //console.log("categoryId", categoryId)
        if(!blind){
            setMarkCategorySelected(categoryId);
        }
        setSelectedCategory(categoryId === selectedCategory ? neverClose ? categoryId : null : categoryId);
    };
    const handleSubCategoryClick = (categoryId, blind) => {
        if(!blind){
            setMarkSubCategorySelected(categoryId);
        }
        setSelectedSubCategory(categoryId === selectedCategory ? null : categoryId);
    };

    const handleGoTo = (url, type, external) => {
        if(type === 'category'){
            //console.log("SelectedSubCategory", selectedSubCategory)
            setSelectedSubCategory(null);
        }else{

        }
        if(external){
            window.open(url, '_blank', 'noopener,noreferrer');
        }else{
            navigate(`${url}`);
        }
    }

    useEffect(() => {

        if(menu && Object.keys(menu).length){
            Object.keys(menu).map((key) => {
                if(menu[key].url === location.pathname){
                    console.log("--->", menu[key].url, location.pathname, menu[key].id)
                    setSelectedCategory(menu[key].id);
                    setMarkCategorySelected(menu[key].id);
                }
                if(Object.keys(menu[key].subcategories).length){
                    Object.keys(menu[key].subcategories).map((sub) => {
                        if(menu[key].subcategories[sub].url === location.pathname){
                            console.log("**")
                            setSelectedCategory(menu[key].id);
                            setSelectedSubCategory(menu[key].subcategories[sub].id);
                        }
                    })
                }
            })
            //console.log("location.pathname", location.pathname, menu)
            switch (location.pathname) {
                case '/admin/debit-cards':
                    
                    

                    break;
            
                default:
                    break;
            }
        }

        return () => {}

    }, [location.pathname, menu])

    useEffect(() => {
      //console.log("sidebarOpen", sidebarOpen)
    
      return () => {}
    }, [sidebarOpen])

    if(!Object.keys(menu).length){
        return null;
    }
    

    return (
        <WrapperStyled
            colors={colors}
            sidebar_open={sidebarOpen}
        >
            {menu && Object.keys(menu).length ?
                Object.keys(menu).map(function(key, index) { 
                    return(
                    <CategoryContainer 
                        colors={colors}
                        key={`category-${menu[key].id}`}
                    >
                        <CategoryLabel
                            colors={colors}
                            selected={markCategorySelected === menu[key].id}
                            className={`${markCategorySelected === menu[key].id ? 'selected' : ''}${sidebarOpen ? " open" : " hide"}`}
                            onClick={() => { handleCategoryClick(menu[key].id, menu[key].url.length ? true : false, menu[key].blind); /*!Object.keys(menu[key].subcategories).length*/ menu[key].url.length && handleGoTo(menu[key].url, 'category') }}
                        >
                            {menu[key].icon ? 
                                <div
                                    className={"icon"}
                                >
                                    { menu[key].icon.component }
                                </div>
                            : ""}
                            <div
                                className={`button_label`}
                            >
                                {menu[key].label}
                            </div>
                        </CategoryLabel>

                        {selectedCategory === menu[key].id && Object.keys(menu[key].subcategories).length ? (
                            <SubcategoryList
                                colors={colors}
                                className={`${sidebarOpen ? " open" : " hide"}`}
                            >
                            {Object.keys(menu[key].subcategories).map(function(skey) {
                                return(
                                    <SubcategoryItem 
                                        colors={colors}
                                        key={menu[key].subcategories[skey].id}
                                        className={`${markSubCategorySelected === menu[key].subcategories[skey].id ? 'selected' : ''}${sidebarOpen ? " open" : " hide"}`}
                                        onClick={() => {handleSubCategoryClick(menu[key].subcategories[skey].id, menu[key].subcategories[skey].blind); handleGoTo(menu[key].subcategories[skey].url, "", menu[key].subcategories[skey].external)}}
                                    >
                                        {menu[key].icon ? 
                                            <div
                                                className={"icon medium  default"}
                                            >
                                                {menu[key].subcategories[skey].icon.component}
                                            </div>
                                        : ""}
                                        <div
                                            className={`button_label`}
                                        >
                                            {menu[key].subcategories[skey].label}
                                        </div>
                                    </SubcategoryItem>
                                )
                            })}
                            </SubcategoryList>
                        ) : ""}

                    </CategoryContainer>
                    
                    )
            })
            : ""}
        </WrapperStyled>
    );
};

export default MenuNav;