import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { colors } from '../../helpers/commons';

export const WrapperStyled = styled.div`
    .loader{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
        svg{
            color: #202960;//rgba(0, 104, 157, 1);
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        width: 100%;
        border: 1px solid #e5e5e5;
        padding: 15px 15px;
        .nodata {
            width: 100%;
            text-align: center;
            padding-top: 25px;
            padding-bottom: 25px;
            font-size: 20px;
            line-height: 20px;
            font-weight: 500;
        }
        .top{
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .months{
                display: flex;
                flex-direction: row;
                :last-child{
                    margin-right: 0!important;
                }
                .month{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 500;
                    height: 30px;
                    width: 45px;
                    color: rgba(${colors.white}, 1);
                    background-color: rgba(${colors.darkGrey}, 1);
                    margin-right: 5px;
                    cursor: pointer;
                    &.selected{
                        background-color: rgba(${colors.darkBlue}, 1);
                    }
                    &:hover{
                        background-color: rgba(${colors.darkBlue}, 1);
                    }
                }
            }
        }
        .bottom{
            display: flex;
            width: 100%;
            .table{
                width: 100%;
                border: 1px solid rgba(${colors.darkGrey}, 1);
                margin-top: 15px;
                .head{
                    display: flex;
                    flex-direction: row;
                    background-color: rgba(${colors.darkBlue}, 1);
                    color: rgba(${colors.white}, 1);
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 18px;
                    .column{
                        padding: 20px 10px;
                        text-align: center;
                        margin-left: 15px;//1513
                        border-left: 1px solid rgba(${colors.darkGrey}, 1);
                        &:first-child{
                            border-left: 0;
                            margin-left: 0;
                        }
                    }
                }
                .rows{
                    display: flex;
                    flex-direction: column;
                    background-color: rgba(${colors.white}, 1);
                    color: rgba(${colors.darkBlue}, 1);
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 18px;
                    .row{
                        display: flex;
                        flex-direction: row;
                        div{
                            padding: 20px 10px;
                            margin-left: 15px;
                            border-left: 1px solid rgba(${colors.darkGrey}, 1);
                            &:first-child{
                                border-left: 0;
                                margin-left: 0;
                            }
                        }
                        &.gray{
                            background-color: rgba(${colors.lightGrey}, 1);
                        }
                    }
                }
                .product{
                    width: 250px;
                }
                .date{
                    width: 150px;
                }
                .description{
                    width: 400px;
                }
                .quantity{
                    width: 210px;
                }
                .value{
                    width: 210px;
                }
                .isin{
                    width: 216px;
                }
            }
        }
    }

    @media ${breakpoints.xs} {//576px
    }
    @media ${breakpoints.sm} {//768px
    }
    @media ${breakpoints.md} {//992px
        
    }
    @media ${breakpoints.lg} {//1200px // Desktop
        
    }
    @media ${breakpoints.xl} {//1366px
    }
    @media ${breakpoints.xxl} {//1440px
    }
    @media ${breakpoints.xxxl} {//1680px
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`