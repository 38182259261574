import React, { useContext, useState, useEffect } from 'react';
import { useNavigate,
         useLocation } from "react-router-dom";
import { AuthContext, 
         ContentContext } from '../../context';
import { WrapperStyled } from './styles';
import { ButtonCustom } from '../../components/ButtonCustom';
import { HiOutlineArrowNarrowUp } from "react-icons/hi";
import { number_format } from '../../helpers/commons';

const Dashboard = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const { logout, user } = useContext(AuthContext);
    const { get_user_trust_fund,
            get_user_account_detail } = useContext(ContentContext);

    const [ monthSelected, setMonthSelected ] = useState("");
    const [ accountDetail, setAccountDetail ] = useState(null);
    const [ beginning, setBeginning ] = useState("");
    const [ ending, setEnding ] = useState("");
    
    const [ trustBeginning, setTrustBeginning ] = useState("");
    const [ trustEnding, setTrustEnding ] = useState("");
    const [ percentReturn, setPercentReturn ] = useState("");

    const handleChangeMonth = (month) => {

        handleGetData({month_range: month});
        setMonthSelected(month);

    }

    useEffect(() => {
        
        handleGetData({month_range: 0});
    
      return () => {}
    }, [])

    const handleGetData = (filters = null) => {

        try {
            
            const response_account_detail = get_user_account_detail();
            response_account_detail.then(res => {
                
                setBeginning(res.data.beginning);
                setEnding(res.data.ending);

                let cash = 0;
                let fixed_income = 0;
                let net_custodied_assets = 0;
                let equities = 0;

                for (let i = 0; i < res.data.records.length; i++) {
                    cash += res.data.records[i].cash;
                    fixed_income += res.data.records[i].fixed_income;
                    net_custodied_assets += res.data.records[i].net_custodied_assets;
                    equities += res.data.records[i].equities;
                }

                setAccountDetail({ cash: cash,
                                fixed_income: fixed_income,
                                net_custodied_assets: net_custodied_assets,
                                equities: equities });
                                
            })

        } catch (error) {
            
        }

        try {
            
            const response_trust_fund = get_user_trust_fund(filters);
            response_trust_fund.then(res => {

                let trust_beginning = res.data.record.beginning_value;
                let trust_ending = res.data.record.ending_value;

                setTrustBeginning(trust_beginning);
                setTrustEnding(trust_ending);

                let beginningValue = trust_beginning;
                let endingValue = trust_ending;
                let percentageChange = 0;

                if(endingValue && beginningValue){
                    percentageChange = ((endingValue - beginningValue) / beginningValue) * 100;
                    setPercentReturn(percentageChange.toFixed(2));
                }else{
                    setPercentReturn("0");
                }
                //console.log(percentageChange.toFixed(2) + '%');

            })

        } catch (error) {
            
        }

        

    }

    const handleGotoPerformance = () => {

        navigate("/performance");

    }

    /*
    useEffect(() => {
        //console.log("Client Dashboard",user,  !user.is_admin, user && !user.is_admin)
        const response_account_detail = get_user_account_detail();
        response_account_detail.then(res => {
            
            setBeginning(res.data.beginning);
            setEnding(res.data.ending);

            let cash = 0;
            let fixed_income = 0;
            let net_custodied_assets = 0;
            let equities = 0;

            for (let i = 0; i < res.data.records.length; i++) {
                cash += res.data.records[i].cash;
                fixed_income += res.data.records[i].fixed_income;
                net_custodied_assets += res.data.records[i].net_custodied_assets;
                equities += res.data.records[i].equities;
            }

            setAccountDetail({ cash: cash,
                               fixed_income: fixed_income,
                               net_custodied_assets: net_custodied_assets,
                               equities: equities });
                               
        })

        const response_trust_fund = get_user_trust_fund();
        response_trust_fund.then(res => {
            console.log("response_trust_fund", res)

            let trust_beginning = 0;
            let trust_ending = 0;

            for (let i = 0; i < res.data.records.length; i++) {
                trust_beginning += res.data.records[i].beginning_value;
                trust_ending += res.data.records[i].ending_value;
            }

            setTrustBeginning(trust_beginning);
            setTrustEnding(trust_ending);

            let beginningValue = trust_beginning;
            let endingValue = trust_ending;

            let percentageChange = ((endingValue - beginningValue) / beginningValue) * 100;
            setPercentReturn(percentageChange.toFixed(2));
            console.log(percentageChange.toFixed(2) + '%');

        })

        return () => {}
      }, [])
    */

    if(user && user.is_admin){
        return null;
    }

    return (
        <WrapperStyled>
            <div className='boxes'>
                <div className='box summary'>
                    <div className='top'>
                        <div className='date_range'>{beginning} to {ending}</div>
                    </div>
                    <div className='bottom'>
                        <div className='title'>Account Detail</div>
                        <div className='subtitle'>Summary</div>
                        <div className='items'>
                            <div className='item grey'>
                                <div className='label'>Cash</div>
                                <div className='value'>{`${accountDetail ? "USD " + number_format(accountDetail.cash, 0 ) : ""}`}</div>
                            </div>
                            <div className='item'>
                                <div className='label'>Fixed Income</div>
                                <div className='value'>{`${accountDetail ? "USD " + number_format(accountDetail.fixed_income, 0) : ""}`}</div>
                            </div>
                            <div className='item grey'>
                                <div className='label'>Equities</div>
                                <div className='value'>{`${accountDetail ? "USD " + number_format(accountDetail.equities, 0) : ""}`}</div>
                            </div>
                            <div className='item'>
                                <div className='label'>Net Custodied Assets</div>
                                <div className='value'>{`${accountDetail ? "USD " + number_format(accountDetail.net_custodied_assets, 0) : ""}`}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='box performance'>
                    <div className='top'>
                        <div className='months'>
                            <div className={`month${monthSelected === 0 ? ' selected' : ''}`} onClick={() => handleChangeMonth(0)}>1M</div>
                            <div className={`month${monthSelected === 6 ? ' selected' : ''}`} onClick={() => handleChangeMonth(6)}>6M</div>
                            <div className={`month${monthSelected === 12 ? ' selected' : ''}`} onClick={() => handleChangeMonth(12)}>12M</div>
                        </div>
                    </div>
                    <div className='bottom'>
                        <div className='title'>Trust fund</div>
                        <div className='subtitle'>Performance</div>
                        <div className='return'>
                            <div className='return_value'>
                                <div className='icon up'><HiOutlineArrowNarrowUp /></div>
                                <div className='value'>{percentReturn}<span className='percent'>%</span></div>
                            </div>
                            <div className='label'>Return</div>
                        </div>
                        <div className='value_range'>
                            <div className='beginning'>
                                <div className='title'>Beginning value</div>
                                <div className='inner_value'>
                                    <div className='value'>{number_format(trustBeginning, 0)}</div>
                                    <div className='currency'>usd</div>
                                </div>
                            </div>
                            <div className='vertical_division'></div>
                            <div className='ending'>
                                <div className='title'>Ending value</div>
                                <div className='inner_value'>
                                    <div className='value'>{number_format(trustEnding, 0)}</div>
                                    <div className='currency'>usd</div>
                                </div>
                            </div>
                        </div>
                        <div className='actions'>
                            <ButtonCustom 
                                label="SEE DETAILED"
                                className="see_detailed"
                                onClick={handleGotoPerformance}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </WrapperStyled>
    );
};

export default Dashboard;