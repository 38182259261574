import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../../Atoms/Button/Button';
import { CircularProgress } from '../../Atoms/CircularProgress';

const ActionsStyled = styled.div`
    display: flex;
    flex-direction: row;
    
    margin-top: ${props => props.margins.top ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => props.margins.bottom ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => props.margins.left ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => props.margins.right ? 5 * props.margins.right + "px" : 0}!important;

    &.left{
        justify-content: flex-start;
    }
    &.right{
        justify-content: flex-end;
    }
    &.center{
        justify-content: center;
    }

    &.top{
        align-items: flex-start;
    }
    &.bottom{
        align-items: flex-end;
    }
    >.loader{
        height: 50px;
    }

`
const ActionsForm = ({ isloading, actions, className, halign, valign, margins = {top: 0, bottom: 0, left: 0, right: 0} }) => {
    return (
        <ActionsStyled
            margins={margins}
            className={`${className} ${halign} ${valign}`}
        >   
            {isloading ?
            <CircularProgress 
                className={"loader"}
                type={"important"} 
                size={"medium"}
            />
            :
            Object.keys(actions).length ? 
                Object.keys(actions).map((key, index) => {
                    
                    if(!actions[key]){ return null; }
                    
                    return (
                        <Button 
                            {...actions[key]}
                        />)
                })
            : ""}
        </ActionsStyled>
    );
};

ActionsForm.propTypes = {
    actions: PropTypes.object,
    margins: PropTypes.shape({
        top: PropTypes.oneOf([1, 2, 3, 4, 5]),
        bottom: PropTypes.oneOf([1, 2, 3, 4, 5]),
        left: PropTypes.oneOf([1, 2, 3, 4, 5]),
        right: PropTypes.oneOf([1, 2, 3, 4, 5]),
    }),
    halign: PropTypes.string,
    valign: PropTypes.string,
    className: PropTypes.string,
    isloading: PropTypes.bool,
};

export default ActionsForm;
 