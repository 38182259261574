import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, maringSizes } from '../../../helpers/commons';

const WrapperStyled = styled.div`
    width: 100%;
    background-color: #FFFFFF;
`

const TableBody = ({children, className}) => {
    return (
        <WrapperStyled
            className={`${className}`}
        >
            {children}
        </WrapperStyled>
    );
};

TableBody.propTypes = {
    columnsTitle: PropTypes.object,
    className: PropTypes.string,
};

export default TableBody;