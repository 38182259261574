import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../helpers/commons';

const SwitchStyled = styled.div`
    
    display: inline-block;
    width: 50px;
    height: 30px;
    border-radius: 19px;
    background-color: #d1d1d1;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid #d1d1d1;/*${props => `rgba(${props.colors.primary}, 1)` };*/
    .switch-bot {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: white;
        transition: transform 0.3s;
        cursor: pointer;
    }

    &.activo {
        background-color: #4cd964;
        .switch-bot {
            transform: translateX(20px);
        }

        &.important{
            background-color: ${props => `rgba(${props.colors.important}, 1)` };
        }
        &.cancel{
            background-color: ${props => `rgba(${props.colors.cancel}, 1)` };
        }
        &.accept{
            background-color: ${props => `rgba(${props.colors.accept}, 1)` };
        }
        &.default{
            background-color: ${props => `rgba(${props.colors.default}, 1)` };
        }
        &.primary{
            background-color: ${props => `rgba(${props.colors.primary}, 1)` };
        }

    }

    &.small{
        select{
            padding: 10px 10px;
            font-size: 12px;
        }
    }
    &.medium{
        select{
            padding: 15px 15px;
            font-size: 15px;
        }
    }
    &.large{
        select{
            padding: 15px 15px;
            font-size: 18px;
        }
    }

    
    &.error{
        border: 1px solid ${props => `rgba(${props.colors.error}, 1)` };
    }

`

export const Switch = ({active, type, className, size, error, name, onClick, ...props}) => {
    return (
        <SwitchStyled 
            colors={colors}
            className={`switch ${className} ${active ? 'activo' : ''} ${type} ${size} ${error ? 'error' : ''}`}
            onClick={(e) => onClick(!active, name)}
        >
            <div className="switch-bot"></div>
        </SwitchStyled>
    );
};
//const [activo, setActivo] = useState(false);
// Switch.propTypes = {
//     active: PropTypes.bool,
//     size: PropTypes.oneOf(['small', 'medium', 'large']),
//     type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
//     className: PropTypes.string,
//     error: PropTypes.bool,
// };