import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { colors } from '../../helpers/commons';

export const WrapperStyled = styled.div`
    .loader{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        svg{
            color: #202960;//rgba(0, 104, 157, 1);
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        width: 100%;
        border: 1px solid #e5e5e5;
        padding: 15px 30px;
        height: calc(100vh - 90px);
        .top{
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .months{
                display: flex;
                flex-direction: row;
                :last-child{
                    margin-right: 0!important;
                }
                .month{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 500;
                    height: 30px;
                    width: 45px;
                    color: rgba(${colors.white}, 1);
                    background-color: rgba(${colors.darkGrey}, 1);
                    margin-right: 5px;
                    cursor: pointer;
                    &.selected{
                        background-color: rgba(${colors.darkBlue}, 1);
                    }
                    &:hover{
                        background-color: rgba(${colors.darkBlue}, 1);
                    }
                }
            }
        }
        .scrollbar{
            display: flex;
            height: calc(100%)!important;
        }
        .bottom{
            display: flex;
            width: 100%;
            height: 100%;
            .table{
                width: 100%;
                border: 1px solid rgba(${colors.darkGrey}, 1);
                margin-top: 15px;
                .nodata{
                    width: 100%;
                    text-align: center;
                    padding-top: 40px;
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 500;
                }
                .head{
                    /* position: fixed; */
                    display: flex;
                    flex-direction: row;
                    background-color: rgba(${colors.darkBlue}, 1);
                    color: rgba(${colors.white}, 1);
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 13px;
                    width: 800px;
                    .column{
                        padding: 10px 5px;
                        text-align: center;
                        //margin-left: 15px;//1513
                        border-left: 1px solid rgba(${colors.darkGrey}, 1);
                        &:first-child{
                            border-left: 0;
                            margin-left: 0;
                        }
                        /* &.description{
                            width: calc(100% - 450px);
                        } */
                    }
                }
                .rows{
                    display: flex;
                    flex-direction: column;
                    background-color: rgba(${colors.white}, 1);
                    color: rgba(${colors.darkBlue}, 1);
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 13px;
                    height: 100%;
                    width: 800px;
                    /* padding-top: 55px; */
                    .row{
                        display: flex;
                        flex-direction: row;
                        //border-bottom: 1px solid rgba(148, 148, 148, 1);
                        div{
                            padding: 10px 5px;
                            //margin-left: 15px;
                            border-left: 1px solid rgba(${colors.darkGrey}, 1);
                            &:first-child{
                                border-left: 0;
                                margin-left: 0;
                            }
                        }
                        &.gray{
                            background-color: rgba(${colors.lightGrey}, 1);
                        }
                    }
                }
                .product{
                    width: 130px;
                }
                .date{
                    width: 100px;
                }
                .description{
                    width: 270px;
                }
                .quantity{
                    width: 90px;
                }
                .value{
                    width: 100px;
                }
                .isin{
                    width: 126px;
                }
            }
        }
    }

    @media ${breakpoints.xs} {//576px
        .content{
            .bottom{
                .table{
                    .head{
                        width: 1000px;
                        font-size: 15px;
                        line-height: 15px;
                        .column{
                            padding: 15px 10px;
                        }
                    }
                    .rows{
                        width: 1000px;
                        font-size: 15px;
                        line-height: 15px;
                        .row{
                            div{
                                padding: 15px 10px;
                            }
                        }
                    }
                    .product{
                        width: 170px;
                    }
                    .date{
                        width: 150px;
                    }
                    .description{
                        width: 280px;
                    }
                    .quantity{
                        width: 130px;
                    }
                    .value{
                        width: 130px;
                    }
                    .isin{
                        width: 156px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.sm} {//768px
        .content{
            .bottom{
                .table{
                    .head{
                        width: 1000px;
                        font-size: 15px;
                        line-height: 15px;
                        .column{
                            padding: 15px 10px;
                        }
                    }
                    .rows{
                        width: 1000px;
                        font-size: 15px;
                        line-height: 15px;
                        .row{
                            div{
                                padding: 15px 10px;
                            }
                        }
                    }
                    .product{
                        width: 170px;
                    }
                    .date{
                        width: 150px;
                    }
                    .description{
                        width: 280px;
                    }
                    .quantity{
                        width: 130px;
                    }
                    .value{
                        width: 130px;
                    }
                    .isin{
                        width: 156px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.md} {//992px
        .content{
            .bottom{
                .table{
                    .head{
                        width: auto;
                        font-size: 13px;
                        line-height: 13px;
                        .column{
                            padding: 10px 5px;
                        }
                    }
                    .rows{
                        width: auto;
                        font-size: 13px;
                        line-height: 13px;
                        .row{
                            div{
                                padding: 10px 5px;
                            }
                        }
                    }
                    .product{
                        width: 140px;
                    }
                    .date{
                        width: 100px;
                    }
                    .description{
                        width: 220px;
                    }
                    .quantity{
                        width: 110px;
                    }
                    .value{
                        width: 110px;
                    }
                    .isin{
                        width: 120px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.lg} {//1200px // Desktop
        .content{
            .bottom{
                .table{
                    .head{
                        font-size: 15px;
                        line-height: 15px;
                        .column{
                            padding: 20px 20px;
                        }
                    }
                    .rows{
                        font-size: 15px;
                        line-height: 15px;
                        .row{
                            div{
                                padding: 20px 20px;
                            }
                        }
                    }
                    .product{
                        width: 170px;
                    }
                    .date{
                        width: 135px;
                    }
                    .description{
                        width: 270px;
                    }
                    .quantity{
                        width: 130px;
                    }
                    .value{
                        width: 130px;
                    }
                    .isin{
                        width: 156px;
                    }
                }
            }
        }
        
    }
    @media ${breakpoints.xl} {//1366px
        .content{
            .bottom{
                .table{
                    .head{
                        font-size: 16px;
                        line-height: 16px;
                    }
                    .rows{
                        font-size: 16px;
                        line-height: 16px;
                    }
                    .product{
                        width: 190px;
                    }
                    .date{
                        width: 150px;
                    }
                    .description{
                        width: 280px;
                    }
                    .quantity{
                        width: 160px;
                    }
                    .value{
                        width: 160px;
                    }
                    .isin{
                        width: 216px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.xxl} {//1440px
        .content{
            .bottom{
                .table{
                    .product{
                        width: 200px;
                    }
                    .date{
                        width: 150px;
                    }
                    .description{
                        width: 285px;
                    }
                    .quantity{
                        width: 180px;
                    }
                    .value{
                        width: 200px;
                    }
                    .isin{
                        width: 216px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.xxxl} {//1680px
        .content{
            .bottom{
                .table{
                    .product{
                        width: 250px;
                    }
                    .date{
                        width: 150px;
                    }
                    .description{
                        width: 430px;
                    }
                    .quantity{
                        width: 210px;
                    }
                    .value{
                        width: 210px;
                    }
                    .isin{
                        width: 216px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.xxxxl} {//1920px
        .content{
            .bottom{
                .table{
                    .product{
                        width: 250px;
                    }
                    .date{
                        width: 150px;
                    }
                    .description{
                        width: 670px;
                    }
                    .quantity{
                        width: 210px;
                    }
                    .value{
                        width: 210px;
                    }
                    .isin{
                        width: 216px;
                    }
                }
            }
        }
    }
`