import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { path } from 'ramda';

import { TfiStatsUp } from "react-icons/tfi";
import { BsEgg } from "react-icons/bs";
import { ImStatsBars } from "react-icons/im";
import { GrTransaction } from "react-icons/gr";
import { RiExchangeDollarLine } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { GrDocumentPerformance } from "react-icons/gr";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { MdAccountBalance } from "react-icons/md";


import { PiTreeStructure } from "react-icons/pi";
import { IoWalletOutline } from "react-icons/io5";
import { AiOutlineTeam } from "react-icons/ai";
import { AiOutlineDollar } from "react-icons/ai";
import { GiCorn } from "react-icons/gi";
import { LiaShippingFastSolid } from "react-icons/lia";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { FaSeedling, FaTools, FaRoute } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import { BsLink45Deg } from "react-icons/bs";
import { FiGift } from "react-icons/fi";

import { VscTools } from "react-icons/vsc";
import { RiSeedlingLine } from "react-icons/ri";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { LiaRouteSolid } from "react-icons/lia";
import { TbMapRoute } from "react-icons/tb";




import { MdSpaceDashboard,
         MdSpeed } from "react-icons/md";


import { MdHouseSiding } from "react-icons/md";


axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
        error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(response => {
    return response;
  }, error => {
    //alert("asasd")
    if (error.response && error.response.status === 401) {
        localStorage.clear();
    }
    return Promise.reject(error);
  });

export const ContentContext = React.createContext({});

export const ContentProvider = ({ children }) => {

    const [ clients, setClients ] = useState(null);
    
    const [ portfolio, setPortfolio ] = useState(null);
    const [ debitCard, setDebitCard ] = useState(null);
    const [ cashflow, setCashflow ] = useState(null);
    const [ accountDetail, setAccountDetail ] = useState(null);
    const [ performance, setPerformance ] = useState(null);
    const [ trustFund, setTrustFund ] = useState(null);
    
    const [ users, setUsers ] = useState(null);
    const [ menu, setMenu ] = useState({});
    

    const getMenu = (user) => {
        //console.log("getMenu", user)
        let menu = {};
        
        
        
        if(user.is_admin){
            menu = {
                dashboard:{
                    id: 0,
                    label: "Dashboard",
                    url: "/admin/dashboard",
                    icon: {component: <MdSpaceDashboard />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                users:{
                    id: 4,
                    label: "Users",
                    url: "/admin/users",
                    icon: {component: <FaUsers />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                account_detail:{
                    id: 1,
                    label: "Account Detail",
                    url: "/admin/account-detail",
                    icon: {component: <MdAccountBalance />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                trust_fund:{
                    id: 2,
                    label: "Trust fund",
                    url: "/admin/trust-fund",
                    icon: {component: <VscWorkspaceTrusted />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                performance:{
                    id: 3,
                    label: "Performance",
                    url: "/admin/performance",
                    icon: {component: <GrDocumentPerformance />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                portfolio:{
                    id: 5,
                    label: "Portfolio",
                    url: "/admin/portfolio",
                    icon: {component: <TfiStatsUp />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                transactions: {
                    id: 6,
                    label: "Debit Cards",
                    component: "",
                    url: "/admin/debit-cards",
                    icon: {component: <GrTransaction />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                cash_flow: {
                    id: 7,
                    label: "Projected Cash Flow",
                    component: "",
                    url: "/admin/cash-flow",
                    icon: {component: <RiExchangeDollarLine />, custom: false},
                    blind: false,
                    subcategories: {}
                },
            };
        }else{
            menu = {
                dashboard:{
                    id: 0,
                    label: "Dashboard",
                    url: "/dashboard",
                    //icon: <MdSpaceDashboard />,
                    icon: {component: <MdSpaceDashboard />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                portfolio:{
                    id: 1,
                    label: "Portfolio",
                    //component: <Analitycs />,
                    url: "/portfolio",
                    //icon: <TfiStatsUp />,
                    icon: {component: <TfiStatsUp />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                performance:{
                    id: 2,
                    label: "Performance",
                    component: "",
                    url: "/performance",
                    //icon: <BsEgg />,
                    icon: {component: <ImStatsBars />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                transactions: {
                    id: 3,
                    label: "Debit Cards",
                    component: "",
                    url: "/debit-cards",
                    icon: {component: <GrTransaction />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                cash_flow: {
                    id: 4,
                    label: "Projected Cash Flow",
                    component: "",
                    url: "/cash-flow",
                    icon: {component: <RiExchangeDollarLine />, custom: false},
                    blind: false,
                    subcategories: {}
                },
                benefits: {
                    id: 5,
                    label: "Our Benefits",
                    component: "",
                    url: "/benefits",
                    icon: {component: <FiGift />, custom: false},
                    blind: false,
                    subcategories: {}
                },
            };
        }

        setMenu(menu);
        
    }






    const getUsername = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/utils/username`;
        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, config);
            return result;

        } catch (error) {}

    }, []);

    const getCashflow = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/cashflow`;
        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });
            return result;

        } catch (error) {}

    }, []);

    const createCashflow = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/cashflow`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);
    
    const editCashflow = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/cashflow/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    
    const deleteCashflow = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/cashflow/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id, bulk: payload.bulk } });//JSON.stringify(payload)
        return result;

    }, []);


    


    const getAccountDetail  = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/account-detail`;
        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });
            return result;

        } catch (error) {}

    }, []);

    const createAccountDetail  = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/account-detail`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);
    
    const editAccountDetail  = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/account-detail/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    
    const deleteAccountDetail  = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/account-detail/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id, bulk: payload.bulk } });//JSON.stringify(payload)
        return result;

    }, []);

    



    


    const getPerformance  = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/performance`;
        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });
            return result;

        } catch (error) {}

    }, []);

    const createPerformance  = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/performance`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);
    
    const editPerformance  = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/performance/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    
    const deletePerformance  = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/performance/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id, bulk: payload.bulk } });//JSON.stringify(payload)
        return result;

    }, []);



    
    

    const getTrustFund  = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/trust-fund`;
        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });
            return result;

        } catch (error) {}

    }, []);

    const createTrustFund  = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/trust-fund`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);
    
    const editTrustFund  = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/trust-fund/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    
    const deleteTrustFund  = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/trust-fund/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id, bulk: payload.bulk } });//JSON.stringify(payload)
        return result;

    }, []);


    



    const getUsers = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/users`;
        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });
            return result;

        } catch (error) {}

    }, []);

    const createUsers = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/users`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);
    
    const editUsers = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/users/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    
    const deleteUsers = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/users/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id, bulk: payload.bulk } });//JSON.stringify(payload)
        return result;

    }, []);









    

    
    const getDebitCard = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/debitcard`;
        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });
            return result;

        } catch (error) {}

    }, []);

    const createDebitCard = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/debitcard`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);
    
    const editDebitCard = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/debitcard/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    
    const deleteDebitCard = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/debitcard/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id, bulk: payload.bulk } });//JSON.stringify(payload)
        return result;

    }, []);

    const getPortfolio = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/portfolio`;

        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });
            //console.log("** records", path(['data', 'records'], result))
            /*
            if(result){
                let records = path(['data', 'records'], result);
                setPortfolio(records);
            }
            */

            return result;

        } catch (error) {

        }

    }, []);

    const createPortfolio = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/portfolio`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);

    const editPortfolio = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/portfolio/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    
    const deletePortfolio = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/portfolio/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id, bulk: payload.bulk } });//JSON.stringify(payload)
        return result;

    }, []);

    const get_data = useCallback(async () => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/utils/data`;

        try {

            const result = await axios.get(requestURL);
            const data = path(['data', 'records'], result);
            setClients(data.clients);
            //console.log("data", data);
            
            // if(data){
            // }

            return result;

        } catch (error) {

        }

    }, []);

    const change_password = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/users/change_password`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${payload.token}`
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);

    const get_user_trust_fund = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/user/trust-fund`;

        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });

            return result;

        } catch (error) {

        }

    }, []);

    const get_user_account_detail = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/user/account-detail`;

        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });

            return result;

        } catch (error) {

        }

    }, []);

    const get_user_portfolio = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/user/portfolio`;

        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });

            return result;

        } catch (error) {

        }

    }, []);

    const get_user_performance = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/user/performance`;

        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });

            return result;

        } catch (error) {

        }

    }, []);

    const get_user_debitcard = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/user/debitcard`;

        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });

            return result;

        } catch (error) {

        }

    }, []);

    const get_user_cashflow = useCallback(async (filter = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/user/cashflow`;

        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL, { params: filter });

            return result;

        } catch (error) {

        }

    }, []);

    useEffect(() => {
        //setMenu({});
        if(localStorage.getItem('token')){
            if(localStorage.getItem('token').length){
                get_data();
            }
        }
      return () => { }
    }, [localStorage.getItem('token')])

    /*
    useEffect(() => {
        //localStorage.clear();
        if(localStorage.getItem('token')){
          //getStardardData();
          //getData();
        }
        return () => {}
      }, [localStorage.getItem('token')])
    */

    /*
    const getLayingHouses = useCallback(async (laying_houses = null) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses`;

        try {
            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL);
            
            if(result){
                setLayingHouses(result.data.records);//JSON.parse(json)
            }

            return result;

        } catch (error) {

        }

    }, []);

    const getLayingHouseAnalitycs = useCallback(async (payload) => {
        const params = payload.params;
        const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
        const requestURL = `${process.env.REACT_APP_BASE_URL}/analitycs/laying_houses/${payload.id}?${queryString}`;
        const result = await axios.get(requestURL);
        return result;

    }, []);

    const getLayingHousesRecods = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses_records/${payload.laying_houses_id}`;

        try {

            const result = await axios.get(requestURL);
            const data = path(['data', 'records'], result);

            if(data){
                setLayingHousesRecords(data);
            }

            return result;

        } catch (error) {

        }

    }, []);

    const saveLayingHouses = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);
    
    const editLayingHouses = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    const deleteLayingHouses = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id } });
        return result;

    }, []);

    const saveLayingHousesRecord = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses_records`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);

    const editLayingHousesRecord = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses_records/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    const editLayingHousesWeight = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses/${payload.id}/weight`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    const deleteLayingHousesRecord = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses_records/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id } });//JSON.stringify(payload)
        return result;

    }, []);
    
    const getData = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/data`;
        const result = await axios.post(requestURL);
        
        setSuppliers(result.data.data.suppliers);
        setProducts(result.data.data.products);
        setItems(result.data.data.items);
        setLayingHousesList(result.data.data.laying_houses);
        setUnits(result.data.data.units);
        setProvinces(result.data.data.provinces);

        setServiceCategories([{ value: 'logistic', label: 'Logística', disabled: false, selected: false, hidden: false},
                              { value: 'administration', label: 'Cargas Impositivas y Administración', disabled: false, selected: false, hidden: false },
                              { value: 'food', label: 'Alimento, Medicación y Proveedores', disabled: false, selected: false, hidden: false },
                              { value: 'fixes', label: 'Arreglos, Manutención e Inversión', disabled: false, selected: false, hidden: false },
                              { value: 'miscellaneous', label: 'Gastos varios', disabled: false, selected: false, hidden: false }]);

    }, []);

    const getStardardData = useCallback(async () => {


        const requestURL = `http://localhost:8888/nutrifinca/categories.php`;
        //const requestURL = `https://fabianhr.com/nutrifinca/categories.php`;

        try {
            //const params = new URLSearchParams()
            //params.append('type', "get_data");
            //params.append('uid', uid);

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.post(requestURL);
            const json_suppliers = path(['data', 'suppliers'], result);
            const json_products = path(['data', 'products'], result);
            const json_items = path(['data', 'items'], result);
            const json_laying_houses = path(['data', 'laying_houses'], result);
            const json_units = path(['data', 'units'], result);
            

            // setSuppliers(json_suppliers);//JSON.parse(json_suppliers)
            // setProducts(json_products);//JSON.parse(json_products)
            // setItems(json_items);//JSON.parse(json_items)
            // setLayingHousesList(json_laying_houses);
            // setUnits(json_units);

            setServiceCategories([{ value: 'logistic', label: 'Logística', disabled: false, selected: false, hidden: false},
                                  { value: 'administration', label: 'Administración', disabled: false, selected: false, hidden: false },
                                  { value: 'food', label: 'Alimentos', disabled: false, selected: false, hidden: false },
                                  { value: 'fixes', label: 'Arreglos', disabled: false, selected: false, hidden: false },
                                  { value: 'miscellaneous', label: 'Gastos varios', disabled: false, selected: false, hidden: false }])
            return result;

        } catch (error) {

        }

    }, []);

    const setDataCategories = useCallback(async (payload) => {

        //const requestURL = `https://fabianhr.com/nutrifinca/setdata_categories.php`;
        const requestURL = `http://localhost:8888/nutrifinca/setdata_categories.php`;

        try {
            const params = new URLSearchParams()
            params.append('category', payload.category);
            params.append('json', JSON.stringify(payload.json));

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.post(requestURL, params, config);
            return result;

        } catch (error) {

        }

    }, []);


    const createProduct = useCallback(async (payload) => {


        const requestURL = `${process.env.REACT_APP_BASE_URL}/products`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);

    const editProduct = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/products/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    const deleteProduct = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/products/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id } });//JSON.stringify(payload)
        return result;

    }, []);

    
    const createServices = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/services`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);

    

    const getServicesLogistic = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/services/logistic`;

        try {

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL);
            
            if(result){
                setServicesLogistic(result.data.records);
            }

            return result;

        } catch (error) {

        }

    }, []);

    const getServicesAdministration = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/services/administration`;

        try {

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL);
            
            if(result){
                setServicesAdministration(result.data.records);
            }

            return result;

        } catch (error) {

        }

    }, []);

    const getServicesFood = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/services/food`;

        try {

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL);
            
            if(result){
                setServicesFood(result.data.records);
            }

            return result;

        } catch (error) {

        }

    }, []);

    const getServicesFixes = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/services/fixes`;

        try {

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL);
            
            if(result){
                setServicesFixes(result.data.records);
            }

            return result;

        } catch (error) {

        }

    }, []);

    const getServicesMiscellaneous = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/services/miscellaneous`;

        try {

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            const result = await axios.get(requestURL);
            
            if(result){
                setServicesMiscellaneous(result.data.records);
            }

            return result;

        } catch (error) {

        }

    }, []);
    
    const getServices = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/services`;

        try {

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            //const result = await axios.post(requestURL, params, config);
            const result = await axios.get(requestURL);
            //const json = path(['data', 'json'], result);
            
            if(result){
                setServices(result.data.records);
            }

            return result;

        } catch (error) {

        }

    }, []);

    const editServices = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/services/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    const deleteServices = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/services/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id, bulk: payload.bulk } });//JSON.stringify(payload)
        return result;

    }, []);

    


    
    const createLine = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/lines`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);

    const getLines = useCallback(async () => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/lines`;

        try {

            const config = {
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            //const result = await axios.post(requestURL, params, config);
            const result = await axios.get(requestURL);
            //const json = path(['data', 'json'], result);
            
            if(result){
                setServices(result.data.records);
            }

            return result;

        } catch (error) {

        }

    }, []);

    const editLine = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/lines/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    const deleteLine = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/lines/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id, bulk: payload.bulk } });//JSON.stringify(payload)
        return result;

    }, []);





    const getSuppliers = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/suppliers`;

        try {

            const result = await axios.get(requestURL);
            const data = path(['data', 'records'], result);
            
            if(data){
                setSuppliers(data);
            }

            return result;

        } catch (error) {

        }

    }, []);

    const createSupplier = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/suppliers`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);

    const editSupplier = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/suppliers/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);
    
    const deleteSupplier = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/suppliers/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id } });//JSON.stringify(payload)
        return result;

    }, []);

    
    const createItems = useCallback(async (payload) => {


        const requestURL = `${process.env.REACT_APP_BASE_URL}/items`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);   

    const createContact = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/contact`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);
    
    const editContact = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/contact/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    const createPayment = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/payments`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);

    const editPayment = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/payments/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);
    
    const deletePayment = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/payments/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id } });//JSON.stringify(payload)
        return result;

    }, []);
    
    const getBatches = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses/batches/${payload.laying_houses_id}`;

        try {

            const result = await axios.get(requestURL);
            const data = path(['data', 'records'], result);

            if(data){
                setBatches(data);
            }

            return result;

        } catch (error) {

        }

    }, []);

    const createBatches = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses/batches`;
        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.post(requestURL, params, config);
        return result;

    }, []);
    
    const editBatches = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses/batches/${payload.id}`;

        const params = new URLSearchParams()
        
        if(Object.keys(payload).length){
            Object.keys(payload).map(function(key) {
                params.append(key, payload[key]);
            })
        }

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        const result = await axios.put(requestURL, params, config);
        return result;

    }, []);

    const deleteBatches = useCallback(async (payload) => {

        const requestURL = `${process.env.REACT_APP_BASE_URL}/laying_houses/batches/${payload.id}`;
        const result = await axios.delete(requestURL, { data: { ids: payload.id } });
        return result;

    }, []);
    */
    
    

     
    

    //const apiUrl = `${process.env.REACT_APP_BASE_URL}/interceptor/wall/get_post`;

    return(
        <ContentContext.Provider
            value={{
                get_user_trust_fund,
                get_user_account_detail,
                get_user_portfolio,
                get_user_performance,
                get_user_debitcard,
                get_user_cashflow,
                change_password,
                menu,
                setMenu,
                getMenu,
                portfolio, 
                setPortfolio,
                getPortfolio,
                createPortfolio,
                editPortfolio,
                deletePortfolio,
                clients, 
                setClients,
                debitCard,
                setDebitCard,
                getDebitCard,
                createDebitCard,
                editDebitCard,
                deleteDebitCard,
                cashflow, 
                setCashflow,
                getCashflow,
                createCashflow,
                editCashflow,
                deleteCashflow,
                getUsername,
                users,
                setUsers,
                getUsers,
                createUsers,
                editUsers,
                deleteUsers,
                getAccountDetail,
                createAccountDetail,
                editAccountDetail,
                deleteAccountDetail,
                getPerformance,
                createPerformance,
                editPerformance,
                deletePerformance,
                getTrustFund,
                createTrustFund,
                editTrustFund,
                deleteTrustFund,
                accountDetail, setAccountDetail,
                performance, setPerformance,
                trustFund, setTrustFund,
                /*
                getServices,
                getServicesLogistic,
                getServicesAdministration,
                getServicesFood,
                getServicesFixes,
                getServicesMiscellaneous,
                setDataCategories,
                //createServices,
                items, setItems,
                services, setServices,
                servicesLogistic, setServicesLogistic,
                servicesAdministration, setServicesAdministration,
                servicesFood, setServicesFood,
                servicesFixes, setServicesFixes,
                servicesMiscellaneous, setServicesMiscellaneous,
                suppliers, setSuppliers,
                products, setProducts,
                units, setUnits,
                serviceCategories, setServiceCategories,
                layingHouses, setLayingHouses, 
                getLayingHousesRecods, 
                saveLayingHousesRecord, 
                deleteLayingHousesRecord,
                editLayingHousesRecord,
                editLayingHousesWeight,
                layingHousesRecords, setLayingHousesRecords, 
                editLayingHouses,
                saveLayingHouses, 
                getLayingHouses, 
                layingHousesList, setLayingHousesList,
                getLayingHouseAnalitycs,
                createProduct, 
                editProduct,
                deleteProduct,
                getSuppliers,
                createSupplier,
                editSupplier,
                deleteSupplier,
                deleteLayingHouses,
                createServices,
                editServices,
                deleteServices,
                createItems,
                createPayment,
                editPayment,
                createContact, 
                editContact,
                deletePayment,
                batches, setBatches,
                getBatches,
                createBatches,
                editBatches,
                deleteBatches,
                createLine,
                getLines,
                editLine,
                deleteLine,
                provinces, setProvinces
                */
            }}
        >
            {children}
        </ContentContext.Provider>
    )
};

