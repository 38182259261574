import React, { Fragment, useEffect, useState, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Snackbar } from '../../Atoms/Snackbar';

import { ContainerGrid } from '../../Atoms/Container/ContainerGrid';
import { FormStructure } from '../FormStructure';
import { ContentContext } from '../../../context/ContentContext';

import { generateRandomCode,
         validateEmail,
         user_types } from '../../../helpers/commons';

const FormStyled = styled.div`
    >.container{
        position: relative;
        overflow: hidden;
        >.inner{
            >.field_complex{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }
`

const FormUsers = ({actionType, 
                      dataToEdit, 
                      setDataToEdit, 
                      actionForm, 
                      setActionForm,
                      monthSelected }) => {

                
    const { users, 
            setUsers,
            editUsers,
            createUsers,
            clients,
            getUsername } = useContext(ContentContext)

    const [ formData, setFormData ] = useState({});
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [ snackbarData, setSnackbarData ] = useState({text: '', severity: ''});
    const [ isLoading, setIsLoading ] = useState(false);
    const [ uniqueUsername, setUniqueUsername ] = useState(null);
    
    useEffect(() => {
        //if(uniqueUsername){
            
        
            const data = {
                /*
                user_type: {
                    isSlave: false,
                    masterOf: null,
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: '',
                        icon: { component: null },
                        size: 'medium',
                        type: 'primary',
                        options: user_types(),
                    },
                    label: {
                        text: 'User Type',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.user_id ? dataToEdit.user_id : '',
                    mandatory: true,
                    type: 'select:common',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    innerElement: null,
                    dependendComponent: null,
                    errorMessage: null,
                    disableElements: null,
                },
                */
                /*
                username: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a username...',
                    },
                    label: {
                        text: 'Username',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit ? dataToEdit?.username ? dataToEdit.username : '' : uniqueUsername,
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                */
                account_number: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter an account number...',
                    },
                    label: {
                        text: 'Account Number',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.account_number ? dataToEdit.account_number : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                name: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a full name...',
                    },
                    label: {
                        text: 'Full Name',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.name ? dataToEdit.name : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                address: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter an address...',
                    },
                    label: {
                        text: 'Address',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.address ? dataToEdit.address : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                phone: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a phone number...',
                    },
                    label: {
                        text: 'Phone',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.phone ? dataToEdit.phone : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                email: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter an e-mail...',
                    },
                    label: {
                        text: 'E-Mail',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.email ? dataToEdit.email : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
            }

            setFormData(data);
        //}
    
      return () => {
        setFormData({});
      }
    }, [dataToEdit])

    const handleCancel = () => {
        resetForm();
    }

    const handleOnChange = (e, name) => {
        
        if(name === "username"){
            return null;
        }

        const update = {...formData}

        

        update[name].value = e.target.value;
        if(e.target.value.length){
            update[name].error = false;
        }

        
        /*
        if(name !== 'quantity' && name !== 'value' && name !== 'amount' && name !== 'balance'){
            update[name].value = e.target.value;
            if(e.target.value.length){
                update[name].error = false;
            }
        }else{
            update[name].value = e;
            if(e?.length){
                update[name].error = false;
            }
        }
        */

        setFormData(update)

    }

    const handleSendForm = () => {
        
        setIsLoading(true);
        let haveError = false;

        let formDataValidate = {...formData};
        Object.keys(formDataValidate).map((field) => {
            
            if(formDataValidate[field].mandatory && field !== 'empty' && !formDataValidate[field].disabled){
                
                if(!formDataValidate[field].value.toString().length){
                    haveError = true;
                    formDataValidate[field].error =  true;
                }else{
                    if(field === "email"){
                        if(!validateEmail(formDataValidate[field].value)){
                            haveError = true;
                            formDataValidate[field].error =  true;
                        }
                    }else{
                        formDataValidate[field].error =  false;
                    }
                }

                
                
                //return null;

            }
            
        })
        //console.log("haveError", haveError, formDataValidate);
        //return  null;
        setFormData(formDataValidate);

       

        if(!haveError){
            
            let update_json = [];
            let data = '';
            let id = uuid();

            let formDataToSend = Object.keys(formData).map((field) => {
                return {field: field, value: formData[field].value};
            })

            if(dataToEdit){
                
                data = {"id": dataToEdit.id,
                        //"username": formData["username"].value, 
                        "account_number": formData["account_number"].value, 
                        "name": formData["name"].value, 
                        "address": formData["address"].value,  
                        "phone": formData["phone"].value,  
                        "email": formData["email"].value};
                
                const update_json_ = [...users];
                update_json = update_json_.map((el, index) => {
                    
                    if(el.id === dataToEdit.id){
                        return data;
                    }else{
                        return el;
                    }
                })
                

            }else{

                data = {"uuid": id,
                        //"username": formData["username"].value, 
                        "account_number": formData["account_number"].value, 
                        "name": formData["name"].value, 
                        "address": formData["address"].value,  
                        "phone": formData["phone"].value,  
                        "email": formData["email"].value};

                update_json = [...users];
                update_json.push(data);

            }
            
            const payload = data;
            let result;
            
            
            if(dataToEdit){
                result = editUsers(payload);
            }else{
                result = createUsers(payload);
            }
            
            
            result.then(res => {
                
                setSnackbarData({text: dataToEdit ? 'The data was successfully updated.' : 'The data was successfully saved.', severity: 'success'});
                setSnackbarOpen(true)
                setIsLoading(false);

                let data_users = {label: res.data.record.name,
                                  value: res.data.record.id,
                                  username: res.data.record.username,
                                  account_number: res.data.record.account_number,
                                  name: res.data.record.name,
                                  id: res.data.record.id,
                                  address: res.data.record.address,
                                  phone: res.data.record.phone,
                                  email: res.data.record.email,
                                  created_at: res.data.record.created_at,
                                  updated_at: res.data.record.updated_at,
                                  deleted_at: res.data.record.deleted_at};
                                    
                if(dataToEdit){
                    const usersUpdated = users.map(record => {
                        if(record.id === res.data.record.id){
                            return data_users;//res.data.record;
                        }else{
                            return record;
                        }
                    })
                    setUsers(usersUpdated);
                }else{
                    let usersUpdated = [...users];
                    usersUpdated.push(data_users);
                    setUsers(usersUpdated);
                }

                if(!dataToEdit){
                    resetForm();
                }
            })

        }else{
            setIsLoading(false);
            return null;
        }
        
    }

    const resetForm = () => {

        let formReset = {...formData};
        Object.keys(formReset).map((field) => {
            formReset[field].value = "";
            formReset[field].error = false;
        })
        setFormData(formReset);

    }

    const getUniqueUsername = () => {
        const response = getUsername();
        response.then(res => {
            setUniqueUsername(res.data.record);
            //console.log("res --> ", res.data.record);
        })
    }

    useEffect(() => {

        //getUniqueUsername();
    
      return () => {}
    }, [])
    
    useEffect(() => {
      return () => {
        setDataToEdit(null);
        setActionForm('');
        resetForm();
      }
    }, [])
    /*
    if(!clients){
        return null;
    }
    */
    return (
        <FormStyled>
            <Snackbar 
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                snackbarData={snackbarData}
                setSnackbarData={setSnackbarData}
                hide={2500}
            />
            <ContainerGrid
                className={`container`}
                margins={{top: 4}}
            > 
                <FormStructure 
                    formData={formData}
                    setFormData={setFormData}
                    handleOnChange={handleOnChange}
                    handleSendForm={handleSendForm}
                    handleCancel={handleCancel}
                    dataToEdit={dataToEdit}
                    isloading={isLoading}
                    payButton={false}
                />
            </ContainerGrid>
        </FormStyled>
    );
};

export default FormUsers;