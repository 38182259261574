import React, { useContext, useState, useEffect } from 'react';
import { createBrowserRouter,
         RouterProvider,
         redirect,
         useLocation } from "react-router-dom";
import { AuthContext, ContentContext } from '../../context';
import { WrapperStyled } from './styles';
import { Scrollbar } from 'react-scrollbars-custom';
import { CircularProgress } from '@mui/material';
import { number_format } from '../../helpers/commons';

const Portfolio = () => {

    const {get_user_portfolio} = useContext(ContentContext);

    const [ monthSelected, setMonthSelected ] = useState(0);
    const [ portfolio, setPortfolio ] = useState(null);

    const handleChangeMonth = (month) => {

        handleGetData({month_range: month});
        setMonthSelected(month);

    }

    useEffect(() => {
        
        handleGetData({month_range: 0});
    
      return () => {}
    }, [])

    const handleGetData = (filters = null) => {

        const response = get_user_portfolio(filters);
        response.then(res => {
            setPortfolio(res.data.records);
        })

    }

    return (
        <WrapperStyled>
            <div className='content'>

                {portfolio === null ?
                    <div className='loader'>
                        <CircularProgress size={50}/>
                    </div>
                : 
                <>
                <div className='top'>
                    <div className='months'>
                        <div className={`month${monthSelected === 0 ? ' selected' : ''}`} onClick={() => handleChangeMonth(0)}>1M</div>
                        <div className={`month${monthSelected === 6 ? ' selected' : ''}`} onClick={() => handleChangeMonth(6)}>6M</div>
                        <div className={`month${monthSelected === 12 ? ' selected' : ''}`} onClick={() => handleChangeMonth(12)}>12M</div>
                    </div>
                </div>
                <div className='bottom'>

                    <div className='table'>
                        {portfolio !== null ?
                        <Scrollbar className='scrollbar' permanentTrackY={true} disableTracksMousewheelScrolling={false}>
                            <div className='head'>
                                <div className='column product'>Product</div>
                                <div className='column date'>Date</div>
                                <div className='column description'>Description</div>
                                <div className='column quantity'>Quantity</div>
                                <div className='column value'>USD Value</div>
                                <div className='column isin'>ISIN</div>
                            </div>
                            
                            {!portfolio.length ? <div className='nodata'>No data to show</div> : ""}
                            
                            <>
                            {portfolio.length ?
                                <div className='rows'>
                                    {portfolio.map((element, index) => {
                                        return(
                                            <div className={`row${index % 2 ? " gray" : ""}`}>
                                                <div className='product'>{ element.product }</div>
                                                <div className='date'>{ element.date }</div>
                                                <div className='description'>{ element.description }</div>
                                                <div className='quantity'>{ number_format(element.quantity) }</div>
                                                <div className='value'>{ number_format(element.usd_value) }</div>
                                                <div className='isin'>{ element.isin }</div>
                                            </div>
                                        )
                                    })}
                                </div> : ""}
                            </>
                        </Scrollbar>
                        : ""}
                    </div>
                            
                        
                </div>
                </>
                }
            </div>
        </WrapperStyled>
    );
};

export default Portfolio;