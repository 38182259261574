import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 10px 10px;
    border: 1px ${props => props.borderType} #CADAFF;
    border-radius: 6px; */

    margin-top: ${props => (props.margins && props.margins.top) ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => (props.margins && props.margins.bottom) ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => (props.margins && props.margins.left) ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => (props.margins && props.margins.right) ? 5 * props.margins.right + "px" : 0}!important;

    padding-top: ${props => (props.paddings && props.paddings.top) ? 5 * props.paddings.top + "px" : 0}!important;
    padding-bottom: ${props => (props.paddings && props.paddings.bottom) ? 5 * props.paddings.bottom + "px" : 0}!important;
    padding-left: ${props => (props.paddings && props.paddings.left) ? 5 * props.paddings.left + "px" : 0}!important;
    padding-right: ${props => (props.paddings && props.paddings.right) ? 5 * props.paddings.right + "px" : 0}!important;
    //width: 800px;
`

export const ContainerDrawer = ({ children, margins, paddings }) => {
    return (
        <Container
            margins={margins}
            paddings={paddings}
            className={`container`}
        >
            {children} 
        </Container>
    );
};

ContainerDrawer.propTypes = {
    margins: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
    }),
    paddings: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
    }),
    borderType: PropTypes.oneOf(['dashed', 'dotted', 'solid']),
    position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

ContainerDrawer.defaultProps = {
    margins: PropTypes.shape({
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    }),
    paddings: PropTypes.shape({
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    }),
};