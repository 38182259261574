import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoints } from '../../../helpers/breakpoints';

const Container = styled.div`
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #CADAFF;
    box-sizing: border-box;
    border-radius: 6px;

    margin-top: ${props => (props.margins && props.margins.top) ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => (props.margins && props.margins.bottom) ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => (props.margins && props.margins.left) ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => (props.margins && props.margins.right) ? 5 * props.margins.right + "px" : 0}!important;

    padding-top: ${props => (props.paddings && props.paddings.top) ? 5 * props.paddings.top + "px" : 0}!important;
    padding-bottom: ${props => (props.paddings && props.paddings.bottom) ? 5 * props.paddings.bottom + "px" : 0}!important;
    padding-left: ${props => (props.paddings && props.paddings.left) ? 5 * props.paddings.left + "px" : 0}!important;
    padding-right: ${props => (props.paddings && props.paddings.right) ? 5 * props.paddings.right + "px" : 0}!important;

    .inner{
        width: 100%;
        display: flex;
        flex-direction: column;
        
        >div{
            margin-bottom: 15px;
        }
        :last-child{
            margin-bottom: 0;
        }
        
        /*
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        */
    }

    @media ${breakpoints.xs} {//576px

    }
    @media ${breakpoints.sm} {//768px
        .inner{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            flex-direction: unset;
            >div{
                margin-bottom: 0;
            }
        }
    }
    @media ${breakpoints.md} {//992px
    }
    @media ${breakpoints.lg} {//1200px // Desktop
    }
    @media ${breakpoints.xl} {//1366px
    }
    @media ${breakpoints.xxl} {//1440px
    }
    @media ${breakpoints.xxxl} {//1680px
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`

export const ContainerGrid = ({ _key, children, className, margins = {top: 0, bottom: 0, left: 0, right: 0}, paddings}) => {
    return (
        <Container
            key={_key}
            className={`${className}`}
            margins={margins}
            paddings={paddings}
        >
            {children}
        </Container>
    );
};


ContainerGrid.propTypes = {
    lala: PropTypes.string,
    className: PropTypes.string,
    _key: PropTypes.string,
    margins: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
    }),
    paddings: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
    }),
};

ContainerGrid.defaultProps = {
    className: '',
    margins:{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    paddings:{
        top: 4,
        bottom: 4,
        left: 4,
        right: 4,
    },
};