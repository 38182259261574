import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WrapperStyled = styled.div`

    margin-top: ${props => props.margins.top ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => props.margins.bottom ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => props.margins.left ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => props.margins.right ? 5 * props.margins.right + "px" : 0}!important;

`

const ModalBody = ({className = '', children, margins = {top: 0, bottom: 0, left: 0, right: 0}}) => {
    return (
        <WrapperStyled
            className={`${className}`}
            margins={margins}
        >
            {children}
        </WrapperStyled>
    );
};

ModalBody.propTypes = {
    className: PropTypes.string,
    margins: PropTypes.shape({
        top: PropTypes.oneOf([1, 2, 3, 4, 5]),
        bottom: PropTypes.oneOf([1, 2, 3, 4, 5]),
        left: PropTypes.oneOf([1, 2, 3, 4, 5]),
        right: PropTypes.oneOf([1, 2, 3, 4, 5]),
    }),
};

export default ModalBody;