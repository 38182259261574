import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';

import { WrapperStyled } from './styles';
import { Outlet } from "react-router-dom";
import { ContentContext, AuthContext } from '../../context';
import { useNavigate, useLocation } from 'react-router-dom';
import { TiChevronRight } from "react-icons/ti";
import { Footer } from '../../pages/Footer';
import { Header } from '../../components/Header';
import { MenuNav } from '../MenuNav';


const Root = (props) => {
  
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { menu,
          getMenu } = useContext(ContentContext)
        
  const { user } = useContext(AuthContext)
  
  const [ sidebarOpen, setSidebarOpen ] = useState(false);
  
  const timeOutSidebar = useRef(null);

  const handleOpenSidebar = () => {
    if(timeOutSidebar.current){
      clearTimeout(timeOutSidebar.current);
    }
    setSidebarOpen(!sidebarOpen)
  }
  
  const handleLeaveSidebar = () => {
    timeOutSidebar.current = setTimeout(() => { setSidebarOpen(false); }, 500);
    
  }
  const handleEnterSidebar = () => {
    if(timeOutSidebar.current){
      clearTimeout(timeOutSidebar.current);
    }
    setSidebarOpen(true)
  }
  

  useEffect(() => {

    if(Object.keys(user).length){
      getMenu(user);
    }
  
    return () => {}
  }, [user])
  
  
  if(!Object.keys(menu).length){
    //return null;
  }
  
  
  return (
    <WrapperStyled
      with_margin_rigth={true}
    >
        {localStorage.getItem('token') ?
          <>
            {/* <div className='container_loged'>
              <Outlet />
              <Footer />
            </div> */}
            <Header />
            <div className='content'>
              <div className={`sidebar ${sidebarOpen ? " open" : ""}`} onMouseLeave={handleLeaveSidebar} onMouseEnter={handleEnterSidebar}>
                <MenuNav 
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                  menu={menu}
                />
              </div>
              <div 
                className={`bt_open_sidebar${sidebarOpen ? " open" : ""}`} 
                onClick={handleOpenSidebar}
              >
                <div className='icon'>
                  <TiChevronRight />
                </div>
              </div>
              <div className='container'>
                <div className='inner'>
                  <Outlet />
                </div>
              </div>
            </div>
          </>
        : 
          <>
          <div className='container_logout'>
            <Outlet />
            <Footer />
          </div>
          </>
          
            
        }

        
    </WrapperStyled>
  )
  
}
export default Root;
