import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { colors } from '../../helpers/commons';

export const WrapperStyled = styled.div`
    min-height: 100vh;
    //background-color: white;
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .container_loged{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .container_logout{
        display: flex;
        flex-direction: column;
    }
    >.header{
        position: fixed;
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 50px;
        border-bottom: 1px solid #CADAFF;
        z-index: 9999;
    }
    .content{
        display: flex;
        flex-direction: row;
        min-height: calc(100% - 50px);
        .sidebar{
            position: fixed;
            width: 65px;
            min-height: calc(100vh - 50px);
            padding: 20px 20px;
            border-right: 1px solid #CADAFF;
            background-color: rgba(${colors.brand}, 1)!important;
            transition: all .1s ease-in-out;
            margin-top: 50px;
            z-index: 999;
            overflow: hidden;
            &.open{
                width: 250px;
            }
        }
        >.container{
            display: flex;
            justify-content: center;
            margin-left: 50px;
            margin-top: 50px;
            width: calc(100% - 50px);
            //padding: 25px 50px;
            height: 100%;
            >.inner{
                padding: 25px ${props => props.with_margin_rigth ? "25px" : "0"} 25px 35px;
                width: 100%;
            }
        }
    }
    .bt_open_sidebar{
        position: fixed;
        display: flex;
        align-items: center;
        width: 17px;
        height: 30px;
        z-index: 999;
        margin-top: 70px;//calc(50% - 12px);
        margin-left: 64px;
        background-color: #FFFFFF;
        border: 1px solid rgba(${colors.brand}, 1);
        border-left: 0;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        transition: all .1s ease-in-out;
        cursor: pointer;
        .icon{
            display: flex;
            font-size: 16px;
            line-height: 16px;
            color: rgba(${colors.brand}, 1);
        }
        &.open{
            margin-left: 249px;
            .icon{
                transform: rotate(-0.5turn);
            }
        } 
    }
    

    @media ${breakpoints.xs} {//576px

        .content{
            min-height: calc(100% - 50px);
            >.container{
                width: calc(100% - 65px);
                margin-left: 65px;
                height: 100%;
                >.inner{
                    padding: 25px ${props => props.with_margin_rigth ? "25px" : "0"} 25px 35px;
                    width: 100%; //511px;
                }
            }
        }

    }
    @media ${breakpoints.sm} {//768px
        
        .content{
            min-height: calc(100% - 50px);
            >.container{
                margin-left: 65px;
                height: 100%;
                >.inner{
                    padding: 25px ${props => props.with_margin_rigth ? "25px" : "0"} 25px 35px;
                    width: 100%; //703px;
                }
            }
        }

    }
    @media ${breakpoints.md} {//992px

        .content{
            min-height: calc(100% - 50px);
            >.container{
                margin-left: 65px;
                height: 100%;
                >.inner{
                    padding: 25px ${props => props.with_margin_rigth ? "25px" : "0"}; //25px 35px;
                    width: 927px;//927px;
                }
            }
        }

    }
    @media ${breakpoints.lg} {//1200px

        .content{
            min-height: calc(100% - 50px);
            >.container{
                margin-left: 65px;
                height: 100%;
                >.inner{
                    padding: 25px 35px;
                    width: 1135px;
                }
            }
        }

    }
    @media ${breakpoints.xl} {//1366px

        .content{
            min-height: calc(100% - 50px);
            >.container{
                margin-left: 65px;
                height: 100%;
                >.inner{
                    padding: 25px 35px;
                    width: 1301px;
                }
            }
        }

    }
    @media ${breakpoints.xxl} {//1440px

        .content{
            min-height: calc(100% - 50px);
            >.container{
                margin-left: 65px;
                height: 100%;
                >.inner{
                    padding: 25px 35px;
                    width: 1375px;
                }
            }
        }

    }
    @media ${breakpoints.xxxl} {//1680px

        .content{
            min-height: calc(100% - 50px);
            >.container{
                margin-left: 65px;
                height: 100%;
                >.inner{
                    padding: 25px 35px;
                    width: 1615px;
                }
            }
        }

    }
    @media ${breakpoints.xxxxl} {//1920px

        .content{
            min-height: calc(100% - 50px);
            >.container{
                margin-left: 65px;
                height: 100%;
                >.inner{
                    padding: 25px 35px;
                    width: 1855px;
                }
            }
        }

    }

`