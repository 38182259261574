import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { startOfMonth, endOfMonth } from 'date-fns';
import { Input } from '../../Atoms/Input/Input';
import { Label } from '../../Atoms/Label/Label';
import { Icon } from '../../Atoms/Icon/Icon';

import { CustomProvider, DateRangePicker, Stack } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import esAr from 'rsuite/locales/es_AR';

const FieldDateRangeStyled = styled.div`
    display: flex;
    flex-direction: column;
    grid-column: ${props => props.fullwidth ? 'span 2' : ''};

    
    
   
    .rs-input-group{
        border: 1px solid #CADAFF!important;
        input{
            background-color: #FBFCFF!important;
            color: #879AC6!important;
            font-size: 15px!important;
            padding: 15px 15px;
        }
        
        outline: 0!important;
    }
    .containet_label{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: ${props => (props.input_reverse || !props.input_icon) ? '0' : '37px'};
        margin-bottom: 3px;
        .icon{
            display: flex;
            padding-right: 2px;
            svg{
                display: flex;
            }
        }
        &.reverse{
            .icon{
                margin-right: 0;
                margin-left: 0;
            }
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
    .containet_input{
        display: flex;
        flex-direction: row;
        align-items: center;
        .input{
            width: calc(100% - 40px);
        }
        .icon{
            display: flex;
            justify-content: center;
            width: 40px;
            padding-right: 2px;
            /* background-color: pink; */
        }
        &.no-icon{
            .input{
                width: 100%!important;
            }
        }
        &.reverse{
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
`

const FieldDateRange = ({ placement, value, fullwidth, className, onChange, type, label, input, placeholder, icon, name, error, disabled, input_type, rangeValue, setRangeValue, rangeLoading, setRangeLoading, handleClean, ...props }) => {

    const handleChangeRange = (value) => {
        setRangeValue(value);
        setRangeLoading(true);
    }    

    return (
        <FieldDateRangeStyled
            input_reverse={input.reverse ? true : undefined}
            input_icon={input.icon.component}
            className={className}
            fullwidth={fullwidth ? true : undefined}
        >
            
            {label ?
            <div className={`containet_label ${label.reverse ? 'reverse' : ''}`}>
                {label.icon.component ?
                <Icon 
                    type={label.icon.type}
                    icon={label.icon.component}
                    size={`label ${label.icon.size}`}
                    error={error}
                /> : ""}
                <Label 
                    type={label.type}
                    label={label.text} 
                    error={error}
                />
            </div> : ""}
            <CustomProvider locale={esAr}>
            <Stack spacing={10} direction="column" alignItems="flex-start">

                <DateRangePicker 
                    showOneCalendar
                    format="dd/MM/yyyy" 
                    character=" --> " 
                    onOk={handleChangeRange}
                    placeholder="DD/MM/AAAA - DD/MM/YYYY"
                    ranges={[]}
                    //disabled={rangeLoading}
                    //hoverRange={"week"}
                    onClean={handleClean}
                    defaultValue={rangeValue/*[startOfMonth(new Date()), endOfMonth(new Date())]*/}
                    placement={placement}
                />
            </Stack>
            </CustomProvider>

        </FieldDateRangeStyled>
    );

};

FieldDateRange.propTypes = {
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element,
    onChange: PropTypes.func,
    name: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    fullwidth: PropTypes.bool,
    value: PropTypes.string,
    disabled: PropTypes.bool,

    rangeValue: PropTypes.array, 
    setRangeValue: PropTypes.func, 
    rangeLoading: PropTypes.bool,
    setRangeLoading: PropTypes.func,

    input: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        placeholder: PropTypes.string,
        icon: PropTypes.shape({
            uuid: PropTypes.string,
            type: PropTypes.string,
            component: PropTypes.element,
            size: PropTypes.oneOf(['small', 'medium', 'large']),
            error: PropTypes.bool,
        }),
        className: PropTypes.string,
        reverse: PropTypes.bool,
        error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    }),

    label: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        text: PropTypes.string,
        icon: PropTypes.shape({
            uuid: PropTypes.string,
            type: PropTypes.string,
            component: PropTypes.element,
            size: PropTypes.oneOf(['small', 'medium', 'large']),
            error: PropTypes.bool,
            scope: PropTypes.string,
        }),
        className: PropTypes.string,
        reverse: PropTypes.bool,
        error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        placement: PropTypes.string
    }),
};


export default React.memo(FieldDateRange);