import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Select } from '../../Atoms/Select/Select';
import { Label } from '../../Atoms/Label/Label';
import { Icon } from '../../Atoms/Icon/Icon';

const FieldStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-column: ${props => props.fullwidth ? 'span 2' : ''};

    margin-top: ${props => (props.margins && props.margins.top) ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => (props.margins && props.margins.bottom) ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => (props.margins && props.margins.left) ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => (props.margins && props.margins.right) ? 5 * props.margins.right + "px" : 0}!important;

    padding-top: ${props => (props.paddings && props.paddings.top) ? 5 * props.paddings.top + "px" : 0}!important;
    padding-bottom: ${props => (props.paddings && props.paddings.bottom) ? 5 * props.paddings.bottom + "px" : 0}!important;
    padding-left: ${props => (props.paddings && props.paddings.left) ? 5 * props.paddings.left + "px" : 0}!important;
    padding-right: ${props => (props.paddings && props.paddings.right) ? 5 * props.paddings.right + "px" : 0}!important;
    
    .containet_label{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: ${props => (props.input_reverse || !props.input_icon) ? '0' : '37px'};
        margin-bottom: 3px;
        .icon{
            display: flex;
            padding-right: 2px;
            svg{
                display: flex;
            }
        }
        &.reverse{
            .icon{
                margin-right: 0;
                margin-left: 0;
            }
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
    .containet_input{
        display: flex;
        flex-direction: row;
        align-items: center;
        .input{
            width: calc(100% - 40px);
        }
        .icon{
            display: flex;
            justify-content: center;
            width: 40px;
            padding-right: 2px;
            /* background-color: pink; */
        }
        &.no-icon{
            .input{
                width: 100%!important;
            }
        }
        &.reverse{
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
`

const FieldSelect = ({ uuid, className, _key, fullwidth, select, label, onChange, name, error, value, margins, paddings, disabled }) => {
    return (
        <FieldStyled
            key={uuid}
            className={`${className}`}
            input_reverse={select.reverse ? true : undefined}
            input_icon={select.icon.component}
            fullwidth={fullwidth ? true : undefined}
            margins={margins}
            paddings={paddings}
        >
            
            {label ? 
            <div className={`containet_label ${label.reverse ? 'reverse' : ''}`}>
                {label.icon.component ?
                <Icon 
                    uuid={label.icon.uuid}
                    type={label.icon.type}
                    icon={label.icon.component}
                    size={`label ${label.icon.size}`}
                    error={error}
                /> : ""}
                <Label 
                    uuid={label.uuid}
                    type={label.type}
                    label={label.text} 
                    error={error}
                />
            </div> : ""}

            <div className={`containet_input ${select.reverse ? 'reverse' : ''} ${!select.icon.component ? 'no-icon' : ''}`}>
                {select.icon.component ?
                <Icon 
                    uuid={select.icon.uuid}
                    type={select.icon.type}
                    icon={select.icon.component}
                    size={select.icon.size} 
                    error={error}
                /> : ""}
                <Select 
                    uuid={select.uuid}
                    type={select.type}
                    placeholder={select.placeholder} 
                    size={select.size} 
                    className={select.className} 
                    reverse={select.reverse} 
                    options={select.options}
                    onChange={onChange}
                    name={name}
                    error={error}
                    selected={value}
                    disabled={disabled}
                />
            </div>
        </FieldStyled>
    );
};

FieldSelect.propTypes = {
    _key: PropTypes.string,
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    icon: PropTypes.element,
    name: PropTypes.string,
    error: PropTypes.bool,
    fullwidth: PropTypes.bool,
    value: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    uuid: PropTypes.string,

    margins: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
    }),
    paddings: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
    }),

    select: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        placeholder: PropTypes.string,
        selected: PropTypes.string,
        icon: PropTypes.shape({
            uuid: PropTypes.string,
            type: PropTypes.string,
            component: PropTypes.element,
            size: PropTypes.oneOf(['small', 'medium', 'large']),
            error: PropTypes.bool,
        }),
        className: PropTypes.string,
        reverse: PropTypes.bool,
        error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        options: PropTypes.array,
    }),

    label: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        text: PropTypes.string,
        icon: PropTypes.shape({
            uuid: PropTypes.string,
            type: PropTypes.string,
            component: PropTypes.element,
            size: PropTypes.oneOf(['small', 'medium', 'large']),
            error: PropTypes.bool,
            scope: PropTypes.string,
        }),
        className: PropTypes.string,
        reverse: PropTypes.bool,
        error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    }),
};

export default FieldSelect;