import React, { useState, useEffect, useContext } from 'react';
import { path } from 'ramda';
import { WrapperStyled, LoaderStyled } from './styles';
import { FormPortfolio } from '../../../components/Organisms/FormPortfolio';
import { TableData } from '../../../components/Organisms/TableData';
import { ContentContext } from '../../../context';
import { CircularProgress } from '../../../components/Atoms/CircularProgress';

const Portfolio = () => {

    const { getPortfolio, setPortfolio, portfolio } = useContext(ContentContext);

    const [ actionForm, setActionForm ] = useState("");
    const [ optionSelected, setOptionSelected ] = useState("");
    const [ dataToEdit, setDataToEdit ] = useState(null);
    const [ filtersData, setFiltersData ] = useState(null);

    useEffect(() => {

        const response = getPortfolio();
        response.then(res => {
            let records = path(['data', 'records'], res);
            let filters = path(['data', 'filters'], res);
            setPortfolio(records);
            setFiltersData(filters);
        })

        return () => {
            setPortfolio(null);
        }
    }, [])

    return (
        <WrapperStyled>
            <div className='title'>Portfolio</div>
            <FormPortfolio 
                dataToEdit={dataToEdit}
                setDataToEdit={setDataToEdit}
                actionForm={actionForm}
                setActionForm={setActionForm}
                filtersData={filtersData}
                setFiltersData={setFiltersData}
            />
            <TableData 
                actionForm={actionForm}
                dataToEdit={dataToEdit}
                setDataToEdit={setDataToEdit}
                filtersData={filtersData}
                setFiltersData={setFiltersData}
            />
        </WrapperStyled>
    );
};

export default Portfolio;