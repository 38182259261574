import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useLocation } from "react-router-dom";

import { colors, 
         //months_list,
         maringSizes,
         fixParseFloat,
         number_format,
         payment_status,
         calculate_quote,
         calculate_quote_usd,
         filter_by_start_month,
         calculate_outstanding_balance,
         calculate_outstanding_balance_usd,
         get_element_product_decription,
         get_element_food_decription,
         get_element_miscellaneous_decription,
         get_element_logistic_decription,
         get_element_label,
         get_title } from '../../../helpers/commons';

import { WrapperStyled, LoaderStyled } from './styles';

import { Portfolio as PortfolioTable } from '../Tables/PortfolioTable';
import { DebitCard as DebitCardTable } from '../Tables/DebitCardTable';
import { Cashflow as CashflowTable } from '../Tables/CashflowTable';
import { Users as UsersTable } from '../Tables/UsersTable';
import { AccountDetail as AccountDetailTable } from '../Tables/AccountDetailTable';
import { TrustFund as TrustFundTable } from '../Tables/TrustFundTable';
import { Performance as PerformanceTable } from '../Tables/PerformanceTable';


import { TableHeader } from '../../Molecules/TableHeader';
import { TableBody } from '../../Molecules/TableBody';
import { TableFooter } from '../../Molecules/TableFooter';
import { Typography } from '../../Atoms/Typography/Typography';
import { ButtonIcon } from '../../Atoms/ButtonIcon/ButtonIcon';
import { FieldPayment } from '../../Molecules/FieldPayment';
import { MenuMore } from '../../Molecules/MenuMore';
import { ButtonText } from '../../Atoms/ButtonText/ButtonText';
import { Badge } from '../../Atoms/Badge';
import { Button } from '../../Atoms/Button/Button';
import { Modal as ModalDelete } from '../../Molecules/Modal';
import { Modal as ModalBulkPayment } from '../../Molecules/Modal';
//import { ModalExpenseServices } from '../ModalServices';
import { FieldSelect } from '../../Molecules/FieldSelect'
import { Drawer } from '../../Molecules/Drawer';
//import { View } from '../Services/View';
import { Snackbar } from '../../Atoms/Snackbar';
import { CircularProgress } from '../../Atoms/CircularProgress';


import { FaPlus, FaEye, FaCheck } from "react-icons/fa6";
import { FaPencilAlt, FaRegClock } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoInfiniteSharp } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";



import { Checkbox } from '../../Atoms/Checkbox/Checkbox';
import { FieldCheckbox } from '../../Molecules/FieldCheckbox';

import { ContentContext } from '../../../context/ContentContext';
 
var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)



const TableDataServices = ({ dataToEdit, 
                             setDataToEdit, 
                             optionSelected, 
                             setOptionSelected, 
                             actionForm, 
                             setActionForm, 
                             margins = {top: 0, bottom: 0, left: 0, right: 0},
                             monthSelected, 
                             setMonthSelected,
                             filtersData, 
                             setFiltersData }) => {
    
    const location = useLocation();

    const { portfolio, 
            setPortfolio,
            createPortfolio,
            getPortfolio,
            /*units,
            items,
            getServices,
            deleteServices,
            deletePayment,
            services, 
            setServices,
            createPayment,
            products,
            suppliers,
            serviceCategories,
            servicesAdministration,
            setServicesAdministration,
            servicesLogistic,
            setServicesLogistic,
            servicesFood,
            setServicesFood,
            servicesFixes,
            setServicesFixes,
            servicesMiscellaneous,
            setServicesMiscellaneous*/ } = useContext(ContentContext)
    
    const upper1366px = useMediaQuery('(min-width:1366px)');

    //const [ titleModal, setTitleModal ] = useState("");
    //const [ dataSuccessModal ]
    const [ openDeleteModal, setOpenDeleteModal ] = useState(false);
    const [ openBulkPaymentModal, setOpenBulkPaymentModal ] = useState(false);
    
    const [ openAddExpenseModal, setOpenAddExpenseModal ] = useState(false);
    const [ singleRowsSelected, setSingleRowsSelected] = useState(null);
    const [ rowsSelected, setRowsSelected] = useState([]);
    const [ rowsSelectedAll, setRowsSelectedAll ] = useState(false);
    const [ modalTitle, setModalTitle ] = useState("");
    const [ modalDescription, setModalDescription ] = useState("");
    const [ paymentData, setPaymentData ] = useState({});
    const [ paymentDataToEdit, setPaymentDataToEdit ] = useState(null);
    
    const [ rows, setRows ] = useState([]);
    const [ months, setMonths ] = useState([]);
    
    const [ openDrawer, setOpenDrawer ] = useState(false);
    const [ hideDrawer, setHideDrawer ] = useState(true);
    const [ dataDrawer, setDataDrawer ] = useState(null);
    const [ dataDrawerType, setDataDrawerType ] = useState(null);
    const [ deleteComponent, setDeleteComponent ] = useState(null);
    const [ bulkPaymentComponent, setBulkPaymentComponent ] = useState(null);
    
    const [ contentType, setContentType ] = useState(null);
    const [ paySelected, setPaySelected ] = useState(null);
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [ snackbarData, setSnackbarData ] = useState({text: '', severity: ''});
    const [ isLoading, setIsLoading ] = useState(false);
    const [ canBulkPay, setCanBulkPay ] = useState(false);

    const columns = [{type: "checkbox", name: "check", label: '', orderable: false},
                     {type: "text", name: "description", label: "Descripción", orderable: false},
                     {type: "text", name: "quotes", label: "Cuotas", orderable: false},
                     {type: "text", name: "expense_type", label: "Tipo de gasto", orderable: false},
                     {type: "text", name: "amount_owed", label: "Monto", orderable: false},
                     {type: "text", name: "outstanding_balance", label: "Saldo", orderable: false},
                     {type: "text", name: "debt_status", label: "Estado", orderable: false},
                     {type: "text", name: "created_at", label: "Fecha Alta", orderable: false},
                     {type: "text", name: "actions", label: "Acciones", orderable: false}];         

    const handleCheckRow = (e, row) => {
        
        const exist = rowsSelected.filter(r => (r.id === row.id && r.type === row.expense_type.type));
        if(!exist.length){
            const add = [...rowsSelected];
            add.push({id: row.id, type: row.expense_type.type});
            setRowsSelected(add);
        }else{
            setRowsSelectedAll(false);
            const removed = rowsSelected.filter(r => {
                return !(r.id === row.id && r.type === row.expense_type.type);
            });
            setRowsSelected(removed);
        }

    }

    const handleCheckAllRow = (e, rows) => {
        
        if(e.target.checked){
            setRowsSelectedAll(true);
            const rows_id = rows.map(row => {
                return {id: row.id, type: row.expense_type.type};
            })
            setRowsSelected(rows_id);
        }else{
            setRowsSelectedAll(false);
            setRowsSelected([]);
        }

    }
            
    const handleEditOutcomeModal = (row) => {
        setActionForm("edit");
        setOptionSelected(row.expense_type.type)
        setDataToEdit(row);
        return null;
    }

    const handleDeleteModal = (type, row, expense_type) => {

        setContentType('outcome');
        setModalTitle('Eliminar');

        if(type === 'bulk'){
            setSingleRowsSelected(null);
            setDeleteComponent(handleDeleteComponent(null));
        }
        if(type === 'single'){
            setRowsSelected([]);
            setSingleRowsSelected({id:row.id, type: expense_type});
            setDeleteComponent(handleDeleteComponent(row.id, row));
        }
        
        setOpenDeleteModal(!openDeleteModal);

    }
    
    const handleDelete = () => {

        setIsLoading(true);
                
        let response;
        let payload = {};

        payload = {id: rowsSelected.length ? rowsSelected : singleRowsSelected, bulk: rowsSelected.length ? true : false};
        //response = deleteServices(payload);

        response.then(res => {

            let result = [];
            let snackbarDataText = '';
            let snackbarDataSeverity = 'success';
            
            





            let portfolioRemove = portfolio ? [...portfolio] : [];

            /*
            let servicesLogisticRemove = servicesLogistic ? [...servicesLogistic] : [];
            let servicesFoodRemove = servicesFood ? [...servicesFood] : [];
            let servicesFixesRemove = servicesFixes ? [...servicesFixes] : [];
            let servicesMiscellaneousRemove = servicesMiscellaneous ? [...servicesMiscellaneous] : [];
            */

            if(rowsSelected.length){

                snackbarDataText = 'Los gastos se eliminaron con éxito';
                //result = services.filter(service => !rowsSelected.some(obj => obj.id === service.id));

                for (let index = 0; index < rowsSelected.length; index++) {
                    switch (rowsSelected[index].type) {
                        case "portfolio":
                            portfolioRemove = portfolioRemove.filter(el => el.id !== rowsSelected[index].id);
                            break;
                        /*
                        case "logistic":
                            servicesLogisticRemove = servicesLogisticRemove.filter(el => el.id !== rowsSelected[index].id);
                            break;
                        case "food":
                            servicesFoodRemove = servicesFoodRemove.filter(el => el.id !== rowsSelected[index].id);
                            break;
                        case "fixes":
                            servicesFixesRemove = servicesFixesRemove.filter(el => el.id !== rowsSelected[index].id);
                            break;
                        case "miscellaneous":
                            servicesMiscellaneousRemove = servicesMiscellaneousRemove.filter(el => el.id !== rowsSelected[index].id);
                            break;
                        */
                        default:
                            break;
                    }
                }
                    
                switch (location.pathname) {
                    case "/portfolio":
                        setPortfolio(portfolioRemove);
                        break;
                    /*
                    case "/servicios/logistica":
                        setServicesLogistic(servicesLogisticRemove);
                        break;
                    case "/servicios/alimentos":
                        setServicesFood(servicesFoodRemove);
                        break;
                    case "/servicios/arreglos":
                        setServicesFixes(servicesFixesRemove);
                        break;
                    case "/servicios/gastos-varios":
                        setServicesMiscellaneous(servicesMiscellaneousRemove);
                        break;
                    */
                    default:
                        break;
                }

            }

            if(singleRowsSelected){
                
                snackbarDataText = 'El gasto se eliminó con éxito';
                //
                switch (singleRowsSelected.type) {
                    case "portfolio":
                        portfolioRemove = portfolioRemove.filter(el => el.id !== singleRowsSelected.id);
                        setPortfolio(portfolioRemove);
                        break;
                    /*
                    case "logistic":
                        servicesLogisticRemove = servicesLogisticRemove.filter(el => el.id !== singleRowsSelected.id);
                        setServicesLogistic(servicesLogisticRemove);
                        break;
                    case "food":
                        servicesFoodRemove = servicesFoodRemove.filter(el => el.id !== singleRowsSelected.id);
                        setServicesFood(servicesFoodRemove);
                        break;
                    case "fixes":
                        servicesFixesRemove = servicesFixesRemove.filter(el => el.id !== singleRowsSelected.id);
                        setServicesFixes(servicesFixesRemove);
                        break;
                    case "miscellaneous":
                        servicesMiscellaneousRemove = servicesMiscellaneousRemove.filter(el => el.id !== singleRowsSelected.id);
                        setServicesMiscellaneous(servicesMiscellaneousRemove);
                        break;
                    */
                    default:
                        break;
                }
            }








            setSnackbarData({text: snackbarDataText, severity: snackbarDataSeverity});
            setSnackbarOpen(true)
            setIsLoading(false);
            //setSingleRowsSelected(null);
            setRowsSelected([]);
            setOpenDeleteModal(false);
            setRowsSelectedAll(false);
            
        })
        
        
    }


    const handleDeleteComponent = (row_id, row) => {
        let text = '';
        
        if(rowsSelected.length){
            text = (<>Esta a punto de <span className='highlight'>eliminar {rowsSelected.length} registros</span>, desea continuar?</>);
        }
        if(row_id){
            const description = row;//getItemDescription(row);
            text = (<>Esta a punto de eliminar el registro <span className='highlight'>{ description }</span>, desea continuar?</>);
        }

        return(
            <div>
                {text}
            </div>
        )

    }
    
    const handleOnChangeMonth = (e) => {
        setMonthSelected(e.target.value);
        //filter_by_start_month(portfolio, e.target.value);
    }

    const handleDrawer = (row, type) => {

        setSingleRowsSelected(row.id);

        setHideDrawer(!hideDrawer);
        setOpenDrawer(!openDrawer);
        
        setDataDrawerType(type);
        setDataDrawer(row);

        setRowsSelected([]);
        setRowsSelectedAll(false);

    }
     
    /*
    useEffect(() => {

        getServices();

        const months = months_list(dayjs().month()+1);
        setMonths(months);

        const month_selected = months.filter(month => month.selected);
        if(month_selected.length){
            setMonthSelected(month_selected[0].value);
        }
        
        return () => {}
    }, [])
    
    
    
    if(services === null || !serviceCategories){
        return(
            <LoaderStyled>
                <CircularProgress 
                    type={'primary'} 
                    size={'medium'}
                />
            </LoaderStyled>
        )
    }
    */
   
    return (
        <WrapperStyled
            margins={margins}
            colors={colors}
        >
            
            <Snackbar 
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                snackbarData={snackbarData}
                setSnackbarData={setSnackbarData}
                hide={2500}
            />

            {/* {openDrawer ?
            <Drawer 
                data={services}
                dataSelected={singleRowsSelected}
                open={openDrawer}
                setOpen={setOpenDrawer}
                hide={hideDrawer}
                setHide={setHideDrawer}
                component={<View 
                                id={dataDrawer?.id}
                                dataDrawer={dataDrawer}
                                type={dataDrawerType}
                                setType={setDataDrawerType}
                                deleteComponent={deleteComponent}
                                setDeleteComponent={setDeleteComponent}
                                openEditModal={openAddExpenseModal}
                                setOpenEditModal={setOpenAddExpenseModal}
                                openDeleteModal={openDeleteModal}
                                setOpenDeleteModal={setOpenDeleteModal}
                                openAddExpenseModal={openAddExpenseModal}
                                setOpenAddExpenseModal={setOpenAddExpenseModal}
                                setModalTitle={setModalTitle}
                                setModalDescription={setModalDescription}
                                handleDeletePayment={handleDelete}
                                setContentType={setContentType}
                                paySelected={paySelected}
                                setPaySelected={setPaySelected}
                                handleEditExpense={handleEditExpense}
                            />}
            /> : ""} */}

            <ModalDelete 
                data={rowsSelected}
                title={modalTitle}
                component={deleteComponent}
                setComponent={setDeleteComponent}
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                handleSuccess={handleDelete}
                isloading={isLoading}
            />


            {/* {openAddExpenseModal &&
            <ModalExpenseServices 
                open={openAddExpenseModal}
                setOpen={setOpenAddExpenseModal}
                handleSuccess={null}
                //handleCancel={}
                title={modalTitle}
                description={modalDescription}
                data={paymentData}
                component={null}
                setComponent={null}
                paymentDataToEdit={paymentDataToEdit}
                setPaymentDataToEdit={setPaymentDataToEdit}
                rows={rows}
                setRows={setRows}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarData={setSnackbarData}
            />} */}

            { location.pathname === "/admin/account-detail" ?
                <AccountDetailTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                    dataToEdit={dataToEdit}
                    setDataToEdit={setDataToEdit}
                    filtersData={filtersData}
                    setFiltersData={setFiltersData}
                /> : ""
            }

            { location.pathname === "/admin/trust-fund" ?
                <TrustFundTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                    dataToEdit={dataToEdit}
                    setDataToEdit={setDataToEdit}
                    filtersData={filtersData}
                    setFiltersData={setFiltersData}
                /> : ""
            }

            { location.pathname === "/admin/performance" ?
                <PerformanceTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                    dataToEdit={dataToEdit}
                    setDataToEdit={setDataToEdit}
                    filtersData={filtersData}
                    setFiltersData={setFiltersData}
                /> : ""
            }

            { location.pathname === "/admin/portfolio" ?
                <PortfolioTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                    dataToEdit={dataToEdit}
                    setDataToEdit={setDataToEdit}
                    filtersData={filtersData}
                    setFiltersData={setFiltersData}
                /> : ""
            }
            
            { location.pathname === "/admin/debit-cards" ?
                <DebitCardTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                    dataToEdit={dataToEdit}
                    setDataToEdit={setDataToEdit}
                    filtersData={filtersData}
                    setFiltersData={setFiltersData}
                /> : ""
            }

            { location.pathname === "/admin/cash-flow" ?
                <CashflowTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                    dataToEdit={dataToEdit}
                    setDataToEdit={setDataToEdit}
                    filtersData={filtersData}
                    setFiltersData={setFiltersData}
                /> : ""
            }

            { location.pathname === "/admin/users" ?
                <UsersTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                    dataToEdit={dataToEdit}
                    setDataToEdit={setDataToEdit}
                /> : ""
            }
            
            {/* 
            { location.pathname === "/servicios/administracion" ?
                <AdministrationTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleBulkPaymentModal={handleBulkPaymentModal}
                    handleAddExpense={handleAddExpense}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                /> : ""
            }

            { location.pathname === "/servicios/logistica" ?
                <LogisticTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleBulkPaymentModal={handleBulkPaymentModal}
                    handleAddExpense={handleAddExpense}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                /> : ""
            }

            { location.pathname === "/servicios/alimentos" ?
                <FoodTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleBulkPaymentModal={handleBulkPaymentModal}
                    handleAddExpense={handleAddExpense}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                /> : ""
            }

            { location.pathname === "/servicios/arreglos" ?
                <FixesTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleBulkPaymentModal={handleBulkPaymentModal}
                    handleAddExpense={handleAddExpense}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                /> : ""
            }

            { location.pathname === "/servicios/gastos-varios" ?
                <MiscellaneousTable 
                    margins={margins}
                    colors={colors}
                    actionForm={actionForm}
                    setActionForm={setActionForm}
                    rowsSelected={rowsSelected}
                    rowsSelectedAll={rowsSelectedAll}
                    handleCheckAllRow={handleCheckAllRow}
                    handleOnChangeMonth={handleOnChangeMonth}
                    handleDeleteModal={handleDeleteModal}
                    handleBulkPaymentModal={handleBulkPaymentModal}
                    handleAddExpense={handleAddExpense}
                    handleDrawer={handleDrawer}
                    handleCheckRow={handleCheckRow}
                    handleEditOutcomeModal={handleEditOutcomeModal}
                /> : ""
            } */}

            {/*  */}

            {/* <TableFooter /> */}
        </WrapperStyled>
    );
};

TableDataServices.propTypes = {
    handleDelete: PropTypes.func,
    margins: PropTypes.shape({
        top: PropTypes.oneOf(maringSizes),
        bottom: PropTypes.oneOf(maringSizes),
        left: PropTypes.oneOf(maringSizes),
        right: PropTypes.oneOf(maringSizes),
    }),
    dataToEdit: PropTypes.string,
    setDataToEdit: PropTypes.func,
    optionSelected: PropTypes.string,
    setOptionSelected: PropTypes.func,
    actionForm: PropTypes.string,
    setActionForm: PropTypes.func,
    monthSelected: PropTypes.string,
    setMonthSelected: PropTypes.func,
};

export default TableDataServices;