export const colors = {
    primary: "135, 154, 198",
    secondary: "202, 218, 255",
    tertiary: "251, 252, 255",
    brand: "32, 41, 96",
    red: "214, 26, 40",
    green: "86, 181, 125",
    darkBlue: "32, 41, 96",
    lightBlue: "0, 104, 157",
    black: "0, 0, 0",
    white: "255, 255, 255",
    darkGrey: "148, 148, 148",
    grey: "229, 229, 229",
    lightGrey: "242, 242, 242",

    labelForm: "117, 132, 167",
    important: "130, 190, 0",
    cancel: "207, 0, 0",
    accept: "0, 122, 190",
    default: "6, 6, 6",
    error: "255, 0, 0",
};

export const maringSizes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const paddingSizes = maringSizes;

export const number_format = (number, decimals = 2, dec_point = ',', thousands_sep = '.') => {
    var n = !isFinite(+number) ? 0 : +number, 
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const fixParseFloat = (num_float) => {
    let number;
    if(typeof num_float !== 'number' && num_float){
        let value_coma = num_float; // Valor con coma como separador decimal
        let value_dot = value_coma.replace(',', '.'); // Reemplazar la coma por un punto
        number = parseFloat(value_dot);
    }else{
        number = num_float;
    }
    return number ? number : 0;
}

export const get_client_name = (clients, user_id) => {
    //if(clients){
        console.log("clients, user_id", clients, user_id)
        const client = clients.filter(c => c.id.toString() === user_id.toString());
        if(client.length){
            return client[0].name;
        }
    //}
    return "";

}

export const generateRandomCode = () => {
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    // Función para obtener un carácter aleatorio de un string
    function getRandomChar(str) {
        return str.charAt(Math.floor(Math.random() * str.length));
    }

    let codeArray = [];

    // Agregar 3 letras aleatorias al array
    for (let i = 0; i < 3; i++) {
        codeArray.push(getRandomChar(letters));
    }

    // Agregar 3 números aleatorios al array
    for (let i = 0; i < 3; i++) {
        codeArray.push(getRandomChar(numbers));
    }

    // Mezclar el array de letras y números aleatoriamente
    codeArray.sort(() => Math.random() - 0.5);

    // Unir el array en un string y devolverlo
    return codeArray.join('');
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const user_types = () => {

    return [{value: "client", label: "Client"},{value: "admin", label: "Admin"}];

}