import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../helpers/commons';

const WrapperStyled = styled.div`
    border-bottom: 1px solid ${props => `rgba(${props.colors.secondary}, 1)` };
    padding-bottom: 10px;
`

const ModalHeader = ({children, className}) => {
    return (
        <WrapperStyled
            colors={colors}
            className={className}
        >
            {children}
        </WrapperStyled>
    );
};

ModalHeader.propTypes = {
    className: PropTypes.string,
};

export default ModalHeader;