import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputCurrency } from '../../Atoms/InputCurrency/InputCurrency';
import { Label } from '../../Atoms/Label/Label';
import { Icon } from '../../Atoms/Icon/Icon';
import { FormErrorLabel } from '../../Atoms/FormErrorLabel';
// import './field.css';

const FieldStyled = styled.div`
    display: flex;
    flex-direction: column;
    .containet_label{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: ${props => (props.input_reverse || !props.input_icon) ? '0' : '37px'};
        margin-bottom: 3px;
        .icon{
            display: flex;
            padding-right: 2px;
            svg{
                display: flex;
            }
        }
        &.reverse{
            .icon{
                margin-right: 0;
                margin-left: 0;
            }
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
    .containet_input{
        display: flex;
        flex-direction: row;
        align-items: center;
        .input{
            width: calc(100% - 40px);
        }
        .icon{
            display: flex;
            justify-content: center;
            width: 40px;
            padding-right: 2px;
            /* background-color: pink; */
        }
        &.no-icon{
            .input{
                width: 100%!important;
            }
        }
        &.reverse{
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
`

const FieldInputCurrency = ({ uuid, onChange, name, error, label, input, icon, value, disabled, errorMessage, ...props }) => {
    
    return (
        <FieldStyled
            key={uuid}
            input_reverse={input.reverse ? true : undefined}
            input_icon={input.icon.component}
        >
            
            <div className={`containet_label ${label.reverse ? 'reverse' : ''}`}>
                {label.icon.component ?
                <Icon 
                    type={label.icon.type}
                    icon={label.icon.component}
                    size={`label ${label.icon.size}`}
                    error={error}
                /> : ""}
                <Label 
                    type={label.type}
                    label={label.text} 
                    error={error}
                />
            </div>

            <div className={`containet_input ${input.reverse ? 'reverse' : ''} ${!input.icon.component ? 'no-icon' : ''}`}>
                {input.icon.component ?
                <Icon 
                    type={input.icon.type}
                    icon={input.icon.component}
                    size={`input ${input.icon.size}`} 
                    error={error}
                /> : ""}
                <InputCurrency 
                    name={name}
                    type={input.type}
                    placeholder={input.placeholder} 
                    size={input.size} 
                    className={input.className} 
                    reverse={input.reverse} 
                    error={error}
                    prefix={input.prefix}
                    suffix={input.suffix}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                />
            </div>

            {error && errorMessage !== null ?
            <FormErrorLabel
                label={errorMessage.label}
                type={errorMessage.type}
                size={errorMessage.size}
            /> : ""}
            
        </FieldStyled>
    );
};

FieldInputCurrency.propTypes = {
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    icon: PropTypes.element,
    error: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.object,
    uuid: PropTypes.string,
    
    input: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        placeholder: PropTypes.string,
        icon: PropTypes.shape({
            uuid: PropTypes.string,
            type: PropTypes.string,
            component: PropTypes.element,
            size: PropTypes.oneOf(['small', 'medium', 'large']),
            //error: PropTypes.bool,
        }),
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        className: PropTypes.string,
        reverse: PropTypes.bool,
        //error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    }),

    label: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        text: PropTypes.string,
        icon: PropTypes.shape({
            uuid: PropTypes.string,
            type: PropTypes.string,
            component: PropTypes.element,
            size: PropTypes.oneOf(['small', 'medium', 'large']),
            //error: PropTypes.bool,
            scope: PropTypes.string,
        }),
        className: PropTypes.string,
        reverse: PropTypes.bool,
        //error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    }),
};


export default FieldInputCurrency;