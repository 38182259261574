import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from '../../Atoms/Typography/Typography';
import { colors, maringSizes } from '../../../helpers/commons';

const WrapperStyled = styled.div`
    background-color: #FFFFFF;
`

const FieldPayment = ({first, second, className}) => {
    return (
        <WrapperStyled
            className={`${className}`}
        >

            {first &&
                <Typography
                    variant={first.variant}
                    size={first.size}
                    type={first.type}
                >
                    {first.currency}{first.value}
                </Typography>
            }
            {second &&
                <Typography
                    variant={second.variant}
                    size={second.size}
                    type={second.type}
                >
                    {second.currency}{second.value}
                </Typography>
            }

        </WrapperStyled>
    );
};

FieldPayment.propTypes = {
    className: PropTypes.string,
    first: PropTypes.shape({
        value: PropTypes.string,
        className: PropTypes.string,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'small']),
        currency: PropTypes.string,
    }),
    second: PropTypes.shape({
        value: PropTypes.string,
        className: PropTypes.string,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'small']),
        currency: PropTypes.string,
    }),
};

export default FieldPayment;