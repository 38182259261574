import React from 'react';
import { ButtonStyled } from './styles';

const ButtonCustom = (props) => {

    const { label,
            className,
            onClick } = props;

    return (
        <ButtonStyled
            className={className}
            onClick={onClick}
        >
            { label }
        </ButtonStyled>
    );
};

export default ButtonCustom;