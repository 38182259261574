import React, { useContext, useState, useEffect } from 'react';
import { createBrowserRouter,
         RouterProvider,
         redirect,
         useLocation } from "react-router-dom";
import { AuthContext,
         ContentContext } from '../../context';
import { WrapperStyled } from './styles';
import { IoChevronForwardCircleOutline } from "react-icons/io5";

import { Scrollbar } from 'react-scrollbars-custom';

const Benefits = () => {

    const [ monthSelected, setMonthSelected ] = useState("");

    const handleChangeMonth = (month) => {

        setMonthSelected(month);

    }

    return (
        <WrapperStyled>
            <div className='content'>
                <div className='title'>
                    <div className='icon'>
                        <img src='images/iso_blue.svg' />
                    </div>
                    <div className='text'>OUR <span className='highlighted'>BENEFITS</span></div>
                </div>
                <div className='image_benefit'>
                    <div className='inner'>
                        <img src='images/img_benefits.jpg' />
                    </div>
                </div>
                <div className='items'>
                    <div className='item'>
                        <div className='icon'>
                            <IoChevronForwardCircleOutline />
                        </div>
                        <div className='text'>Enjoy <span className='highlighted'>free access to all VIP lounges</span> at airports worldwide*.</div>
                    </div>
                    <div className='item'>
                        <div className='icon'>
                            <IoChevronForwardCircleOutline />
                        </div>
                        <div className='text'>Access discounts of <span className='highlighted'>20% and 30% on international flights*.</span></div>
                    </div>
                </div>
                <div className='terms'>
                    <div className='text'>Subject to <span className='highlighted'>terms and conditions.</span></div>
                </div>
            </div>
        </WrapperStyled>
    );
};

export default Benefits;