import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, maringSizes } from '../../../helpers/commons';

const BadgeStyled = styled.div`
    display: flex;
    align-self: start;
    font-size: ${props => props.type === "text" ? "11px" : "16px"};
    line-height: ${props => props.type === "text" ? "11px" : "16px"};
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: ${props => props.type === "text" ? "5px 10px" : "5px 5px"};
    border-radius: 6px;
    &.pending{
        background-color: ${props => `rgba(${props.colors.cancel}, 1)` };
    }
    &.complete{
        background-color: ${props => `rgba(${props.colors.important}, 1)` };
    }

    margin-top: ${props => (props.margins && props.margins.top) ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => (props.margins && props.margins.bottom) ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => (props.margins && props.margins.left) ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => (props.margins && props.margins.right) ? 5 * props.margins.right + "px" : 0}!important;
`

const Badge = ({ type, data, margins }) => {
    return (
        <BadgeStyled
            className={`${data.type}`}
            colors={colors}
            margins={margins}
            type={type}
        >
            {data.label}
        </BadgeStyled>
    );
};

Badge.propTypes = {
    data: PropTypes.object,
    type: PropTypes.string,
    margins: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number,
    }),
};

Badge.defaultProps = {
    margins: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
};

export default Badge;