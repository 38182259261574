import React, { useContext, useState, useEffect } from 'react';
import { WrapperStyled,
         LoaderStyled } from './styles';
import { AuthContext, ContentContext } from '../../context';
//import logo from '../../assets/logo.svg'
import { useNavigate, useLocation } from 'react-router-dom';
import { InputCustom } from '../../components/Form/InputCustom';
import { ButtonCustom } from '../../components/ButtonCustom';
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
    
    const navigate = useNavigate();
    const location = useLocation();

    const { login,
            logout,
            setIsAuth,
            setAuthToken } = useContext(AuthContext);
    
    const { change_password } = useContext(ContentContext);
            

    const [ isLoading, setIsLoading ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ formDataChange, setFormDataChange ] = useState({});
    const [ resetPassword, setResetPassword ] = useState(false);
    const [ changePasswordErrors, setChangePasswordErrors ] = useState(null);
    const [ tokenToChangePass, setTokenToChangePass ] = useState("");
    const [ passChanged, setPassChanged ] = useState(false);
    

    useEffect(() => {

        setFormData({
            email: {
                value: '',
                mandatory: true,
                error: false,
                onChange: handleOnChange,
                disabled: false,
                errorMessage: null,
            },
            password: {
                value: '',
                mandatory: true,
                error: false,
                onChange: handleOnChange,
                disabled: false,
                errorMessage: null,
            },
        })

        setFormDataChange({
            password: {
                value: '',
                mandatory: true,
                error: false,
                onChange: handleOnChangePassword,
                disabled: false,
                errorMessage: null,
            },
            repeat_password: {
                value: '',
                mandatory: true,
                error: false,
                onChange: handleOnChangePassword,
                disabled: false,
                errorMessage: null,
            },
        })
    

    return () => {
        setFormDataChange({});
        setFormData({});
        setIsLoading(false);
    }
    }, [])

    const handleOnChange = (e, name) => {

        const update = {...formData}

        update[name].value = e.target.value;
        if(e.target.value.length){
            update[name].error = false;
        }

        setFormData(update);
        
    }

    const handleOnChangePassword = (e, name) => {

        //console.log(e.target.value, name);
        //return null;

        const update = {...formDataChange}

        update[name].value = e.target.value;
        if(e.target.value.length){
            update[name].error = false;
        }

        setFormDataChange(update);
        
    }

    

    const handleSendForm = () => {
        
        let haveError = false;

        let formDataValidate = {...formData};
        Object.keys(formDataValidate).map((field) => {

            if(formDataValidate[field].mandatory){
                
                if(!formDataValidate[field].value.length){
                    haveError = true;
                    formDataValidate[field].error =  true;
                }else{
                    formDataValidate[field].error =  false;
                }
            }
            
        })

        setFormData(formDataValidate);
        
        if(!haveError){
            setIsLoading(true);
            //console.log("SEND");
            
            const result = login(formData["email"].value, formData["password"].value);
            result.then(res => {
                setIsLoading(false);
                if(res.data.status){
                    //console.log("YYYYYYY", res.data)
                    setTokenToChangePass(res.data.token);
                    if(!res.data.user.email_verified_at){
                        setResetPassword(true);
                        //console.log("🔥 no email_verified_at")
                    }else{
                        localStorage.setItem('token', res.data.token)
                        localStorage.setItem('is_admin', Boolean(res.data.user.is_admin))
                        //console.log("🚀 email_verified_at");
                        setAuthToken(res.data.token);
                        setIsAuth(true);
                        if(res.data.user.is_admin){
                            navigate(`/admin/dashboard`); 
                        }else{
                            navigate(`/dashboard`); 
                        }
                    }
                }else{
                    alert("error")
                }
            })
            
        }
    }

    const handleSendChangeForm = () => {
        
        let haveError = false;

        let formDataValidate = {...formDataChange};
        Object.keys(formDataValidate).map((field) => {

            if(formDataValidate[field].mandatory){
                
                if(!formDataValidate[field].value.length){
                    haveError = true;
                    formDataValidate[field].error =  true;
                }else{
                    formDataValidate[field].error =  false;
                }
            }
            
        })

        setFormDataChange(formDataValidate);
        
        if(!haveError){
            setIsLoading(true);
            const payload = {token: tokenToChangePass,
                             password: formDataChange["password"].value, 
                             repeat_password: formDataChange["repeat_password"].value}
            const result = change_password(payload);
            result.then(res => {
                setIsLoading(false);
                if(res.data.success){
                    setPassChanged(true);
                }else{
                    let message_arr = [];

                    if("password" in res.data.errors){
                        for (let index = 0; index < res.data.errors.password.length; index++) {
                            message_arr.push(`- ${res.data.errors.password[index]}\n`);
                        }
                    }

                    if("repeat_password" in res.data.errors){
                        for (let index = 0; index < res.data.errors.repeat_password.length; index++) {
                            message_arr.push(`- ${res.data.errors.repeat_password[index]}\n`);
                        }
                    }

                    setChangePasswordErrors(message_arr);
                    //console.log("🚀", res.data.errors.password.length)
                }
                
            })
            
        }
    }
    
    const handleCloseChangeError = () => {
        setChangePasswordErrors(null);
    }

    const handleBackToLogin = () => {
        setTokenToChangePass(null);
        setResetPassword(false);
        logout();
    }

    useEffect(() => {
      //console.log("formData", formData)
    
      return () => {}
    }, [formData])
    
    if(!Object.keys(formData).length || !Object.keys(formDataChange).length){
        return null;
    }

    return (
        <WrapperStyled>
            <div className='box_login'>
                <div className='left'>
                    <div className='image_login'>
                        <img src='images/logo_login.svg' />
                    </div>
                    <div className='image_login_mobile'>
                        <img src='images/logo_login_mobile.svg' />
                    </div>
                </div>
                <div className='right'>
                    {changePasswordErrors ? 
                    <div className='error_change_password'>
                        <div className='inner_error_change_password'>
                            {changePasswordErrors}
                            <ButtonCustom 
                                label="CLOSE"
                                className="close_error_change_password"
                                onClick={handleCloseChangeError}
                            />
                        </div>
                    </div> : ""}
                    {resetPassword ?
                    <div className='inner_change_password'>
                        {!passChanged ?
                        <>
                        <div className='title'>
                            Change your password
                        </div>
                        <div className='form'>

                            <input 
                                name="password"
                                placeholder="Password..."
                                className={`password${formDataChange?.password.error ? " error" : ""}`}
                                type="password"
                                value={formDataChange?.password.value}
                                onChange={(e) => handleOnChangePassword(e, "password")}
                            />
                            <div className='pass_aclaration'><strong>The password must contain:</strong><br/>- At least one letter<br/>- At least one uppercase and one lowercase letter<br/>- At least one number<br/>- At least one symbol</div>
                            <input 
                                name="repeat_password"
                                placeholder="Repeat Password..."
                                className={`repeat_password${formDataChange?.repeat_password.error ? " error" : ""}`}
                                type="password"
                                value={formDataChange?.repeat_password.value}
                                onChange={(e) => handleOnChangePassword(e, "repeat_password")}
                            />

                            {!isLoading ? 
                                <ButtonCustom 
                                    label="SEND"
                                    className="send_change_password"
                                    onClick={handleSendChangeForm}
                                />
                            :
                                <div className='loader'>
                                    <CircularProgress size={30} />
                                </div>
                            }
                        </div>
                        </>
                        :
                        <div className='inner_pass_changed'>
                            <div className='pass_changed_back_message'>Your password has been successfully changed. Please return to the login page to sign in with your new password.</div>
                            <ButtonCustom 
                                label="BACK TO LOGIN"
                                className="send_back_to_login"
                                onClick={handleBackToLogin}
                            />
                        </div>
                        }
                    </div>
                    :
                    <div className='inner_login'>
                        <div className='title'>
                            User Login
                        </div>
                        <div className='form'>
                            <InputCustom 
                                name="email"
                                className="user"
                                type="text"
                                placeholder="Username..."
                                handleOnChange={handleOnChange}
                                error={formData.email.error}
                            />
                            <InputCustom 
                                name="password"
                                className="password"
                                type="password"
                                placeholder="Password..."
                                handleOnChange={handleOnChange}
                                error={formData.password.error}
                            />
                            {!isLoading ? 
                                <ButtonCustom 
                                    label="LOGIN"
                                    className="send_login"
                                    onClick={handleSendForm}
                                />
                            :
                                <div className='loader'>
                                    <CircularProgress size={30} />
                                </div>
                            }
                        </div>
                    </div>
                    }
                </div>
            </div>
        </WrapperStyled>
    );
};

export default Login;