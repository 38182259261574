import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { colors, maringSizes } from '../../../helpers/commons';

const CircularProgressStyled = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    

    &.small{
        width: 20px;
        height: 20px;
        .circular{
            width: 20px!important;
            height: 20px!important;
        }
    }
    
    &.medium{
        width: 28px;
        height: 28px;
        .circular{
            width: 28px!important;
            height: 28px!important;
        }
    }
    
    &.large{
        width: 35px;
        height: 35px;
        .circular{
            width: 35px!important;
            height: 35px!important;
        }
    }
    
    &.primary{
        .circular{
            color: ${props => `rgba(${props.colors.primary}, 1)` };
        }
    }

    &.default{
        .circular{
            color: ${props => `rgba(${props.colors.primary}, 1)` };
        }
    }

    &.important{
        .circular{
            color: ${props => `rgba(${props.colors.important}, 1)` }!important;
        }
    }

    &.cancel{
        .circular{
            color: ${props => `rgba(${props.colors.cancel}, 1)` };
        }
    }

    &.accept{
        .circular{
            color: ${props => `rgba(${props.colors.accept}, 1)` };
        }
    }
    &.white{
        .circular{
            color: #FFFFFF;
        }
    }

    &.shadowed{
        background-color: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.35);
        -webkit-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.35);
        -moz-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.35);
        &.small{
            width: 30px;
            height: 30px;
            border-radius: 15px;
            .circular{
                width: 20px!important;
                height: 20px!important;
            }
        }
        &.medium{
            width: 50px;
            height: 50px;
            border-radius: 28px;
            .circular{
                width: 40px!important;
                height: 40px!important;
            }
        }
        
        &.large{
            width: 70px;
            height: 70px;
            border-radius: 35px;
            .circular{
                width: 55px!important;
                height: 55px!important;
            }
        }
    }
    

`
const _CircularProgress = ({ className = '', size, type, shadowed }) => {
    return (
        <CircularProgressStyled
            colors={colors}
            className={`loader ${className} ${size} ${type} ${shadowed ? 'shadowed' : ''}`}
        >
            <CircularProgress className='circular'/>
        </CircularProgressStyled>
    );
};

_CircularProgress.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form', 'white']),
    shadowed: PropTypes.bool,
};

_CircularProgress.defaultProps = {
    type: 'important',
    size: 'small',
    shadowed: false,
};

export default _CircularProgress;