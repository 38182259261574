import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { colors } from '../../helpers/commons';

export const WrapperStyled = styled.div`
    .loader{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        svg{
            color: #202960;//rgba(0, 104, 157, 1);
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        width: 100%;
        border: 1px solid #e5e5e5;
        padding: 15px 30px;
        height: calc(100vh - 120px);
        .top{
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .months{
                display: flex;
                flex-direction: row;
                :last-child{
                    margin-right: 0!important;
                }
                .month,
                .actual_month{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    line-height: 14px;
                    //font-weight: 500;
                    height: 30px;
                    width: 45px;
                    color: rgba(${colors.white}, 1);
                    background-color: rgba(${colors.darkGrey}, 1);
                    margin-right: 5px;
                    font-weight: 600;
                    cursor: pointer;
                    &.selected{
                        background-color: rgba(${colors.darkBlue}, 1);
                    }
                    &:hover{
                        background-color: rgba(${colors.darkBlue}, 1);
                    }
                }
                .actual_month{
                    width: 135px!important;
                }
            }
        }
        .scrollbar{
            display: flex;
            height: 100%!important;
        }
        .bottom{
            display: flex;
            width: 100%;
            height: 100%;
            .table{
                width: 100%;
                border: 1px solid rgba(${colors.darkGrey}, 1);
                margin-top: 15px;
                .nodata{
                    width: 100%;
                    text-align: center;
                    padding-top: 40px;
                    font-size: 20px;
                    line-height: 20px;
                    font-weight: 500;
                }
                .head{
                    display: flex;
                    flex-direction: row;
                    background-color: rgba(${colors.darkBlue}, 1);
                    color: rgba(${colors.white}, 1);
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 13px;
                    width: 500px;
                    .column{
                        padding: 10px 5px;
                        text-align: center;
                        //margin-left: 15px;//1513
                        border-left: 1px solid rgba(${colors.darkGrey}, 1);
                        &:first-child{
                            border-left: 0;
                            margin-left: 0;
                        }
                        &.description{
                            //width: calc(100% - 450px);
                        }
                    }
                }
                .rows{
                    display: flex;
                    flex-direction: column;
                    background-color: rgba(${colors.white}, 1);
                    color: rgba(${colors.darkBlue}, 1);
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 13px;
                    height: 100%;
                    width: 500px;
                    .row{
                        display: flex;
                        flex-direction: row;
                        div{
                            padding: 10px 5px;
                            //margin-left: 15px;
                            border-left: 1px solid rgba(${colors.darkGrey}, 1);
                            &:first-child{
                                border-left: 0;
                                margin-left: 0;
                            }
                        }
                        &.gray{
                            background-color: rgba(${colors.lightGrey}, 1);
                        }
                    }
                }
                .date{
                    text-align: center;
                    width: 110px;
                }
                .description{
                    width: calc(100% - 220px);
                }
                .value{
                    text-align: center;
                    width: 110px;
                }
            }
        }
    }

    @media ${breakpoints.xs} {//576px
        .content{
            .bottom{
                .table{
                    .head{
                        width: 1000px;
                        font-size: 15px;
                        line-height: 15px;
                        .column{
                            padding: 15px 10px;
                        }
                    }
                    .rows{
                        width: 1000px;
                        font-size: 15px;
                        line-height: 15px;
                        .row{
                            div{
                                padding: 15px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media ${breakpoints.sm} {//768px
        .content{
            .bottom{
                .table{
                    .head{
                        width: 1000px;
                        font-size: 15px;
                        line-height: 15px;
                        .column{
                            padding: 15px 10px;
                        }
                    }
                    .rows{
                        width: 1000px;
                        font-size: 15px;
                        line-height: 15px;
                        .row{
                            div{
                                padding: 15px 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media ${breakpoints.md} {//992px
        .content{
            .bottom{
                .table{
                    .head{
                        width: auto;
                        font-size: 15px;
                        line-height: 15px;
                        .column{
                            padding: 15px 10px;
                        }
                    }
                    .rows{
                        width: auto;
                        font-size: 15px;
                        line-height: 15px;
                        .row{
                            div{
                                padding: 15px 10px;
                            }
                        }
                    }
                    .date{
                        text-align: center;
                        width: 140px;
                    }
                    .description{
                        width: calc(100% - 290px);
                    }
                    .value{
                        text-align: center;
                        width: 150px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.lg} {//1200px // Desktop
        .content{
            .bottom{
                .table{
                    .head{
                        font-size: 15px;
                        line-height: 15px;
                        .column{
                            padding: 20px 20px;
                        }
                    }
                    .rows{
                        font-size: 15px;
                        line-height: 15px;
                        .row{
                            div{
                                padding: 20px 20px;
                            }
                        }
                    }
                }
            }
        }
        
    }
    @media ${breakpoints.xl} {//1366px
        .content{
            .bottom{
                .table{
                    .head{
                        font-size: 16px;
                        line-height: 16px;
                    }
                    .rows{
                        font-size: 16px;
                        line-height: 16px;
                    }
                    .date{
                        text-align: center;
                        width: 170px;
                    }
                    .description{
                        width: calc(100% - 370px);
                    }
                    .value{
                        text-align: center;
                        width: 200px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.xxl} {//1440px
        .content{
            .bottom{
                .table{
                    
                }
            }
        }
    }
    @media ${breakpoints.xxxl} {//1680px
        .content{
            .bottom{
                .table{
                    .date{
                        text-align: center;
                        width: 170px;
                    }
                    .description{
                        width: calc(100% - 450px);
                    }
                    .value{
                        text-align: center;
                        width: 280px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`