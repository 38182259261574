import React, { Fragment, useEffect, useState, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Snackbar } from '../../Atoms/Snackbar';

import { ContainerGrid } from '../../Atoms/Container/ContainerGrid';
import { FormStructure } from '../FormStructure';
import { ContentContext } from '../../../context/ContentContext';

const FormStyled = styled.div`
    >.container{
        position: relative;
        overflow: hidden;
        >.inner{
            >.field_complex{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }
`

const FormCashflow = ({actionType, 
                      dataToEdit, 
                      setDataToEdit, 
                      actionForm, 
                      setActionForm,
                      monthSelected,
                      filtersData, 
                      setFiltersData }) => {
    
                
    const { cashflow, 
            setCashflow,
            editCashflow,
            createCashflow,
            clients } = useContext(ContentContext)

    const [ formData, setFormData ] = useState({});
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [ snackbarData, setSnackbarData ] = useState({text: '', severity: ''});
    const [ isLoading, setIsLoading ] = useState(false);
    
    useEffect(() => {
        //if(filtersData){
            
        
            const data = {
                client: {
                    isSlave: false,
                    masterOf: null,
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: '',
                        icon: { component: null },
                        size: 'medium',
                        type: 'primary',
                        options: clients,
                    },
                    label: {
                        text: 'Client',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.user_id ? dataToEdit.user_id : '',
                    mandatory: true,
                    type: 'select:common',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    innerElement: null,
                    dependendComponent: null,
                    errorMessage: null,
                    disableElements: null,
                },
                concept: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a concept...',
                    },
                    label: {
                        text: 'Concept',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.concept ? dataToEdit.concept : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                date: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a date...',
                    },
                    label: {
                        text: 'Date',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.date ? dayjs(dataToEdit.date).format("DD/MM/YYYY") : '',
                    mandatory: true,
                    type: 'input:masked',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                value: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a value...',
                    },
                    label: {
                        text: 'Value',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.value ? dataToEdit.value : '',
                    mandatory: false,
                    type: 'input:currency',
                    prefix: '',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
            }

            setFormData(data);
        //}
    
      return () => {
        setFormData({});
      }
    }, [dataToEdit, clients])//filtersData

    const handleCancel = () => {
        resetForm();
    }

    const handleOnChange = (e, name) => {
        
        const update = {...formData}



        if(name !== 'quantity' && name !== 'value' && name !== 'amount' && name !== 'balance'){
            update[name].value = e.target.value;
            if(e.target.value.length){
                update[name].error = false;
            }
        }else{
            update[name].value = e;
            if(e?.length){
                update[name].error = false;
            }
        }

        setFormData(update)

    }

    const handleSendForm = () => {
        
        setIsLoading(true);
        let haveError = false;

        let formDataValidate = {...formData};
        Object.keys(formDataValidate).map((field) => {
            
            if(formDataValidate[field].mandatory && field !== 'empty' && !formDataValidate[field].disabled){
                
                if(!formDataValidate[field].value.toString().length){
                    haveError = true;
                    formDataValidate[field].error =  true;
                }else{
                    formDataValidate[field].error =  false;
                }
            }
            
        })
        setFormData(formDataValidate);

       

        if(!haveError){
            
            let update_json = [];
            let data = '';
            let id = uuid();

            let formDataToSend = Object.keys(formData).map((field) => {
                return {field: field, value: formData[field].value};
            })

            if(dataToEdit){
                
                data = {"id": dataToEdit.id,
                        "user_id": formData["client"].value, 
                        "concept": formData["concept"].value, 
                        "value": formData["value"].value, 
                        "date": formData["date"].value,  
                        };
                
                const update_json_ = [...cashflow];
                update_json = update_json_.map((el, index) => {
                    
                    if(el.id === dataToEdit.id){
                        return data;
                    }else{
                        return el;
                    }
                })
                

            }else{

                data = {"uuid": id,
                        "user_id": formData["client"].value, 
                        "concept": formData["concept"].value, 
                        "value": formData["value"].value, 
                        "date": formData["date"].value,  
                        };

                update_json = [...cashflow];
                update_json.push(data);

            }
            
            const payload = data;
            let result;
            
            
            if(dataToEdit){
                result = editCashflow(payload);
            }else{
                result = createCashflow(payload);
            }
            
            
            result.then(res => {
                
                setSnackbarData({text: dataToEdit ? 'The data was successfully updated.' : 'The data was successfully saved.', severity: 'success'});
                setSnackbarOpen(true)
                setIsLoading(false);

                let data_cashflow = {user_id: res.data.record.user_id,
                                     created_at: res.data.record.created_at,
                                     deleted_at: res.data.record.deleted_at,
                                     date: res.data.record.date,
                                     concept: res.data.record.concept,
                                     id: res.data.record.id,
                                     value: res.data.record.value,
                                     amount: res.data.record.amount,
                                     balance: res.data.record.balance,
                                     updated_at: res.data.record.updated_at};
                                    
                if(dataToEdit){
                    const cashflowUpdated = cashflow.map(record => {
                        if(record.id === res.data.record.id){
                            return data_cashflow;//res.data.record;
                        }else{
                            return record;
                        }
                    })
                    setCashflow(cashflowUpdated);
                }else{
                    let cashflowUpdated = [...cashflow];
                    cashflowUpdated.push(data_cashflow);
                    setCashflow(cashflowUpdated);
                }

                if(!dataToEdit){
                    resetForm();
                }
            })

        }else{
            setIsLoading(false);
            return null;
        }
        
    }

    const resetForm = () => {

        let formReset = {...formData};
        Object.keys(formReset).map((field) => {
            formReset[field].value = "";
            formReset[field].error = false;
        })
        setFormData(formReset);

    }

    useEffect(() => {
        //console.log("clients", clients)
    
      return () => {}
    }, [clients])
    
    useEffect(() => {
      return () => {
        setDataToEdit(null);
        setActionForm('');
        resetForm();
      }
    }, [])
    
    if(!clients){
        return null;
    }

    return (
        <FormStyled>
            <Snackbar 
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                snackbarData={snackbarData}
                setSnackbarData={setSnackbarData}
                hide={2500}
            />
            <ContainerGrid
                className={`container`}
                margins={{top: 4}}
            > 
                <FormStructure 
                    formData={formData}
                    setFormData={setFormData}
                    handleOnChange={handleOnChange}
                    handleSendForm={handleSendForm}
                    handleCancel={handleCancel}
                    dataToEdit={dataToEdit}
                    isloading={isLoading}
                    payButton={false}
                />
            </ContainerGrid>
        </FormStyled>
    );
};

export default FormCashflow;