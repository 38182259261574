import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../helpers/commons';
import { IoMdCheckmark } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { ImCheckmark } from "react-icons/im";

const CheckboxStyled = styled.div`

    input {
        position: absolute;
        padding: 0;
        margin: 0;
        cursor: pointer;
        border: 1px solid #CADAFF;
        outline: 0;
        opacity: 0;
        z-index: 99;
    }
    .checkmark{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border: 1px solid #CADAFF;
        background-color: #FFFFFF;
        border-radius: 6px;
        .icon{
            display: flex;
            font-size: 25px;
            line-height: 25px;
            //position: absolute;
        }
    }
    input ~ .checkmark .icon{
        display: none;
    }
    input:checked ~ .checkmark .icon{
        display: flex;
        color: rgba(135, 154, 198, 1);
    }
    input:checked ~ .checkmark{
        font-size: 30px;
        font-weight: 600;
        //content: 'x';
    }
    input:checked ~ .checkmark::after{
        display: flex;
        justify-content: center;
        align-items: center;
        //content: 'x';
    }

    &.xsmall{
        input {
            width: 26px;
            height: 26px;
        }
        .checkmark{
            width: 26px;
            height: 26px;
            border-radius: 3px;
            .icon{
                font-size: 14px;
                line-height: 14px;
            }
        }
    }
    &.small{
        input {
            width: 37px;
            height: 37px;
        }
        .checkmark{
            width: 37px;
            height: 37px;
            border-radius: 6px;
            .icon{
                font-size: 20px;
                line-height: 20px;
            }
        }

    }
    &.medium{

        input {
            width: 51px;
            height: 51px;
        }
        .checkmark{
            width: 51px;
            height: 51px;
            border-radius: 6px;
            .icon{
                font-size: 25px;
                line-height: 25px;
            }
        }

    }
    &.large{

        input {
            width: 55px;
            height: 55px;
        }
        .checkmark{
            width: 55px;
            height: 55px;
            border-radius: 6px;
            .icon{
                font-size: 30px;
                line-height: 30px;
            }
        }

    }

    &.important{
        .checkmark{
            border-color: ${props => `rgba(${props.colors.important}, 1)` };
            .icon{
                color: ${props => `rgba(${props.colors.important}, 1)` }!important;
            }
        }
    }
    &.cancel{
        .checkmark{
            border-color: ${props => `rgba(${props.colors.cancel}, 1)` };
            .icon{
                color: ${props => `rgba(${props.colors.cancel}, 1)` }!important;
            }
        }
    }
    &.accept{
        .checkmark{
            border-color: ${props => `rgba(${props.colors.accept}, 1)` };
            .icon{
                color: ${props => `rgba(${props.colors.accept}, 1)` }!important;
            }
        }
    }
    &.default{
        .checkmark{
            border-color: ${props => `rgba(${props.colors.default}, 1)` };
            .icon{
                color: ${props => `rgba(${props.colors.default}, 1)` }!important;
            }
        }
    }
    &.primary{
        .checkmark{
            border-color: ${props => `rgba(${props.colors.primary}, 1)` };
            .icon{
                color: ${props => `rgba(${props.colors.primary}, 1)` }!important;
            }
        }
    }

`

export const Checkbox = ({ onClick,
                           onChange,
                           className, 
                           checked,
                           error,
                           type,
                           size, 
                           name, 
                           ...props }) => {
                            
    return (

        <CheckboxStyled
            colors={colors}
            className={`${size} ${type}`}
        >
            <input type="checkbox" onChange={onChange} checked={checked} />
            <div className="checkmark">
                <div className='icon'>
                    <ImCheckmark />
                </div>
            </div>
        </CheckboxStyled>

    );
};

Checkbox.propTypes = {
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    name: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
};

Checkbox.defaultProps = {
    type: 'primary',
    size: 'medium',
    name: '',
    className: "input-masked",
    error: false,
};