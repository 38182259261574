import React, { useContext, useState, useEffect } from 'react';
import { createBrowserRouter,
         RouterProvider,
         redirect,
         useLocation } from "react-router-dom";
import { ContentContext } from '../../context';
import { WrapperStyled } from './styles';
import { Scrollbar } from 'react-scrollbars-custom';
import { CircularProgress } from '@mui/material';
import { number_format } from '../../helpers/commons';

const Portfolio = () => {

    const { get_user_cashflow } = useContext(ContentContext);

    const [ monthSelected, setMonthSelected ] = useState(0);
    const [ cashflow, setCashflow ] = useState(null);

    const handleChangeMonth = (month) => {
        
        handleGetData({month_range: month});
        setMonthSelected(month);

    }

    useEffect(() => {
        
        handleGetData({month_range: 0});
    
      return () => {}
    }, [])

    const handleGetData = (filters = null) => {

        const response = get_user_cashflow(filters);
        response.then(res => {
            console.log("res", res)
            setCashflow(res.data.records);
        })

    }

    return (
        <WrapperStyled>
            <div className='content'>

                {cashflow === null ?
                    <div className='loader'>
                        <CircularProgress size={50}/>
                    </div>
                : 
                <>

                <div className='top'>
                    <div className='months'>
                        {/* <div className={`actual_month${monthSelected === 0 ? ' selected' : ''}`} onClick={() => handleChangeMonth(0)}>ACTUAL MONTH</div> */}
                        <div className={`month${monthSelected === 0 ? ' selected' : ''}`} onClick={() => handleChangeMonth(0)}>1M</div>
                        <div className={`month${monthSelected === 6 ? ' selected' : ''}`} onClick={() => handleChangeMonth(6)}>6M</div>
                        <div className={`month${monthSelected === 12 ? ' selected' : ''}`} onClick={() => handleChangeMonth(12)}>12M</div>
                    </div>
                </div>
                <div className='bottom'>
                    
                    <div className='table'>
                        {cashflow !== null ?
                        <Scrollbar className='scrollbar' permanentTrackY={true} disableTracksMousewheelScrolling={false}>
                            <div className='head'>
                                <div className='column date'>Date</div>
                                <div className='column description'>Concept</div>
                                <div className='column value'>Value</div>
                            </div>
                            {!cashflow.length ? <div className='nodata'>No data to show</div> : ""}
                            <>
                            {cashflow.length ?
                            <div className='rows'>
                                {cashflow.map((element, index) => {
                                        return(
                                            <div className={`row${index % 2 ? " gray" : ""}`}>
                                                <div className='date'>{ element.date }</div>
                                                <div className='description'>{ element.concept }</div>
                                                <div className='value'>{ number_format(element.value) }</div>
                                            </div>
                                            )
                                        })}
                            </div> : ""}
                            </>
                        </Scrollbar>
                        : ""}
                    </div>
                    
                </div>
                </> }
            </div>
        </WrapperStyled>
    );
};

export default Portfolio;