import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { colors } from '../../helpers/commons';

export const WrapperStyled = styled.div`
    .category{
        display: flex;
        flex-direction: column;
        >li{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 2px;
            padding: 7px 0;
            cursor: pointer;
            .inner{
                display: flex;
                align-items: center;
                flex-direction: row;
            }
            .subcategory{
                display: flex;
                flex-direction: column;
                width: calc()(100% - 30px);
                padding-left: 30px;
                margin-top: 5px;
                
            }
            
            &>:hover{
                color: rgba(${colors.brand}, 1)!important;
                .button_label{
                    color: rgba(${colors.brand}, 1)!important;
                }
            }
        }
    }

    .icon{
        display: flex;
        color: rgba(${colors.white}, 1)!important;
    }

    @media ${breakpoints.xs} {//576px
    }
    @media ${breakpoints.sm} {//768px
    }
    @media ${breakpoints.md} {//992px
    }
    @media ${breakpoints.lg} {//1200px // Desktop
    }
    @media ${breakpoints.xl} {//1366px
    }
    @media ${breakpoints.xxl} {//1440px
    }
    @media ${breakpoints.xxxl} {//1680px
    }
    @media ${breakpoints.xxxxl} {//1920px
    }

`
export const CategoryContainer = styled.div`

    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(${colors.white}, 0.1);

    font-size: 25px;
    line-height: 25px;

    .button_label{
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        margin-left: 10px;
        color: rgba(${colors.white}, 1);
        white-space: nowrap;
    }
    .open{
        .button_label{
            display: block;
            color: rgba(${colors.white}, 1);
        }
    }
    .hide{
        .button_label{
            display: none;
        }
    }

`;

export const CategoryLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: black;
    
    

    &:hover {
        .icon{
            display: flex;
            color: rgba(${colors.red}, 1)!important;
        }
        .button_label{
            color: rgba(${colors.red}, 1)!important;
        }
    }
    &.selected{
        .icon{
            display: flex;
            color: rgba(${colors.red}, 1)!important;
            &.custom{
                display: none;
                fill: white!important;
            }
        }
        .button_label{
            color: rgba(${colors.red}, 1)!important;
        }
    }

    

    @media ${breakpoints.xs} {//576px
    }
    @media ${breakpoints.sm} {//768px
    }
    @media ${breakpoints.md} {//992px
    }
    @media ${breakpoints.lg} {//1200px // Desktop
    }
    @media ${breakpoints.xl} {//1366px
    }
    @media ${breakpoints.xxl} {//1440px
    }
    @media ${breakpoints.xxxl} {//1680px
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`;

export const SubcategoryList = styled.ul`
    list-style: none;
    padding-left: 6px;
    margin-top: 10px;

    &.open{
        padding-left: 20px;
        transition: all .15s ease-in-out;
        .button_label{
            display: block;
        }
    }
    &.hide{
        padding-left: 0;
        transition: all .15s ease-in-out;
        .button_label{
            display: none;
        }
    }
    .icon{
        display: flex;
        svg{
            display: flex;
            &.custom{
                width: 20px;
            }
        }
    }

    @media ${breakpoints.xs} {//576px
    }
    @media ${breakpoints.sm} {//768px
    }
    @media ${breakpoints.md} {//992px
    }
    @media ${breakpoints.lg} {//1200px // Desktop
        //padding-left: 5px;
        /* transition-timing-function: cubic-bezier(.4,0,.2,1);
        transition-duration: .2s;
        transition-property: all; */
    }
    @media ${breakpoints.xl} {//1366px
    }
    @media ${breakpoints.xxl} {//1440px
    }
    @media ${breakpoints.xxxl} {//1680px
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`;

export const SubcategoryItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 7px;
    min-height: 27px;
    white-space: nowrap;
    .icon{
        display: flex;
        color: rgba(${colors.white}, 1)!important;
        &.custom{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            svg{
                display: flex;
                width: 22px;
                height: 22px;
                fill: rgba(${colors.white}, 1)!important;
            }
        }
    }

    &:hover {
        .icon{
            display: flex;
            color: rgba(${colors.red}, 1)!important;
            &.custom{
                svg{
                    display: flex;
                    fill: rgba(${colors.red}, 1)!important;
                }
            }
        }
        .button_label{
            color: rgba(${colors.red}, 1)!important;
        }
    }
    &.selected{
        .icon{
            display: flex;
            color: rgba(${colors.red}, 1)!important;
            &.custom{
                svg{
                    display: flex;
                    fill: rgba(${colors.red}, 1)!important;
                }
            }
        }
        .button_label{
            color: rgba(${colors.red}, 1)!important;
        }
    }
`;

