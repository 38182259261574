import React, { useState, useContext, useEffect } from 'react';
import { path } from 'ramda';
import { WrapperStyled, LoaderStyled } from './styles';
import { FormDebitCard } from '../../../components/Organisms/FormDebitCard';
import { TableData } from '../../../components/Organisms/TableData';
import { ContentContext } from '../../../context';
import { CircularProgress } from '../../../components/Atoms/CircularProgress';

const DebitCard = () => {

    const { getDebitCard, setDebitCard, debitCard } = useContext(ContentContext);

    const [ actionForm, setActionForm ] = useState("");
    const [ optionSelected, setOptionSelected ] = useState("");
    const [ dataToEdit, setDataToEdit ] = useState(null);
    const [ filtersData, setFiltersData ] = useState(null);

    useEffect(() => {

        const response = getDebitCard();
        response.then(res => {
            let records = path(['data', 'records'], res);
            let filters = path(['data', 'filters'], res);
            setDebitCard(records);
            setFiltersData(filters);
        })

        return () => {
            setDebitCard(null);
        }
    }, [])

    // if(!debitCard){
    //     return (
    //         <LoaderStyled>
    //             <CircularProgress 
    //                 type={'primary'} 
    //                 size={'medium'}
    //             />
    //         </LoaderStyled>
    //     );
    // }

    return (
        <WrapperStyled>
            <div className='title'>Debit Card</div>
            <FormDebitCard 
                dataToEdit={dataToEdit}
                setDataToEdit={setDataToEdit}
                actionForm={actionForm}
                setActionForm={setActionForm}
                filtersData={filtersData}
                setFiltersData={setFiltersData}
            />
            <TableData 
                actionForm={actionForm}
                dataToEdit={dataToEdit}
                setDataToEdit={setDataToEdit}
                filtersData={filtersData}
                setFiltersData={setFiltersData}
            />
        </WrapperStyled>
    );
};

export default DebitCard;