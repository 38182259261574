import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { colors } from '../../helpers/commons';

export const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //width: 100vw;
    width: 100%;
    min-height: 650px;
    height: 500px;
    
    background-color: white;
    background-image: url("../../images/background_login.jpg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    
    input{
        width: 100%;
        border-radius: 6px;
        border: 1px solid #c9c9c9;
        background-color: #f6f6f6;
        font-size: 16px;
        line-height: 16px;
        padding: 13px 15px;
        outline: 0;
        &::placeholder{
            color: #a8a8a8;
        }
        &.error{
            border: 1px solid rgba(${colors.red}, 1);
        }
    }

    .image_login_mobile{
        display: flex;
        width: 75vw;
        padding-top: 10vw;
        svg{
            float: left;
            width: 100%;
        }
    }
    .image_login{
        display: none;
    }

    .box_login{
        display: flex;
        flex-direction: column;
        width: calc(100% - 30px);
        //height: 450px;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        .title{
            color: rgba(${colors.darkBlue}, 1);
            font-size: 20px;
            line-height: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .left{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 100%;
            background-color: rgba(${colors.darkBlue}, 1);
            .image_login{
                width: 393px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
        }
        .right{
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            padding: 40px 40px;
            background-color: rgba(${colors.white}, 1);
            .form{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .user{
                    margin-bottom: 10px;
                }
                .send_login{
                    margin-top: 10px;
                }
                .loader{
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    height: 40px;
                    svg{
                        color: rgba(${colors.darkBlue}, 1);
                    }
                }
                
                
            }
            .error_change_password{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                background-color: rgba(255,255,255,0.6);
                .inner_error_change_password{
                    display: flex;
                    flex-direction: column;
                    white-space: pre-line;
                    background-color: #FFFFFF;
                    width: calc(100% - 60px);
                    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
                    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
                    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
                    border-radius: 8px;
                    box-sizing: border-box;
                    padding: 10px 10px;
                    font-size: 14px;
                    line-height: 21px;
                    .close_error_change_password{
                        display: flex;
                        align-self: flex-end;
                        font-size: 12px!important;
                        line-height: 12px!important;
                        height: 30px!important;
                        padding: 0 15px!important;
                        margin-top: 10px;
                    }
                }
            }
        }

        
            

        .inner_change_password{
            .inner_pass_changed{
                display: flex;
                flex-direction: column;
                align-items: center;
                .pass_changed_back_message{
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
            .repeat_password{
                margin-top: 10px!important;
            }
            .send_change_password{
                margin-top: 10px!important;
            }
            .pass_aclaration{
                font-size: 13px;
                line-height: 17px;
                margin-top: 8px;
            }
        }
    }

    @media ${breakpoints.xs} {//576px
        .box_login{
            width: 450px;
        }
        .image_login_mobile{
            width: 393px;
            padding-top: 60px;
        }
    }
    @media ${breakpoints.sm} {//768px
    }
    @media ${breakpoints.md} {//992px

        height: calc(100vh - 400px)!important;

        .image_login_mobile{
            display: none;
        }
        .image_login{
            display: flex;
        }

        .box_login{
            display: flex;
            flex-direction: row;
            width: 900px;
            height: 450px;
            .title{
                font-size: 20px;
                line-height: 20px;
                font-weight: 500;
                margin-bottom: 20px;
            }
            .left{
                display: flex;
                justify-content: center;
                align-items: flex-end;
                width: 500px;
                .image_login{
                    width: 393px;
                    svg{
                        float: left;
                        width: 100%;
                    }
                }
            }
            .right{
                display: flex;
                flex-direction: column;
                width: 400px;
                justify-content: center;
                padding: 0 40px;
                background-color: rgba(${colors.white}, 1);
                .form{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .user{
                        margin-bottom: 10px;
                    }
                    .send_login{
                        margin-top: 10px;
                    }
                    .loader{
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        height: 40px;
                        svg{
                            color: rgba(${colors.darkBlue}, 1);
                        }
                    }
                }
            }
        }
    }
    @media ${breakpoints.lg} {//1200px // Desktop
    }
    @media ${breakpoints.xl} {//1366px
    }
    @media ${breakpoints.xxl} {//1440px
    }
    @media ${breakpoints.xxxl} {//1680px
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`


export const LoaderStyled = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25px;
`