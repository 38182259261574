import React, { Fragment, useEffect, useState, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { Snackbar } from '../../Atoms/Snackbar';

import { ContainerGrid } from '../../Atoms/Container/ContainerGrid';
import { FormStructure } from '../FormStructure';
import { ContentContext } from '../../../context/ContentContext';

const FormStyled = styled.div`
    >.container{
        position: relative;
        overflow: hidden;
        >.inner{
            >.field_complex{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }
`

const FormProduct = ({actionType, 
                      dataToEdit, 
                      setDataToEdit, 
                      actionForm, 
                      setActionForm,
                      monthSelected,
                      filtersData, 
                      setFiltersData }) => {
    
    const { portfolio,
            setPortfolio,
            editPortfolio,
            createPortfolio,
            clients } = useContext(ContentContext)

    const [ formData, setFormData ] = useState({});
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [ snackbarData, setSnackbarData ] = useState({text: '', severity: ''});
    const [ isLoading, setIsLoading ] = useState(false);
    
    useEffect(() => {
        //if(filtersData){
            
        
            const data = {
                client: {
                    isSlave: false,
                    masterOf: null,
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: '',
                        icon: { component: null },
                        size: 'medium',
                        type: 'primary',
                        options: clients,
                    },
                    label: {
                        text: 'Client',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.user_id ? dataToEdit.user_id : '',
                    mandatory: true,
                    type: 'select:common',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    innerElement: null,
                    dependendComponent: null,
                    errorMessage: null,
                    disableElements: null,
                },
                product: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a product name...',
                    },
                    label: {
                        text: 'Product',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.product ? dataToEdit.product : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                date: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a date...',
                    },
                    label: {
                        text: 'Date',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.date ? dayjs(dataToEdit.date).format("DD/MM/YYYY") : '',
                    mandatory: true,
                    type: 'input:masked',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                quantity: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a quantity...',
                    },
                    label: {
                        text: 'Quantity',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.quantity ? dataToEdit.quantity : '',
                    mandatory: false,
                    type: 'input:currency',
                    prefix: '',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                usd_value: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a USD Value...',
                    },
                    label: {
                        text: 'USD Value',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.usd_value ? dataToEdit.usd_value : '',
                    mandatory: false,
                    type: 'input:currency',
                    prefix: '',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                isin: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a isin...',
                    },
                    label: {
                        text: 'ISIN',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.isin ? dataToEdit.isin : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: false,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
                description: {
                    standalone: false,
                    field: {
                        icon: { component: null, size: '' },
                        placeholder: 'Enter a description...',
                    },
                    label: {
                        text: 'Description',
                        type: 'form',
                        reverse: false,
                        icon: { component: null }
                    },
                    value: dataToEdit?.description ? dataToEdit.description : '',
                    mandatory: true,
                    type: 'input:text',
                    error: false,
                    onChange: handleOnChange,
                    fullwidth: true,
                    disabled: false,
                    //enabledBy: null,
                    errorMessage: null,
                },
            }

            setFormData(data);
        //}
    
      return () => {
        setFormData({});
      }
    }, [dataToEdit, clients])

    const handleCancel = () => {
        resetForm();
    }

    const handleOnChange = (e, name) => {
        
        const update = {...formData}



        if(name !== 'quantity' && name !== 'usd_value'){
            update[name].value = e.target.value;
            if(e.target.value.length){
                update[name].error = false;
            }
        }else{
            update[name].value = e;
            if(e?.length){
                update[name].error = false;
            }
        }

        setFormData(update)

    }

    const handleSendForm = () => {
        
        setIsLoading(true);
        let haveError = false;

        let formDataValidate = {...formData};
        Object.keys(formDataValidate).map((field) => {
            
            if(formDataValidate[field].mandatory && field !== 'empty' && !formDataValidate[field].disabled){
                
                if(!formDataValidate[field].value.toString().length){
                    haveError = true;
                    formDataValidate[field].error =  true;
                }else{
                    formDataValidate[field].error =  false;
                }
            }
            
        })
        setFormData(formDataValidate);

       

        if(!haveError){
            
            let update_json = [];
            let data = '';
            let id = uuid();

            let formDataToSend = Object.keys(formData).map((field) => {
                return {field: field, value: formData[field].value};
            })

            if(dataToEdit){
                
                data = {"id": dataToEdit.id,
                        "user_id": formData["client"].value, 
                        "product": formData["product"].value, 
                        "date": formData["date"].value, 
                        "description": formData["description"].value, 
                        "quantity": formData["quantity"].value, 
                        "usd_value": formData["usd_value"].value, 
                        "isin": formData["isin"].value, 
                        };
                
                const update_json_ = [...portfolio];
                update_json = update_json_.map((el, index) => {
                    
                    if(el.id === dataToEdit.id){
                        return data;
                    }else{
                        return el;
                    }
                })
                

            }else{

                data = {"uuid": id,
                        "user_id": formData["client"].value, 
                        "product": formData["product"].value, 
                        "date": formData["date"].value, 
                        "description": formData["description"].value, 
                        "quantity": formData["quantity"].value, 
                        "usd_value": formData["usd_value"].value, 
                        "isin": formData["isin"].value, 
                        };

                update_json = [...portfolio];
                update_json.push(data);

            }
            
            const payload = data;
            let result;
            
            
            if(dataToEdit){
                result = editPortfolio(payload);
            }else{
                result = createPortfolio(payload);
            }
            
            
            result.then(res => {
                
                setSnackbarData({text: dataToEdit ? 'The data was successfully updated.' : 'The data was successfully saved.', severity: 'success'});
                setSnackbarOpen(true)
                setIsLoading(false);

                let data_product = {user_id: res.data.record.user_id,
                                    created_at: res.data.record.created_at,
                                    deleted_at: res.data.record.deleted_at,
                                    date: res.data.record.date,
                                    description: res.data.record.description,
                                    id: res.data.record.id,
                                    isin: res.data.record.isin,
                                    product: res.data.record.product,
                                    quantity: res.data.record.quantity,
                                    usd_value: res.data.record.usd_value,
                                    updated_at: res.data.record.updated_at};
                                    
                if(dataToEdit){
                    const portfolioUpdated = portfolio.map(record => {
                        if(record.id === res.data.record.id){
                            return data_product;//res.data.record;
                        }else{
                            return record;
                        }
                    })
                    setPortfolio(portfolioUpdated);
                }else{
                    let portfolioUpdated = [...portfolio];
                    portfolioUpdated.push(data_product);
                    setPortfolio(portfolioUpdated);
                }

                if(dataToEdit){
                    resetForm();
                }
            })

        }else{
            setIsLoading(false);
            return null;
        }
        
    }

    const resetForm = () => {

        let formReset = {...formData};
        Object.keys(formReset).map((field) => {
            formReset[field].value = "";
            formReset[field].error = false;
        })
        setFormData(formReset);

    }

    useEffect(() => {
        //console.log("clients", clients)
    
      return () => {}
    }, [clients])
    
    useEffect(() => {
      return () => {
        setDataToEdit(null);
        setActionForm('');
        resetForm();
      }
    }, [])
    
    if(!clients){
        return null;
    }

    return (
        <FormStyled>
            <Snackbar 
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                snackbarData={snackbarData}
                setSnackbarData={setSnackbarData}
                hide={2500}
            />
            <ContainerGrid
                className={`container`}
                margins={{top: 4}}
            > 
                <FormStructure 
                    formData={formData}
                    setFormData={setFormData}
                    handleOnChange={handleOnChange}
                    handleSendForm={handleSendForm}
                    handleCancel={handleCancel}
                    dataToEdit={dataToEdit}
                    isloading={isLoading}
                    payButton={false}
                />
            </ContainerGrid>
        </FormStyled>
    );
};

FormProduct.propTypes = {
    formData: PropTypes.object,
    setFormData: PropTypes.func,
    handleOnChange: PropTypes.func,
    //visible: PropTypes.bool,
    actionType: PropTypes.string,
    handleCancel: PropTypes.func,
    dataToEdit: PropTypes.object,
    setDataToEdit: PropTypes.func,
    actionForm: PropTypes.string,
    setActionForm: PropTypes.func,
    monthSelected: PropTypes.string,
};

export default FormProduct;