import React from 'react';
import PropTypes from 'prop-types';

const ModalFooter = ({children}) => {
    return (
        <div>
            {children}
        </div>
    );
};

ModalFooter.propTypes = {
    
};

export default ModalFooter;