import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 20px 20px;
    border: 1px solid #CADAFF;
    border-radius: 6px;

    margin-top: ${props => props.margins.top ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => props.margins.bottom ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => props.margins.left ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => props.margins.right ? 5 * props.margins.right + "px" : 0}!important;
    width: 800px;
`

export const ContainerModal = ({ children, margins = {top: 0, bottom: 0, left: 0, right: 0} }) => {
    return (
        <Container
            margins={margins}
            className={``}
        >
            {children} 
        </Container>
    );
};

ContainerModal.propTypes = {
    margins: PropTypes.shape({
        top: PropTypes.oneOf([1, 2, 3, 4, 5]),
        bottom: PropTypes.oneOf([1, 2, 3, 4, 5]),
        left: PropTypes.oneOf([1, 2, 3, 4, 5]),
        right: PropTypes.oneOf([1, 2, 3, 4, 5]),
    }),
    position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};
