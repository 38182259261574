import React, { useState, useContext, useEffect } from 'react';
import { path } from 'ramda';
import { WrapperStyled, LoaderStyled } from './styles';
import { FormUsers } from '../../../components/Organisms/FormUsers';
import { TableData } from '../../../components/Organisms/TableData';
import { ContentContext } from '../../../context';
import { CircularProgress } from '../../../components/Atoms/CircularProgress';

const Users = () => {

    const { users, getUsers, setUsers } = useContext(ContentContext);

    const [ actionForm, setActionForm ] = useState("");
    const [ optionSelected, setOptionSelected ] = useState("");
    const [ dataToEdit, setDataToEdit ] = useState(null);
    const [ filtersData, setFiltersData ] = useState(null);
    /*
    useEffect(() => {

        const response = getUsers();
        response.then(res => {
            let records = path(['data', 'records'], res);
            let filters = path(['data', 'filters'], res);
            setUsers(records);
            setFiltersData(filters);
        })

        return () => {
            setUsers(null);
        }
    }, [])

    if(!users){
        return (
            <LoaderStyled>
                <CircularProgress 
                    type={'primary'} 
                    size={'medium'}
                />
            </LoaderStyled>
        );
    }
    */

    return (
        <WrapperStyled>
            <div className='title'>Users</div>
            <FormUsers
                dataToEdit={dataToEdit}
                setDataToEdit={setDataToEdit}
                actionForm={actionForm}
                setActionForm={setActionForm}
                //filtersData={filtersData}
                //setFiltersData={setFiltersData}
            />
            <TableData 
                actionForm={actionForm}
                dataToEdit={dataToEdit}
                setDataToEdit={setDataToEdit}
                margins={{top: 3}}
                //filtersData={filtersData}
                //setFiltersData={setFiltersData}
            />
        </WrapperStyled>
    );
};

export default Users;