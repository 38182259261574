import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../helpers/commons';

const ButtonStyled = styled.button`
  
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    
    /* :hover{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
        .icon{
            color: ${props => `rgba(${props.colors.primary}, 1)` }!important;
        }
    } */
    .icon{
        display: flex!important;
    }
    &.fullwidth{
        width: 100%;
        justify-content: center;
    }
    &.small .label{
        /* padding: 10px 30px; */
        font-size: 12px;
        line-height: 11px;
        border-radius: 5px;
    }
    &.small .icon{
        display: flex;
        font-size: 11px;
        line-height: 11px;
        border-radius: 6px;
    }
    &.medium .label{
        /* padding: 15px 45px; */
        font-size: 16px;
        line-height: 15px;
    }
    &.medium .icon{
        font-size: 13px;
        line-height: 10px;
    }
    &.large .label{
        /* padding: 15px 50px; */
        font-size: 25px;
        line-height: 24px;
    }
    &.large .icon{
        font-size: 14px;
        line-height: 14px;
    }
    .icon{
        display: flex;
        font-size: 14px;
        line-height: 14px;
        margin-right: 7px;
        svg{
            display: flex!important;
        }
    }
    &.reverse{
        flex-direction: row-reverse;
    }
    &.reverse .icon{
        margin-right: 0;
        margin-left: 7px;
    }

    &.default{
        color: ${props => `rgba(${props.colors.default}, 1)`};
    }
    &.default:hover{
        color: ${props => `rgba(${props.colors.primary}, 1)`};
        .icon{
            color: ${props => `rgba(${props.colors.primary}, 1)` }!important;
        }
    }
    
    &.important{
        color: ${props => `rgba(${props.colors.important}, 1)`};
    }
    &.important:hover{
        color: ${props => `rgba(${props.colors.primary}, 1)`};
        .icon{
            color: ${props => `rgba(${props.colors.primary}, 1)` }!important;
        }
    }

    &.cancel{
        color: ${props => `rgba(${props.colors.cancel}, 1)`};
    }
    &.cancel:hover{
        color: ${props => `rgba(${props.colors.primary}, 1)`};
        .icon{
            color: ${props => `rgba(${props.colors.primary}, 1)` }!important;
        }
    }

    &.primary{
        color: ${props => `rgba(${props.colors.primary}, 1)`}!important;
    }
    &.primary:hover{
        color: ${props => `rgba(${props.colors.secondary}, 1)`}!important;
        .icon{
            color: ${props => `rgba(${props.colors.secondary}, 1)` }!important;
        }
    }

    &.accept{
        color: ${props => `rgba(${props.colors.accept}, 1)`};
    }
    &.accept:hover{
        color: ${props => `rgba(${props.colors.primary}, 1)`};
        .icon{
            color: ${props => `rgba(${props.colors.primary}, 1)` }!important;
        }
    }

    margin-top: ${props => props.margins.top ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => props.margins.bottom ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => props.margins.left ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => props.margins.right ? 5 * props.margins.right + "px" : 0}!important;

`
export const ButtonText = ({ type, label, size, icon, reverse, fullwidth, invert, className, _key, margins = {top: 0, bottom: 0, left: 0, right: 0}, ...props }) => {
    //const mode = primary ? 'storybook-button--primary' : 'storybook-button--secondary';
    const _reverse = reverse ? 'reverse' : '';
    const _fullwidth = fullwidth ? 'fullwidth' : '';
    const _invert = invert ? 'invert' : '';

    return (
      <ButtonStyled
        key={_key}
        colors={colors}
        type="button"
        className={`${className} ${type} ${size} ${_reverse} ${_fullwidth} ${_invert}`}
        //style={backgroundColor && { backgroundColor }}
        margins={margins}
        {...props}
      >
        {icon ? <div className='icon'>{icon}</div> : "" }
        {label ? <div className='label'>{label}</div> : ""}
      </ButtonStyled>
    );
  };

  ButtonText.propTypes = {
    _key: PropTypes.string,
    /**
     * Is this the principal call to action on the page?
     */
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    /**
     * What background color to use
     */
    //backgroundColor: PropTypes.string,
    /**
     * How large should the button be?
     */
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    /**
     * Button contents
     */
    label: PropTypes.string.isRequired,
    /**
     * Optional click handler
     */
    onClick: PropTypes.func,
    icon: PropTypes.element,
    reverse: PropTypes.bool,
    fullwidth: PropTypes.bool,
    invert: PropTypes.bool,
    margins: PropTypes.shape({
        top: PropTypes.oneOf([1, 2, 3, 4, 5]),
        bottom: PropTypes.oneOf([1, 2, 3, 4, 5]),
        left: PropTypes.oneOf([1, 2, 3, 4, 5]),
        right: PropTypes.oneOf([1, 2, 3, 4, 5]),
    }),
  };
  
//   Button.defaultProps = {
//     //backgroundColor: null,
//     reverse: false,
//     invert: false,
//     type: 'default',
//     size: 'medium',
//     icon: null,
//     onClick: undefined,
//     fullwidth: false
//   };