import React from 'react';
import { WrapperStyled } from './styles';

const Footer = () => {
    return (
        <WrapperStyled>
            <div className='content'>
                <div className='container'>
                    <div className='inner'>
                        <div className='top'>
                            <div className='logo'>
                                <img src='/images/logo_footer.svg' />
                            </div>
                            <div className='columns'>
                                <div className='column funds'>
                                    <div className='title'>
                                        <div className='icon'>
                                            <img src='/images/icon_funds.svg' />
                                        </div>
                                        <div className='text'>Funds</div>
                                    </div>
                                    <div className='links'>
                                        <a href='' className='link'>Alliance</a>
                                        <a href='' className='link'>BlackRock</a>
                                        <a href='' className='link'>MFS</a>
                                        <a href='' className='link'>Templeton</a>
                                    </div>
                                </div>
                                <div className='column markets'>
                                    <div className='title'>
                                        <div className='icon'>
                                            <img src='/images/icon_markets.svg' />
                                        </div>
                                        <div className='text'>Markets</div>
                                    </div>
                                    <div className='links'>
                                        <a href='' className='link'>New York</a>
                                        <a href='' className='link'>Frankfurt</a>
                                        <a href='' className='link'>Hong Kong</a>
                                        <a href='' className='link'>Switzerland</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bottom'>
                            <div className='all_rights'>
                                ® 2024 - ALL RIGHTS RESERVED
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </WrapperStyled>
    );
};

export default Footer;