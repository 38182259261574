import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../helpers/commons';

const FormErrorLabelStyled = styled.div`

    width: calc(100% - 16px);
    font-size: 14px;
    color: ${props => `rgba(${props.colors.error}, 1)` };
    margin-top: 5px;
    margin-left: 8px;
    font-weight: 400;
    /* background-color: pink; */

    &.xsmall{
        font-size: 10px;
    }
    &.small{
        font-size: 12px;
    }
    &.medium{
        font-size: 15px;
    }
    &.large{
        font-size: 18px;
    }

`

const FormErrorLabel = ({ label, type, size }) => {
    return (
        <FormErrorLabelStyled
            colors={colors}
            className={`${type} ${size}`}
        >
            { label }
        </FormErrorLabelStyled>
    );
};

FormErrorLabel.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
};

export default FormErrorLabel;