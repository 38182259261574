import styled from 'styled-components';
import { breakpoints } from '../../../helpers/breakpoints';
import { colors } from '../../../helpers/commons';

export const WrapperStyled = styled.div`

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    

    margin-top: ${props => props.margins.top ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => props.margins.bottom ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => props.margins.left ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => props.margins.right ? 5 * props.margins.right + "px" : 0}!important;

    .bulk_pays{
        .bulk_pay{
            margin-bottom: 5px;
        }
    }
    .block{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(243, 246, 255, 0.75);
        z-index: 99;
    }
    .no_result{
        text-align: center;
    }
    .bt_add_expense{
        
    }
    .table_box{
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            width: 0; /* Ancho de la barra de desplazamiento horizontal */
        }

        /* Estilos personalizados para el deslizador de la barra de desplazamiento en WebKit */
        &::-webkit-scrollbar-thumb {
            background-color: transparent; /* Color transparente para el deslizador */
        }
        .inner{
            //width: 100%;
            width: 1135px;
            .table_header,
            .table_row{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;//space-between;
                align-items: center;
            }
            .field_name{
                margin-left: 0;
            }

            >.table_body .table_row{
                padding: 15px 20px;
                border-bottom: 1px solid ${props => `rgba(${props.colors.secondary}, 1)` };
            }
            >.table_body .table_row:last-child{
                border-bottom: 0;
            }

            >.table_header .column,
            >.table_body .table_row .column{
                margin-right: 15px;
            }
            >.table_header .column.check,
            >.table_body .table_row .column.check{
                width: 26px;
            }
            >.table_body .table_row .column.actions{
                display: flex;
                flex-direction: row;
            }
            >.table_header .column.description,
            >.table_body .table_row .column.description{
                width: 240px;
                //background-color: pink;
            }
            >.table_header .column.quotes,
            >.table_body .table_row .column.quotes{
                width: 60px;
                //background-color: skyblue;
            }
            >.table_header .column.expense_type,
            >.table_body .table_row .column.expense_type{
                width: 160px;
                //background-color: skyblue;
            }
            >.table_header .column.amount_owed,
            >.table_body .table_row .column.amount_owed{
                width: 115px;
                //background-color: pink;
            }
            >.table_header .column.outstanding_balance,
            >.table_body .table_row .column.outstanding_balance{
                width: 115px;
                //background-color: skyblue;
            }
            >.table_header .column.debt_status,
            >.table_body .table_row .column.debt_status{
                width: 60px;
                //background-color: pink;
            }
            >.table_body .table_row .column.debt_status{
                display: flex;
                align-items: flex-start;
            }
            >.table_header .column.actions,
            >.table_body .table_row .column.actions{
                width: 112px;
                margin-right: 0;
                //background-color: skyblue;
            }
            >.table_header .column.outstanding_balance,
            >.table_body .table_row .column.outstanding_balance{
                //background-color: skyblue;
            }
            >.table_header .column.created_at,
            >.table_body .table_row .column.created_at{
                width: 90px;
                //background-color: pink;
            }
        }
    }
    .month_select{
        width: 100%;
        align-self: flex-end;
        padding-right: 35px!important;
    }

    @media ${breakpoints.xs} {//576px
        .table_box{
            width: 100%;
            padding-right: 35px!important;
            .inner{
                width: 1135px;
                >.table_header .column.description,
                >.table_body .table_row .column.description{
                    width: 294px;
                    //background-color: pink;
                }
                >.table_header .column.quotes,
                >.table_body .table_row .column.quotes{
                    width: 50px;
                    //background-color: skyblue;
                }
                >.table_header .column.expense_type,
                >.table_body .table_row .column.expense_type{
                    width: 130px;
                    //background-color: skyblue;
                }
                >.table_header .column.amount_owed,
                >.table_body .table_row .column.amount_owed{
                    width: 120px;
                    //background-color: pink;
                }
                >.table_header .column.outstanding_balance,
                >.table_body .table_row .column.outstanding_balance{
                    width: 120px;
                    //background-color: skyblue;
                }
                >.table_header .column.debt_status,
                >.table_body .table_row .column.debt_status{
                    width: 60px;
                    //background-color: pink;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 95px;
                    //background-color: pink;
                }
                >.table_header .column.actions,
                >.table_body .table_row .column.actions{
                    width: 110px;
                    margin-right: 0;
                    //background-color: skyblue;
                }
            }
        }
        .month_select{
            width: 300px;
            padding-right: 35px!important;
        }
    }
    @media ${breakpoints.sm} {//768px
    }
    @media ${breakpoints.md} {//992px
        /* .table_box{
            width: 100%;
            padding-right: 35px!important;
            .inner{
                width: 1135px;
                >.table_header .column.description,
                >.table_body .table_row .column.description{
                    width: 324px;
                    //background-color: pink;
                }
                >.table_header .column.quotes,
                >.table_body .table_row .column.quotes{
                    width: 50px;
                    //background-color: skyblue;
                }
                >.table_header .column.expense_type,
                >.table_body .table_row .column.expense_type{
                    width: 100px;
                    //background-color: skyblue;
                }
                >.table_header .column.amount_owed,
                >.table_body .table_row .column.amount_owed{
                    width: 120px;
                    //background-color: pink;
                }
                >.table_header .column.outstanding_balance,
                >.table_body .table_row .column.outstanding_balance{
                    width: 120px;
                    //background-color: skyblue;
                }
                >.table_header .column.debt_status,
                >.table_body .table_row .column.debt_status{
                    width: 60px;
                    //background-color: pink;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 95px;
                    //background-color: pink;
                }
                >.table_header .column.actions,
                >.table_body .table_row .column.actions{
                    width: 110px;
                    margin-right: 0;
                    //background-color: skyblue;
                }
            }
        } */
    }
    @media ${breakpoints.lg} {//1200px Desktop
        .table_box{
            width: 100%;
            padding-right: 0!important;
            .inner{
                width: 100%;
                >.table_header .column.description,
                >.table_body .table_row .column.description{
                    width: 224px;
                    //background-color: pink;
                }
                >.table_header .column.quotes,
                >.table_body .table_row .column.quotes{
                    width: 50px;
                    //background-color: skyblue;
                }
                >.table_header .column.expense_type,
                >.table_body .table_row .column.expense_type{
                    width: 100px;
                    //background-color: skyblue;
                }
                >.table_header .column.amount_owed,
                >.table_body .table_row .column.amount_owed{
                    width: 120px;
                    //background-color: pink;
                }
                >.table_header .column.outstanding_balance,
                >.table_body .table_row .column.outstanding_balance{
                    width: 120px;
                    //background-color: skyblue;
                }
                >.table_header .column.debt_status,
                >.table_body .table_row .column.debt_status{
                    width: 60px;
                    //background-color: pink;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 95px;
                    //background-color: pink;
                }
                >.table_header .column.actions,
                >.table_body .table_row .column.actions{
                    width: 110px;
                    margin-right: 0;
                    //background-color: skyblue;
                }
            }
        }
        .month_select{
            padding-right: 0!important;
        }
    }
    @media ${breakpoints.xl} {//1366px
        .table_box{
            width: 100%;
            .inner{
                width: 100%;
                >.table_header .column.description,
                >.table_body .table_row .column.description{
                    width: 240px;
                    //background-color: pink;
                }
                >.table_header .column.expense_type,
                >.table_body .table_row .column.expense_type{
                    width: 170px;
                    //background-color: skyblue;
                }
                >.table_header .column.debt_status,
                >.table_body .table_row .column.debt_status{
                    width: 100px;
                    //background-color: pink;
                }
                >.table_header .column.amount_owed,
                >.table_body .table_row .column.amount_owed{
                    width: 140px;
                    //background-color: pink;
                }
                >.table_header .column.outstanding_balance,
                >.table_body .table_row .column.outstanding_balance{
                    width: 140px;
                    //background-color: skyblue;
                }
            }
        }
    }
    @media ${breakpoints.xxl} {//1440px
        .table_box{
            width: 100%;
            .inner{
                width: 100%;
                >.table_header .column.description,
                >.table_body .table_row .column.description{
                    width: 284px;
                    //background-color: pink;
                }
                >.table_header .column.expense_type,
                >.table_body .table_row .column.expense_type{
                    width: 200px;
                    //background-color: skyblue;
                }
            }
        }
    }
    @media ${breakpoints.xxxl} {//1680px
        .table_box{
            width: 100%;
            .inner{
                width: 100%;
                >.table_header .column.description,
                >.table_body .table_row .column.description{
                    width: 344px;
                    //background-color: pink;
                }
                >.table_header .column.quotes,
                >.table_body .table_row .column.quotes{
                    width: 70px;
                    //background-color: skyblue;
                }
                >.table_header .column.expense_type,
                >.table_body .table_row .column.expense_type{
                    width: 260px;
                    //background-color: skyblue;
                }
                >.table_header .column.amount_owed,
                >.table_body .table_row .column.amount_owed{
                    width: 150px;
                    //background-color: pink;
                }
                >.table_header .column.outstanding_balance,
                >.table_body .table_row .column.outstanding_balance{
                    width: 150px;
                    //background-color: skyblue;
                }
                >.table_header .column.debt_status,
                >.table_body .table_row .column.debt_status{
                    width: 130px;
                    //background-color: pink;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 145px;
                    //background-color: pink;
                }
            }
        }
    }
    @media ${breakpoints.xxxxl} {//1920px
        .table_box{
            width: 100%;
            .inner{
                width: 100%;
                >.table_header .column.description,
                >.table_body .table_row .column.description{
                    width: 524px;
                    //background-color: pink;
                }
                >.table_header .column.expense_type,
                >.table_body .table_row .column.expense_type{
                    width: 300px;
                    //background-color: skyblue;
                }
                >.table_header .column.amount_owed,
                >.table_body .table_row .column.amount_owed{
                    width: 170px;
                    //background-color: pink;
                }
                >.table_header .column.outstanding_balance,
                >.table_body .table_row .column.outstanding_balance{
                    width: 170px;
                    //background-color: skyblue;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 165px;
                    //background-color: pink;
                }
            }
        }
    }
`

export const LoaderStyled = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25px;
`

