import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../helpers/commons';
import { BiSolidDownArrow } from "react-icons/bi";


const SelectStyled = styled.div`
    width: 100%;
    position: relative;
    display: inline-block;

    select{
        width: 100%;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        /*
        background-color: transparent;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        */
        cursor: pointer;

        font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,Roboto,Arial,sans-serif!important;
        border-radius: 6px;

        background-color: #FBFCFF;
        color: #879AC6;
        font-size: 15px;
        outline: 0;
        border: 1px solid #CADAFF;
        padding-right: 35px!important;

        &:disabled{
            opacity: 0.5;
            cursor: default;
        }

    }

    &.small{
        select{
            padding: 10px 10px;
            font-size: 12px;
        }
    }
    &.medium{
        select{
            padding: 15px 15px;
            font-size: 15px;
        }
    }
    &.large{
        select{
            padding: 15px 15px;
            font-size: 18px;
        }
    }

    &.important{
        select,
        .arrow{
            color: ${props => `rgba(${props.colors.important}, 1)` };
        }
    }
    &.cancel{
        select,
        .arrow{
            color: ${props => `rgba(${props.colors.cancel}, 1)` };
        }
    }
    &.accept{
        select,
        .arrow{
            color: ${props => `rgba(${props.colors.accept}, 1)` };
        }
    }
    &.default{
        select,
        .arrow{
            color: ${props => `rgba(${props.colors.default}, 1)` };
        }
    }
    &.primary{
        select,
        .arrow{
            color: ${props => `rgba(${props.colors.primary}, 1)` };
        }
    }
    &.error{
        select,
        .arrow{
            border-color: ${props => `rgba(${props.colors.error}, 1)` };
        }
    }
    &::placeholder{
        select,
        .arrow{
            color: ${props => `rgba(${props.colors.primary}, 1)` };
        }
    }
    &::-ms-input-placeholder{
        select,
        .arrow{
            color: ${props => `rgba(${props.colors.primary}, 1)` };
        }
    }
    
    
    .arrow {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 14px;
        line-height: 14px;
        padding-top: 2px;
    }
`

export const Select = ({onChange, size, type, className, error, placeholder, name, options, selected, _key, disabled, ...props}) => {
    //console.log("options", options)
    return (
        <SelectStyled 
            key={`${_key}-select`}
            colors={colors}
            className={`${className} ${size} ${type} ${error ? 'error' : ''}`}
        >
            <select
                key={`${name}-${_key}`}
                name={`${name}`} 
                id={`${name}`} 
                onChange={(e) => onChange(e, name)}
                value={selected}
                disabled={disabled}
            >   
                <option key={`${_key}-nooptionselect`} value="" disabled={false} /*selected={option.selected}*/ hidden={false}>Select an option...</option>
                {options && options.map((option, index) => {
                    //console.log("option", option)
                    return(
                        <option key={option.value} value={`${option.value}`} disabled={option.disabled} /*selected={option.selected}*/ hidden={option.hidden}>{option.label}</option>
                    )
                })}
            </select>
            <div 
                key={`${name}-${_key}-arrow`}
                className="arrow"
            >
                <BiSolidDownArrow />
            </div>
        </SelectStyled>
    );
};

Select.propTypes = {
    _key: PropTypes.string,
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    name: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
    options: PropTypes.array,
    onChange: PropTypes.func,
    selected: PropTypes.string,
    disabled: PropTypes.bool,
};