import React, { useContext, useEffect, useState } from 'react';
import { WrapperStyled } from './styles';
import { AuthContext } from '../../../context';

const Dashboard = () => {

    const { user } = useContext(AuthContext);

    const [ actionForm, setActionForm ] = useState("");
    const [ optionSelected, setOptionSelected ] = useState("");
    const [ dataToEdit, setDataToEdit ] = useState(null);

    useEffect(() => {
      //console.log("Admin Dashboard",user,  !user.is_admin, user && !user.is_admin)
    
      return () => {}
    }, [user])
    
    if(user && !user.is_admin){
        return null;
    }

    return (
        <WrapperStyled>
            
        </WrapperStyled>
    );
};

export default Dashboard;