import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../helpers/commons';
import InputMask from 'react-input-mask';

const InputMaskedStyled = styled(InputMask)`
    width: 100%;
    font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,Roboto,Arial,sans-serif!important;
    border-radius: 6px;
    padding: 15px 15px;
    background-color: #FBFCFF;
    color: #879AC6;
    font-size: 15px;
    outline: 0;
    border: 1px solid #CADAFF;
    &.small{
        padding: 10px 10px;
        font-size: 12px;
    }
    &.medium{
        padding: 15px 15px;
        font-size: 15px;
    }
    &.large{
        padding: 15px 15px;
        font-size: 18px;
    }
    &.important{
        color: ${props => `rgba(${props.colors.important}, 1)` };
    }
    &.cancel{
        color: ${props => `rgba(${props.colors.cancel}, 1)` };
    }
    &.accept{
        color: ${props => `rgba(${props.colors.accept}, 1)` };
    }
    &.default{
        color: ${props => `rgba(${props.colors.default}, 1)` };
    }
    &.primary{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &.error{
        border-color: ${props => `rgba(${props.colors.error}, 1)` };
    }
    &::placeholder{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &::-ms-input-placeholder{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &:disabled{
        opacity: 0.5;
    }
`

export const InputMasked = ({ onChange,
                              className, 
                              error,
                              type,
                              size, 
                              name, 
                              placeholder, 
                              value,
                              disabled,
                              uuid,
                              ...props }) => {
                                
    return (

        <InputMaskedStyled
            key={uuid}
            mask="99/99/9999"
            colors={colors}
            name={name}
            placeholder={placeholder}
            className={`${className} input-${name} ${type} ${size} ${error ? 'error' : ''}`}
            value={value}
            onChange={(e) => onChange(e, name)}
            disabled={disabled}
            {...props}
        />

    );
};

InputMasked.propTypes = {
    uuid: PropTypes.string,
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string,//.isRequired,
    //setValue: PropTypes.func,//.isRequired,
};

InputMasked.defaultProps = {
    type: 'primary',
    size: 'medium',
    placeholder: '',
    name: 'input_masked',
    className: "input_masked",
    error: false,
    disabled: false,
};