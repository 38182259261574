import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from '../../Atoms/Input/Input';
import { Label } from '../../Atoms/Label/Label';
import { Icon } from '../../Atoms/Icon/Icon';

// import './field.css';

const FieldStyled = styled.div`
    display: flex;
    flex-direction: column;
    grid-column: ${props => props.fullwidth ? 'span 2' : ''};
    .containet_label{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: ${props => (props.input_reverse || !props.input_icon) ? '0' : '37px'};
        margin-bottom: 3px;
        .icon{
            display: flex;
            padding-right: 2px;
            svg{
                display: flex;
            }
        }
        &.reverse{
            .icon{
                margin-right: 0;
                margin-left: 0;
            }
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
    .containet_input{
        display: flex;
        flex-direction: row;
        align-items: center;
        .input{
            width: calc(100% - 40px);
        }
        .icon{
            display: flex;
            justify-content: center;
            width: 40px;
            padding-right: 2px;
            /* background-color: pink; */
        }
        &.no-icon{
            .input{
                width: 100%!important;
            }
        }
        &.reverse{
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
`

const FieldInput = ({ value, fullwidth, className, onChange, type, label, input, placeholder, icon, name, error, disabled, input_type, ...props }) => {
    return (
        <FieldStyled
            input_reverse={input.reverse ? true : undefined}
            input_icon={input.icon.component}
            className={className}
            fullwidth={fullwidth ? true : undefined}
        >
            
            {label ?
            <div className={`containet_label ${label.reverse ? 'reverse' : ''}`}>
                {label.icon.component ?
                <Icon 
                    type={label.icon.type}
                    icon={label.icon.component}
                    size={`label ${label.icon.size}`}
                    error={error}
                /> : ""}
                <Label 
                    type={label.type}
                    label={label.text} 
                    error={error}
                />
            </div> : ""}

            <div className={`containet_input ${input.reverse ? 'reverse' : ''} ${!input.icon.component ? 'no-icon' : ''}`}>
                {input.icon.component ?
                <Icon 
                    type={input.icon.type}
                    icon={input.icon.component}
                    size={`input ${input.icon.size}`} 
                    error={error}
                /> : ""}
                <Input 
                    type={input.type}
                    placeholder={input.placeholder} 
                    size={input.size} 
                    className={input.className} 
                    reverse={input.reverse} 
                    //prefix={input.prefix ? input.prefix : null} 
                    onChange={onChange}
                    name={name}
                    error={error}
                    value={value}
                    disabled={disabled}
                    input_type={input.input_type}
                />
            </div>
        </FieldStyled>
    );

};

FieldInput.propTypes = {
    placeholder: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element,
    onChange: PropTypes.func,
    name: PropTypes.string,
    error: PropTypes.bool,
    className: PropTypes.string,
    fullwidth: PropTypes.bool,
    value: PropTypes.string,
    disabled: PropTypes.bool,

    input: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        placeholder: PropTypes.string,
        icon: PropTypes.shape({
            uuid: PropTypes.string,
            type: PropTypes.string,
            component: PropTypes.element,
            size: PropTypes.oneOf(['small', 'medium', 'large']),
            error: PropTypes.bool,
        }),
        className: PropTypes.string,
        reverse: PropTypes.bool,
        error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    }),

    label: PropTypes.shape({
        uuid: PropTypes.string,
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        text: PropTypes.string,
        icon: PropTypes.shape({
            uuid: PropTypes.string,
            type: PropTypes.string,
            component: PropTypes.element,
            size: PropTypes.oneOf(['small', 'medium', 'large']),
            error: PropTypes.bool,
            scope: PropTypes.string,
        }),
        className: PropTypes.string,
        reverse: PropTypes.bool,
        error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    }),
};

// FieldInput.defaultProps = {
//     type: 'default',
//     size: 'medium',
//     icon: null,
//     placeholder: "Placeholder...",
//     onClick: undefined,
//     input: {
//         type: 'primary',
//         placeholder: "Prueba",
//         icon: {
//             type: 'primary',
//             component: null,
//             size: 'medium',
//             error: false,
//         },
//         className: "input",
//         reverse: false,
//         error: false,
//         size: 'medium',
//     },
//     label: {
//         type: 'primary',
//         text: "Prueba",
//         icon: {
//             type: 'primary',
//             component: null,
//             size: 'medium',
//             error: false,
//         },
//         className: "",
//         reverse: false,
//         error: false,
//         size: 'medium',
//     }
    
// };

export default React.memo(FieldInput);