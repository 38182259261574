import styled from "styled-components";
import { colors } from "../../helpers/commons";
import { breakpoints } from "../../helpers/breakpoints";

export const WrapperStyled = styled.div`
    background-color: rgba(${colors.black}, 1);
    height: auto;
    .content{
        .container{
            width: 100%!important;
            margin-left: 0!important;
            margin-top: 20px;
        }
    }
    .top{
        display: flex;
        flex-direction: column;
        //background-color: pink;

        .logo{
            width: 65vw;
            margin-right: 50px;
        }
        .columns{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-top: 40px;
            .column{
                
                &.funds{
                    width: 200px;
                    margin-right: 50px;
                    margin-bottom: 30px;
                }
                &.markets{
                    width: 200px;
                }
                .title{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 15px;
                    .text{
                        font-size: 23px;
                        line-height: 23px;
                        font-weight: 600;
                        color: #FFFFFF;
                        margin-left: 20px;
                    }
                    .icon{
                        width: 45px;
                        img{
                            float: left;
                            width: 100%;
                        }
                    }
                }
                .links{
                    display: flex;
                    flex-direction: column;
                    padding-left: 68px;
                    a{
                        text-decoration: none;
                        color: #FFFFFF;
                        margin-bottom: 7px;
                        &:hover{
                            color: rgba(${colors.red}, 1);
                        }
                    }
                }
            }
        }

    }
    .bottom{
        //background-color: skyblue;

        .all_rights{
            margin-top: 50px;
            color: #FFFFFF;
            width: 100%;
            border-top: 1px solid rgba(255,255,255,0.5);
            padding: 15px 0;
            font-size: 13px;
            line-height: 13px;
        }

    }

    @media ${breakpoints.xs} {//576px
        height: 400px;
        .top{
            display: flex;
            flex-direction: column;
            //background-color: pink;

            .logo{
                width: 300px;
                margin-right: 50px;
            }
            .columns{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                .column{
                    &.funds{
                        width: 200px;
                        margin-right: 50px;
                        margin-bottom: 0;
                    }
                }
            }
        }

    }
    @media ${breakpoints.sm} {//768px

        /*
        .inner{
            display: flex;
            flex-direction: column;
            .top{
                display: flex;
                flex-direction: row;
            }
            .bottom{

            }
        }
        .columns{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }
        */

    }
    @media ${breakpoints.md} {//992px
        height: 400px;
        .content{
            .container{
                margin-left: 50px;
                margin-top: 50px;
                width: calc(100% - 50px);
            }
        }
        .top{
            display: flex;
            flex-direction: row;
            //background-color: pink;

            .logo{
                width: 300px;
                margin-right: 150px;
            }
            .columns{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-top: 0;
                .column{
                    &.funds{
                        width: 200px;
                        margin-right: 70px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        /*
        height: 400px;
        .inner{
            display: flex;
            flex-direction: column;
            .top{
                display: flex;
                flex-direction: row;
            }
            .bottom{

            }
        }
        */

        /*
        .columns{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .column{
                &.logo{
                    width: 300px;
                    margin-right: 100px;
                }
                &.funds{
                    width: 200px;
                    margin-right: 100px;
                }
                &.markets{
                    width: 200px;
                }
                .title{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 15px;
                    .text{
                        font-size: 23px;
                        line-height: 23px;
                        font-weight: 600;
                        color: #FFFFFF;
                        margin-left: 20px;
                    }
                    .icon{
                        width: 45px;
                        img{
                            float: left;
                            width: 100%;
                        }
                    }
                }
                .links{
                    display: flex;
                    flex-direction: column;
                    padding-left: 68px;
                    a{
                        text-decoration: none;
                        color: #FFFFFF;
                        margin-bottom: 7px;
                        &:hover{
                            color: rgba(${colors.red}, 1);
                        }
                    }
                }
            }
        }
            */
    }
    @media ${breakpoints.lg} {//1200px // Desktop
        
    }
    @media ${breakpoints.xl} {//1366px
        
    }
    @media ${breakpoints.xxl} {//1440px
        
    }
    @media ${breakpoints.xxxl} {//1680px
        
    }
    @media ${breakpoints.xxxxl} {//1920px

    }
`