import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch } from '../../Atoms/Switch/Switch';
import { Label } from '../../Atoms/Label/Label';
import { Icon } from '../../Atoms/Icon/Icon';

const FieldStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .containet_label{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 7px;
        .icon{
            display: flex;
            padding-right: 2px;
            svg{
                display: flex;
            }
        }
        &.reverse{
            margin-right: 0;
            .icon{
                padding-right: 3px;
                padding-left: 2px;
            }
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }
    .containet_input{
        display: flex;
        flex-direction: row;
        align-items: center;
        .input{
            width: calc(100% - 40px);
        }
        .icon{
            display: flex;
            justify-content: center;
            width: 40px;
            padding-right: 2px;
            /* background-color: pink; */
        }
        &.no-icon{
            .input{
                width: 100%!important;
            }
        }
        
    }

    &.reverse{
        flex-direction: row-reverse;
        justify-content: flex-end;
        .containet_label{
            margin-right: 0;
            margin-left: 7px;
        }
    }
`

const FieldSwitch = ({_switch, label, reverse, active, name, onClick}) => {
    return (
        <FieldStyled
            inputReverse={_switch.reverse}
            className={`${reverse ? 'reverse' : ''}`}
        >
            <div className={`containet_label ${label.reverse ? 'reverse' : ''}`}>
                {label.icon.component ?
                <Icon 
                    type={label.icon.type}
                    icon={label.icon.component}
                    size={`label ${label.icon.size}`}
                /> : ""}
                <Label 
                    type={label.type}
                    label={label.text} 
                />
            </div>
            <div className={`containet_input`}>
                <Switch 
                    active={active}
                    type={_switch.type}
                    size={_switch.size} 
                    className={_switch.className} 
                    error={_switch.error}
                    onClick={onClick}
                    name={name}
                />
            </div>
        </FieldStyled>
    );
};

/*
FieldSwitch.propTypes = {
    onClick: PropTypes.func,
    reverse: PropTypes.bool,
    active: PropTypes.bool,
    name: PropTypes.string,

    _switch: PropTypes.shape({
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        className: PropTypes.string,
        error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    }),

    label: PropTypes.shape({
        type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
        text: PropTypes.string,
        icon: PropTypes.shape({
            type: PropTypes.string,
            component: PropTypes.element,
            size: PropTypes.oneOf(['small', 'medium', 'large']),
            error: PropTypes.bool,
        }),
        className: PropTypes.string,
        error: PropTypes.bool,
        size: PropTypes.oneOf(['small', 'medium', 'large']),
    }),
};
*/

export default FieldSwitch;