import styled from 'styled-components';

export const FieldOuter = styled.div`

    &.fullwidth{
        grid-column: span 2;
    }
    &.duoble{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .el-quantity{
            width: calc(100% - 130px)!important;
        }
        .field_complex{
            .el-quantity_unit{
                width: 120px;
            }
        }
    }
`
export const FieldDoubleStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .el-quantity{
        width: calc(100% - 130px)!important;
    }
    .field_complex{
        .el-quantity_unit{
            width: 120px;
        }
    }
`

export const FormElementStyled = styled.div`
    .field_complex{
        &.fullwidth{
            grid-column: span 2;
        }
    }

`