import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar as SnackbarMui } from '@mui/material';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const Snackbar = ({ hide, snackbarData, setSnackbarData, open, setOpen }) => {

    function SlideTransition(props) {
        return <Slide {...props} direction="down" />;
      }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    return (
        <SnackbarMui 
            open={open} 
            autoHideDuration={hide} 
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            //TransitionComponent={SlideTransition}
        >
            <Alert
                onClose={handleClose}
                severity={ snackbarData.severity }
                variant="filled"
                sx={{ width: '100%' }}
                
            >
                { snackbarData.text }
            </Alert>
        </SnackbarMui>
    );
};

Snackbar.propTypes = {
    snackbarData: PropTypes.object,
    setSnackbarData: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    hide: PropTypes.number,
};

export default Snackbar;