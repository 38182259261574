import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../helpers/commons';

const ButtonStyled = styled.button`
  
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 5px 5px;
    border-radius: 50%;
    background-color: transparent;
    
    &.small{
        
        font-size: 14px;
        line-height: 14px;
        padding: 7px 7px;
        
    }
    &.small .icon{
        font-size: 11px;
    }
    &.medium{
        font-size: 16px;
        line-height: 16px;
    }
    &.medium .icon{
        font-size: 13px;
    }
    &.large{
        font-size: 25px;
        line-height: 25px;
    }
    &.large .icon{
        font-size: 14px;
    }
    .icon{
        display: flex;
        font-size: 14px;
        line-height: 14px;
        margin-right: 7px;
    }

    &.primary{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &.primary:hover{
        background-color: ${props => `rgba(${props.colors.primary}, 0.15)` };
        border: 1px solid ${props => `rgba(${props.colors.primary}, 0.2)` };
    }

    &.default{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &.default:hover{
        background-color: ${props => `rgba(${props.colors.primary}, 0.15)` };
        border: 1px solid ${props => `rgba(${props.colors.primary}, 0.2)` };
    }
    &.important{
        color: ${props => `rgba(${props.colors.important}, 1)` };
    }
    &.important:hover{
        background-color: ${props => `rgba(${props.colors.important}, 0.15)` };
        border: 1px solid ${props => `rgba(${props.colors.important}, 0.2)` };
    }

    &.cancel{
        color: ${props => `rgba(${props.colors.cancel}, 1)` };
    }
    &.cancel:hover{
        background-color: ${props => `rgba(${props.colors.cancel}, 0.15)` };
        border: 1px solid ${props => `rgba(${props.colors.cancel}, 0.2)` };
    }

    &.accept{
        color: ${props => `rgba(${props.colors.accept}, 1)` };
    }
    &.accept:hover{
        background-color: ${props => `rgba(${props.colors.accept}, 0.15)` };
        border: 1px solid ${props => `rgba(${props.colors.accept}, 0.2)` };
    }

    &:disabled{
        opacity: 0.3;
        cursor: default;
        &:hover{
            border: 1px solid transparent!important;
            background-color: transparent!important;
        }
    }

    margin-top: ${props => props.margins.top ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => props.margins.bottom ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => props.margins.left ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => props.margins.right ? 5 * props.margins.right + "px" : 0}!important;

`
export const ButtonIcon = ({ disabled = false, className = '', type, size, icon, margins = {top: 0, bottom: 0, left: 0, right: 0}, ...props }) => {
    return (
      <ButtonStyled
        colors={colors}
        type="button"
        disabled={disabled}
        className={`${className} ${type} ${size}`}
        //style={backgroundColor && { backgroundColor }}
        margins={margins}
        {...props}
      >
        {icon}
      </ButtonStyled>
    );
  };

ButtonIcon.propTypes = {
    type: PropTypes.oneOf(['primary', 'important', 'cancel', 'accept', 'default', 'form']),
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    onClick: PropTypes.func,
    icon: PropTypes.element,
    disabled: PropTypes.bool,
    margins: PropTypes.shape({
        top: PropTypes.oneOf([1, 2, 3, 4, 5]),
        bottom: PropTypes.oneOf([1, 2, 3, 4, 5]),
        left: PropTypes.oneOf([1, 2, 3, 4, 5]),
        right: PropTypes.oneOf([1, 2, 3, 4, 5]),
    }),
};