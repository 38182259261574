import styled from "styled-components";
import { colors } from "../../../helpers/commons";

export const WrapperStyled = styled.div`
    .title{
        font-size: 35px;
        line-height: 35px;
        font-weight: 500;
        color: rgba(${colors.brand}, 1);
    }
`