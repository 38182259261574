import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../helpers/commons';
import CurrencyInput from 'react-currency-input-field';

const WrapperInputCurrencyStyled = styled.div`
    display: flex;
    width: 100%;
    .suffix{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        background-color: ${props => `rgba(${props.colors.secondary}, .5)` };
        border: 1px solid #CADAFF;
        border-left: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        font-weight: 600;
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
`

const InputCurrencyStyled = styled(CurrencyInput)`
    width: 100%;
    font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,Roboto,Arial,sans-serif!important;
    border-radius: 6px;
    padding: 15px 15px;
    background-color: #FBFCFF;
    color: #879AC6;
    font-size: 15px;
    outline: 0;
    border: 1px solid #CADAFF;
    border-top-right-radius: ${props => props.have_suffix ? 0 : "6px"};
    border-bottom-right-radius: ${props => props.have_suffix ? 0 : "6px"};

    &.small{
        padding: 10px 10px;
        font-size: 12px;
    }
    &.medium{
        padding: 15px 15px;
        font-size: 15px;
    }
    &.large{
        padding: 15px 15px;
        font-size: 18px;
    }
    &.important{
        color: ${props => `rgba(${props.colors.important}, 1)` };
    }
    &.cancel{
        color: ${props => `rgba(${props.colors.cancel}, 1)` };
    }
    &.accept{
        color: ${props => `rgba(${props.colors.accept}, 1)` };
    }
    &.default{
        color: ${props => `rgba(${props.colors.default}, 1)` };
    }
    &.primary{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &.error{
        border-color: ${props => `rgba(${props.colors.error}, 1)` };
    }
    &::placeholder{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &::-ms-input-placeholder{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &:disabled{
        opacity: 0.5;
    }
    
`

export const InputCurrency = ({ onChange,
                                className, 
                                prefix,
                                suffix,
                                error,
                                type,
                                size, 
                                name, 
                                placeholder, 
                                value,
                                disabled,
                                ...props }) => {
                                    
    let val = '';
    if(value !== undefined && value !== null){
        if(value.length){
            val = value;
        }
    }

    return (
        <WrapperInputCurrencyStyled
            colors={colors}
        >
            <InputCurrencyStyled
                id="input-example"
                name={name}//"input-name"
                placeholder={placeholder}//"Please enter a number"
                className={`${className} ${type} ${size} ${error ? 'error' : ''}`}
                //defaultValue={val}
                value={value}
                allowDecimals={false}
                decimalsLimit={0}
                onValueChange={(value, name) => onChange(value, name)}
                colors={colors}
                prefix={prefix}
                decimalSeparator="," 
                groupSeparator="."
                disabled={disabled}
                have_suffix={suffix?.length ? true : false}
            />
            { suffix?.length ? <div className='suffix'>{suffix}</div> : ""}
        </WrapperInputCurrencyStyled>
    );
};

InputCurrency.propTypes = {
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.string,//.isRequired,
    //setValue: PropTypes.func,//.isRequired,
};

InputCurrency.defaultProps = {
    type: 'primary',
    size: 'medium',
    placeholder: '',
    name: '',
    className: "input_currency",
    error: false,
    disabled: false,
};