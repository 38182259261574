import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../../../helpers/commons';

const LabelStyled = styled.label`
    font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,Roboto,Arial,sans-serif!important;
    color: ${props => `rgba(${props.colors.primary}, 1)` };
    font-weight: 500;
    &.error{
        color: ${props => `rgba(${props.colors.error}, 1)` }!important;
    }
    &.small{
        font-size: 13px;
        line-height: 16px;
    }
    &.medium{
        font-size: 15px;
        line-height: 18px;
    }
    &.large{
        font-style: 19px;
        line-height: 22px;
    }
    &.important{
        color: ${props => `rgba(${props.colors.important}, 1)` };
    }
    &.cancel{
        color: ${props => `rgba(${props.colors.cancel}, 1)` };
    }
    &.accept{
        color: ${props => `rgba(${props.colors.accept}, 1)` };
    }
    &.default{
        color: ${props => `rgba(${props.colors.default}, 1)` };
    }
    &.primary{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }
    &.form{
        color: ${props => `rgba(${props.colors.labelForm}, 1)` };
    }
`

export const Label = ({ label, icon, size, error, type, uuid, ...props }) => {
    return (
        <LabelStyled
            key={uuid}
            colors={colors}
            className={`${size} ${error ? 'error' : ''} ${type}`}
        >
            <div className='label'>{label}</div>
        </LabelStyled>
    );

};

Label.propTypes = {
    _key: PropTypes.string,
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    label: PropTypes.string.isRequired,
    error: PropTypes.bool,
    uuid: PropTypes.string,
};

Label.defaultProps = {
    type: 'default',
    size: 'medium',
    label: 'Defaul Label',
    error: false,
};