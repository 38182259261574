import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 10px 10px;
    border: 1px ${props => props.borderType} #CADAFF;
    border-radius: 6px;

    margin-top: ${props => props.margins.top ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => props.margins.bottom ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => props.margins.left ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => props.margins.right ? 5 * props.margins.right + "px" : 0}!important;
    //width: 800px;
`

export const ContainerSimple = ({ className, children, margins = {top: 0, bottom: 0, left: 0, right: 0}, borderType }) => {
    return (
        <Container
            margins={margins}
            borderType={borderType}
            className={`container ${className}`}
        >
            {children} 
        </Container>
    );
};

ContainerSimple.propTypes = {
    className: PropTypes.string,
    margins: PropTypes.shape({
        top: PropTypes.oneOf([1, 2, 3, 4, 5]),
        bottom: PropTypes.oneOf([1, 2, 3, 4, 5]),
        left: PropTypes.oneOf([1, 2, 3, 4, 5]),
        right: PropTypes.oneOf([1, 2, 3, 4, 5]),
    }),
    borderType: PropTypes.oneOf(['dashed', 'dotted', 'solid']),
    position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

ContainerSimple.defaultProps = {
    className: '',
    borderType: 'dashed',
};