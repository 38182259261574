import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

const WrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    font-size: 18px;
    .bt_back{
        margin-top: 15px;
        cursor: pointer;
        color: #d61a28;
        font-weight: 600;
        &:hover{
            color: #202960;
        }
    }
`

const ErrorPage = props => {

    const navigate = useNavigate();

    const handleGoTo = (url) => {
        navigate(`/${url}`);
    }

    return (
        <WrapperStyled className='conta'>
            There was an error loading the requested page.
            <div className='bt_back' onClick={() => handleGoTo('')}>Go Back</div>
        </WrapperStyled>
    );
};

ErrorPage.propTypes = {
    
};

export default ErrorPage;