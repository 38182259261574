import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const OverlayStyled = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: ${props => props.position};
    
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    .child{
        position: absolute;
    }
    .background{
        background-color: rgba(0, 0, 0, 0.75);
        width: 100%;
        height: 100%;
    }
`

export const Overlay = ({onClick, open, children, position = 'fixed'}) => {
    
    return (
        <Fragment>
            {open ? 
                <OverlayStyled
                    position={position}
                    
                >
                    <div className='child'>
                        {children}
                    </div>
                    <div onClick={onClick} className='background'></div>
                </OverlayStyled>
            : ""}
        </Fragment>
    );
};

Overlay.propTypes = {
    position: PropTypes.string,
    onClick: PropTypes.func,
    open: PropTypes.string,
};