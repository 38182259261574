import React, { useEffect, useContext, useState } from 'react';
import { Chart } from "react-google-charts";
import { WrapperStyled } from './styles';
import { colors } from '../../helpers/commons';
import { CircularProgress } from '@mui/material';
import { createBrowserRouter,
         RouterProvider,
         redirect,
         useLocation } from "react-router-dom";
import { ContentContext } from '../../context';

const Performance = () => {

    const {get_user_performance} = useContext(ContentContext);

    const [ monthSelected, setMonthSelected ] = useState(0);
    const [ performance, setPerformance ] = useState(null);

    const handleChangeMonth = (month) => {

        handleGetData({month_range: month});
        setMonthSelected(month);

    }

    useEffect(() => {
        
        handleGetData({month_range: 0});
    
      return () => {}
    }, [])

    const handleGetData = (filters = null) => {

        const response = get_user_performance(filters);
        response.then(res => {

            if(res.data.records.length){
                let performance_arr = [["Month", "Net Custodied Assets", { role: "style" }]];

                res.data.records.map((record, index) => {
                    performance_arr.push([record.month_name, parseInt(record.total_amount), index % 2 ? "#202960" : "#00689d"]);
                })

                console.log("res.data.records", res.data.records, performance_arr)

                setPerformance(performance_arr);//res.data.records
            }else{
                setPerformance([]);
            }
        })

    }


    /*
    const handleChangeMonth = (month) => {

        setMonthSelected(month);

    }
    */

    const data = [
        ["Month", "Net Custodied Assets", { role: "style" }],
        ["January", 500000, "#202960"], // RGB value
        ["February", 515000, "#00689d"], // English color name
        ["March", 450000, "#202960"],
        ["April", 615000, "#00689d"], // CSS-style declaration
      ];

    useEffect(() => {
      console.log("performance", performance)
    
      return () => {}
    }, [performance])
    

    return (
        <WrapperStyled>
            <div className='content'>
                {performance === null ?
                    <div className='loader'>
                        <CircularProgress size={50}/>
                    </div>
                : 
                performance.length  ? 
                <>
                
                <div className='top'>
                    <div className='months'>
                        <div className={`month${monthSelected === 0 ? ' selected' : ''}`} onClick={() => handleChangeMonth(0)}>1M</div>
                        <div className={`month${monthSelected === 6 ? ' selected' : ''}`} onClick={() => handleChangeMonth(6)}>6M</div>
                        <div className={`month${monthSelected === 12 ? ' selected' : ''}`} onClick={() => handleChangeMonth(12)}>12M</div>
                    </div>
                </div>
                <div className='bottom'>

                    <Chart chartType="ColumnChart" width="100%" height="400px" data={performance} />
                    
                </div>
                </>
                : <div className='nodata'>No data to show</div>
                }
            </div>

            

        </WrapperStyled>
    );
};

export default Performance;