import React, { useState, useEffect, useCallback, useReducer, useMemo, useRef } from 'react';

import { path, defaultTo } from 'ramda';
import axios from 'axios';

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {

    const [ isAuth, setIsAuth ] = useState(null);
    const [ authToken, setAuthToken ] = useState(null);
    const [ user, setUser ] = useState({});
    
    useEffect(() => {
      //localStorage.clear();
        if(localStorage.getItem('token')){
            
            get_user();
        }else{
            setIsAuth(false);
        }
        setAuthToken(defaultTo('', localStorage.getItem('token')));

    }, [localStorage.getItem('token')]);



    
    const login = useCallback(
        async (email, password) => {
          
            const payload = {
                email,
                password,
            };

            const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, payload, {
                headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                },
            });

            return result;

        },[],
    );
    
    const logout = useCallback(async () => {
        
      const requestURL = `${process.env.REACT_APP_BASE_URL}/logout`;

      try {

          const result = await axios.post(requestURL, {token: localStorage.getItem('token')});
          
          if(result){
            //alert("xx")
            setUser({});
            localStorage.clear();
            setIsAuth(false);
          }

          return result;

        } catch (error) {

        }

    }, []);

    
    const get_user = useCallback(async (payload) => {
        
        const requestURL = `${process.env.REACT_APP_BASE_URL}/utils/user`;

        try {

            const result = await axios.get(requestURL);
            const data = path(['data', 'record'], result);
            
            if(data){
              setIsAuth(true);
              setUser(data);
            }

            return result;

        } catch (error) {

        }

    }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        login,
        logout,
        setAuthToken,
        user, 
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
