import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContainerDrawer } from '../../Atoms/Container/ContainerDrawer';
import { Backdrop } from '../../Atoms/Backdrop';
import { useLockBodyScroll } from '@uidotdev/usehooks';
        
const DrawerStyled = styled.div`
    width: 500px;
    background-color: #FFFFFF;
    position: fixed;
    right: -550px;
    top: 0;
    height: 100vh;
    z-index: 99999;

    box-shadow: 0px 1px 11px 1px rgba(0,0,0,0.35);
    -webkit-box-shadow: 0px 1px 11px 1px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 1px 11px 1px rgba(0,0,0,0.35);

    overflow-y: auto;

    &.open{
        transform: translateX(-550px);
        transition-timing-function: ease-in;
        transition: 0.4s;
    }
    &.close{
        transform: translateX(0);
        transition-timing-function: ease-out;
        transition: 0.4s;
    }

`

const Drawer = ({ component, open, setOpen, hide, setHide }) => {

    useLockBodyScroll(); 

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
        <DrawerStyled
            className={`${hide ? 'close' : 'open'}`}
        >
            <ContainerDrawer
                paddings={{top: 3, left: 4, bottom: 3, right: 4}}
            >
                {component}
            </ContainerDrawer>
        </DrawerStyled>
        {open &&
        <Backdrop 
            open={open}
            onClick={handleClose}
            hide={hide}
            setHide={setHide}
        />}
        </>
    );
};

Drawer.propTypes = {
    open: PropTypes.string,
    setOpen: PropTypes.func,
    hide: PropTypes.string,
    setHide: PropTypes.func,
    component: PropTypes.element,
};

export default Drawer;