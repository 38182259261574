import React from 'react';
import { WrapperStyled } from './styles';

const InputCustom = (props) => {

    const { error,
            name,
            type,
            className,
            placeholder,
            handleOnChange } = props;

    return (
        <WrapperStyled>
            <input 
                name={name}
                placeholder={placeholder}
                className={`${className}${error ? " error" : ""}`}
                type={type} 
                onChange={(e) => handleOnChange(e, name)}
            />
        </WrapperStyled>
    );
};

export default InputCustom;