import React, { useContext, useState, useEffect } from 'react';
import { createBrowserRouter,
         RouterProvider,
         redirect,
         useLocation } from "react-router-dom";
import { AuthContext,
         ContentContext } from '../../context';
import { WrapperStyled } from './styles';
import { Scrollbar } from 'react-scrollbars-custom';
import { CircularProgress } from '@mui/material';
import { number_format } from '../../helpers/commons';

const Portfolio = () => {

    const { get_user_debitcard } = useContext(ContentContext);

    const [ monthSelected, setMonthSelected ] = useState(0);
    const [ debitcard, setDebitcard ] = useState(null);

    const handleChangeMonth = (month) => {

        handleGetData({month_range: month});
        setMonthSelected(month);

    }

    useEffect(() => {
        
        handleGetData({month_range: 0});
    
      return () => {}
    }, [])

    const handleGetData = (filters = null) => {

        const response = get_user_debitcard(filters);
        response.then(res => {
            console.log("res", res)
            setDebitcard(res.data.records);
        })

    }




    return (
        <WrapperStyled>
            <div className='content'>
                {debitcard === null ?
                    <div className='loader'>
                        <CircularProgress size={50}/>
                    </div>
                : 
                <>
                <div className='top'>
                    <div className='months'>
                        <div className={`month${monthSelected === 0 ? ' selected' : ''}`} onClick={() => handleChangeMonth(0)}>1M</div>
                        <div className={`month${monthSelected === 6 ? ' selected' : ''}`} onClick={() => handleChangeMonth(6)}>6M</div>
                        <div className={`month${monthSelected === 12 ? ' selected' : ''}`} onClick={() => handleChangeMonth(12)}>12M</div>
                    </div>
                </div>
                <div className='bottom'>
                    
                    <div className='table'>
                        {debitcard !== null ?
                        <Scrollbar className='scrollbar' permanentTrackY={true} disableTracksMousewheelScrolling={false}>
                            <div className='head'>
                                <div className='column date'>Date</div>
                                <div className='column description'>Concept</div>
                                <div className='column value'>Value</div>
                                <div className='column amount'>Amount</div>
                                <div className='column balance'>Balance</div>
                            </div>

                            {!debitcard.length ? <div className='nodata'>No data to show</div> : ""}
                            
                            <>
                            {debitcard.length ?

                            <div className='rows'>
                                {debitcard.map((element, index) => {
                                        return(
                                            <div className={`row${index % 2 ? " gray" : ""}`}>
                                                <div className='date'>{ element.date }</div>
                                                <div className='description'>{ element.concept }</div>
                                                <div className='value'>{ number_format(element.value) }</div>
                                                <div className='amount'>{ number_format(element.amount) }</div>
                                                <div className='balance'>{ number_format(element.balance) }</div>
                                            </div>
                                        )
                                    })}
                                    </div> : ""}
                                </>
                        </Scrollbar>
                        : ""}
                    </div>
                    
                </div>
                </> }
            </div>
        </WrapperStyled>
    );
};

export default Portfolio;