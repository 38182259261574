import styled from "styled-components";
import { colors } from "../../../helpers/commons";

export const WrapperStyled = styled.div`
    width: 100%;
    input{
        width: 100%;
        border-radius: 6px;
        border: 1px solid #c9c9c9;
        background-color: #f6f6f6;
        font-size: 16px;
        line-height: 16px;
        padding: 13px 15px;
        outline: 0;
        &::placeholder{
            color: #a8a8a8;
        }
        &.error{
            border: 1px solid rgba(${colors.red}, 1);
        }
    }
`