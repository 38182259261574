import styled from 'styled-components';
import { colors } from '../../../../helpers/commons';
import { breakpoints } from '../../../../helpers/breakpoints';

export const WrapperStyled = styled.div`

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    width: 100%;

    margin-top: ${props => props.margins.top ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => props.margins.bottom ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => props.margins.left ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => props.margins.right ? 5 * props.margins.right + "px" : 0}!important;

    .loader{
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .bulk_pays{
        .bulk_pay{
            margin-bottom: 5px;
        }
    }
    .block{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(243, 246, 255, 0.75);
        z-index: 99;
    }
    .no_result{
        text-align: center;
    }
    .bt_add_expense{
        
    }
    .filter_box{
        width: 100%;
        margin-bottom: 40px;
        padding-right: 0;
        margin-top: 40px;
        .inner{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            width: 100%;
            .filter_options{
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                flex-wrap: wrap;
                //background-color: pink;
                //width: auto;//690px;
                .field_filter{
                    width: 100%;
                    margin-top: 10px!important;
                    margin-bottom: 0!important;
                    margin-left: 0!important;
                    &.method_of_payment{
                        margin-left: 0!important;
                    }
                    &.supplier{
                        margin-left: 0!important;
                    }
                    &.status{
                        //width: 180px;
                    }
                    &:first-child{
                        margin-left: 0!important;
                    }
                }
                .date_range{
                    width: 100%;
                    margin-top: 10px!important;
                    margin-left: 0!important;
                    .rs-stack{
                        .rs-stack-item{
                            width: 100%;
                            .rs-picker-disabled{
                                opacity: 1!important;
                            }
                        }
                        .rs-picker{
                            width: 100%;
                            //width: 220px!important;
                            .rs-input-group{
                                input{
                                    padding: 10px 10px!important;
                                    font-size: 12px!important;
                                }
                            }
                        }
                    }
                }
            }
            .bt_filter{
                margin-top: 10px!important;
                margin-left: 10px!important;
            }
        }
    }
    .table_box{
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            width: 0;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
        .inner{
            width: 1085px;
            .table_header,
            .table_row{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
            .field_name{
                margin-left: 0;
            }

            >.table_body .table_row{
                padding: 15px 20px;
                border-bottom: 1px solid ${props => `rgba(${props.colors.secondary}, 1)` };
            }
            >.table_body .table_row:last-child{
                border-bottom: 0;
            }

            >.table_header .column,
            >.table_body .table_row .column{
                margin-right: 15px;
            }
            >.table_header .column.check,
            >.table_body .table_row .column.check{
                width: 26px;
            }
            >.table_body .table_row .column.actions{
                display: flex;
                flex-direction: row;
            }
            

            >.table_header .column.client,
            >.table_body .table_row .column.client{
                width: 318px;
                //background-color: pink;
            }
            >.table_header .column.beginning_value,
            >.table_body .table_row .column.beginning_value{
                width: 210px;
                //background-color: pink;
            }
            >.table_header .column.ending_value,
            >.table_body .table_row .column.ending_value{
                width: 210px;
                //background-color: pink;
            }
            >.table_header .column.actions,
            >.table_body .table_row .column.actions{
                width: 56px;
                margin-right: 0;
                //background-color: skyblue;
            }

             

            >.table_header .column.created_at,
            >.table_body .table_row .column.created_at{
                width: 625px;
                //background-color: pink;
            }
        }
    }
    .month_select{
        width: 100%;
        align-self: flex-end;
        padding-right: 35px!important;
    }

    @media ${breakpoints.xs} {//576px
        .filter_box{
            .inner{
                //flex-direction: column;
                .filter_options{
                    width: 100%;
                    .field_filter{
                        width: calc(50% - 5px);
                        margin-top: 10px!important;
                        margin-bottom: 0!important;
                        margin-left: 0!important;
                        &.concept{
                            margin-left: 10px!important;
                        }
                    }
                    .date_range{
                        width: calc(50% - 5px);
                        margin-top: 10px!important;
                        margin-left: 0!important;
                        .rs-stack{
                            .rs-stack-item{
                                width: 100%;
                            }
                        }
                    }
                }
                .bt_filter{
                    margin-left: 10px!important;
                }
            }
        }
        .table_box{
            width: 100%;
            padding-right: 35px!important;
            .inner{
                //width: 1215px;
                
                
            }
        }
        .month_select{
            width: 300px;
            padding-right: 35px!important;
        }
    }
    @media ${breakpoints.sm} {//768px
        .filter_box{
            .inner{
                //flex-direction: row;
            }
        }
    }
    @media ${breakpoints.md} {//992px
        .filter_box{
            width: 100%;
            margin-bottom: 40px;
            .inner{
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: flex-start;
                width: 100%;
                .filter_options{
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    //background-color: pink;
                    width: auto;//690px;
                    .field_filter{
                        width: 180px;
                        margin-top: 0!important;
                        margin-bottom: 0!important;
                        margin-left: 10px!important;
                        &.method_of_payment{
                            margin-left: 10px!important;
                        }
                        &.status{
                            //width: 180px;
                        }
                        &:first-child{
                            margin-left: 0!important;
                        }
                    }
                    .date_range{
                        width: 220px!important;
                        margin-left: 10px!important;
                        .rs-stack{
                            .rs-stack-item{
                                .rs-picker-disabled{
                                    opacity: 1!important;
                                }
                            }
                            .rs-picker{
                                width: 100%!important;
                                .rs-input-group{
                                    input{
                                        padding: 10px 10px!important;
                                        font-size: 12px!important;
                                    }
                                }
                            }
                        }
                    }
                }
                .bt_filter{
                    margin-left: 10px!important;
                }
            }
        }
    }
    @media ${breakpoints.lg} {//1200px Desktop
        
        .table_box{
            width: 100%;
            padding-right: 0!important;
            .inner{
                width: 100%;
                
                /* >.table_header .column.client,
                >.table_body .table_row .column.client{
                    width: 174px;
                }
                >.table_header .column.concept,
                >.table_body .table_row .column.concept{
                    width: 424px;
                }
                >.table_header .column.value,
                >.table_body .table_row .column.value{
                    width: 150px;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 120px;
                } */

            }
        }
        .month_select{
            padding-right: 0!important;
        }
    }
    @media ${breakpoints.xl} {//1366px
        .table_box{
            width: 100%;
            .inner{
                width: 100%;
                
                /* >.table_header .column.client,
                >.table_body .table_row .column.client{
                    width: 204px;
                }
                >.table_header .column.concept,
                >.table_body .table_row .column.concept{
                    width: 540px;
                }
                >.table_header .column.value,
                >.table_body .table_row .column.value{
                    width: 150px;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 140px;
                } */

            }
        }
    }
    @media ${breakpoints.xxl} {//1440px
        .table_box{
            width: 100%;
            .inner{
                width: 100%;
                
                /* >.table_header .column.client,
                >.table_body .table_row .column.client{
                    width: 218px;
                }
                >.table_header .column.concept,
                >.table_body .table_row .column.concept{
                    width: 580px;
                }
                >.table_header .column.value,
                >.table_body .table_row .column.value{
                    width: 170px;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 140px;
                } */

            }
        }
    }
    @media ${breakpoints.xxxl} {//1680px
        .table_box{
            width: 100%;
            .inner{
                width: 100%;
                
                /* >.table_header .column.client,
                >.table_body .table_row .column.client{
                    width: 248px;
                }
                >.table_header .column.concept,
                >.table_body .table_row .column.concept{
                    width: 774px;
                }
                >.table_header .column.value,
                >.table_body .table_row .column.value{
                    width: 166px;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 160px;
                } */

            }
        }
    }
    @media ${breakpoints.xxxxl} {//1920px
        .table_box{
            width: 100%;
            .inner{
                width: 100%;

                /* >.table_header .column.client,
                >.table_body .table_row .column.client{
                    width: 248px;
                }
                >.table_header .column.concept,
                >.table_body .table_row .column.concept{
                    width: 1014px;
                }
                >.table_header .column.value,
                >.table_body .table_row .column.value{
                    width: 166px;
                }
                >.table_header .column.created_at,
                >.table_body .table_row .column.created_at{
                    width: 160px;
                } */
            }
        }
    }
`

export const LoaderStyled = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25px;
`

