import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BackdropStyled = styled.div`
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    //width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 9998;
    opacity: 0;

    &.open{

        opacity: 0;
        animation: aparecer 0.5s forwards;
    }
    &.close{

        opacity: 1;
        animation: desaparecer 0.5s forwards;
    }

    @keyframes aparecer {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes desaparecer {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    &.hide{
        display: none;
    }
    &.show{
        display: flex;
    }
`

const Backdrop = ({ open, onClick, hide, setHide }) => {

    const backdrop = useRef(null);

    useEffect(() => {
        
        let backdrop_current = backdrop.current;

        backdrop_current.addEventListener("animationend", handleEndAnimation, false);
    
      return () => {
        
        backdrop_current.removeEventListener("animationend", handleEndAnimation);

      }
    }, [])

    const handleEndAnimation = (e) => {
        
        if(e.animationName === 'desaparecer'){
            onClick();
        }

    }
    
    const handleClose = () => {

        setHide(true);

    }

    return (
        <BackdropStyled 
            ref={backdrop}
            className={`${hide ? 'close' : 'open'}`}
            onClick={handleClose}
        />
    );
    
};

Backdrop.propTypes = {
    onClick: PropTypes.func,
    open: PropTypes.string,
    hide: PropTypes.string,
    setHide: PropTypes.func,
};

export default Backdrop;