import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//import { colors, maringSizes, paddingSizes } from '../../../../helpers/commons';
import { colors, maringSizes, paddingSizes } from '../../../helpers/commons';

const StyledElement = styled.div`

    h1{
        padding: 0;
        margin: 0;
        font-size: 27px;
        line-height: 31px;
        font-weight: 500;
    }
    h2{
        padding: 0;
        margin: 0;
        font-size: 22px;
        line-height: 26px;
        font-weight: 500;
    }
    h3{
        padding: 0;
        margin: 0;
        font-size: 19px;
        line-height: 23px;
        font-weight: 500;
    }
    h4{
        padding: 0;
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
    }
    h5{
        padding: 0;
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }
    h6{
        padding: 0;
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
    }
    p{
        padding: 0;
        margin: 0;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
    }
    small{
        font-size: 13px;
        line-height: 15px;
        font-weight: 400;
    }

    .center{
        text-align: center;    
    }
    .left{
        text-align: left;    
    }
    .right{
        text-align: right;    
    }
    
    &.havelink{
        cursor: pointer;
        :hover{
            color: ${props => `rgba(${props.colors.primary}, 1)` };
        }
    }
    
    
`

const StyledTypography = styled.div`
    font-family: "IBM Plex Sans",-apple-system,BlinkMacSystemFont,Roboto,Arial,sans-serif!important;
    &.important{
        color: ${props => `rgba(${props.colors.important}, 1)` };
    }
    &.cancel{
        color: ${props => `rgba(${props.colors.cancel}, 1)` };
    }
    &.accept{
        color: ${props => `rgba(${props.colors.accept}, 1)` };
    }
    &.default{
        color: ${props => `rgba(${props.colors.default}, 1)` };
    }
    &.primary{
        color: ${props => `rgba(${props.colors.primary}, 1)` };
    }

    margin-top: ${props => (props.margins && props.margins.top) ? 5 * props.margins.top + "px" : 0}!important;
    margin-bottom: ${props => (props.margins && props.margins.bottom) ? 5 * props.margins.bottom + "px" : 0}!important;
    margin-left: ${props => (props.margins && props.margins.left) ? 5 * props.margins.left + "px" : 0}!important;
    margin-right: ${props => (props.margins && props.margins.right) ? 5 * props.margins.right + "px" : 0}!important;

    padding-top: ${props => (props.paddings && props.paddings.top) ? 5 * props.paddings.top + "px" : 0}!important;
    padding-bottom: ${props => (props.paddings && props.paddings.bottom) ? 5 * props.paddings.bottom + "px" : 0}!important;
    padding-left: ${props => (props.paddings && props.paddings.left) ? 5 * props.paddings.left + "px" : 0}!important;
    padding-right: ${props => (props.paddings && props.paddings.right) ? 5 * props.paddings.right + "px" : 0}!important;

`;

export const Typography = ({ onClick, variant, style, children, className = '', textAlign = 'left', type = 'primary', _key, margins = {top: 0, bottom: 0, left: 0, right: 0}, paddings = {top: 0, bottom: 0, left: 0, right: 0} }) => {
    
    return (
        <StyledElement
            key={_key}
            colors={colors}
            onClick={onClick}
            className={`${onClick ? 'havelink' : ''}`}
        >
            <StyledTypography 
                colors={colors}
                as={variant} 
                style={style}
                className={`${type} ${className} ${textAlign}`}
                margins={margins}
                paddings={paddings}
            >
                {children}
            </StyledTypography>
        </StyledElement>
    );
};

Typography.propTypes = {
    _key: PropTypes.string,
    variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'small']),
    className: PropTypes.string,
    onClick: PropTypes.func,
    //size: PropTypes.oneOf(['small', 'medium', 'large']),
    type: PropTypes.oneOf(['important', 'cancel', 'accept', 'default', 'primary', 'form']),
    margins: PropTypes.shape({
        top: PropTypes.oneOf(maringSizes),
        bottom: PropTypes.oneOf(maringSizes),
        left: PropTypes.oneOf(maringSizes),
        right: PropTypes.oneOf(maringSizes),
    }),
    paddings: PropTypes.shape({
        top: PropTypes.oneOf(paddingSizes),
        bottom: PropTypes.oneOf(paddingSizes),
        left: PropTypes.oneOf(paddingSizes),
        right: PropTypes.oneOf(paddingSizes),
    }),
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    textAlign: PropTypes.oneOf(['left', 'center', 'rigth']),
    //margin: PropTypes.string,
};
