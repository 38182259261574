import styled from "styled-components";
import { colors } from "../../helpers/commons";

export const ButtonStyled = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    height: 40px;
    background-color: rgba(${colors.red}, 1);
    color: rgba(${colors.white}, 1);
    border: 1px solid rgba(${colors.red}, 1);
    border-radius: 20px;
    padding: 0 30px;
    cursor: pointer;
    &:hover{
        background-color: rgba(${colors.white}, 1);
        color: rgba(${colors.red}, 1);
    }
    &.send_login{
        width: 150px;
    }
`