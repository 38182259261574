import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { colors } from '../../helpers/commons';

export const WrapperStyled = styled.div`
    .content{
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        width: 100%;
        border: 1px solid #e5e5e5;
        padding: 30px 30px;
        //height: calc(100vh - 120px);
        .title{
            display: flex;
            flex-direction: row;
            align-items: center;
            color: rgba(${colors.darkBlue}, 1);
            .icon{
                width: 30px;
                img{
                    width: 100%;
                    float: left;
                }
            }
            .text{
                font-size: 30px;
                line-height: 30px;
                font-weight: 300;
                margin-left: 10px;
                .highlighted{
                    font-weight: 500;
                }
            }
        }
        .image_benefit{
            display: flex;
            background-color: #C7C7C7;
            padding: 10px;
            margin-top: 20px;
            .inner{
                width: 100%;
                img{
                    width: 100%;
                    float: left;
                }
            }
        }
        .items{
            margin-top: 25px;
            color: rgba(${colors.darkBlue}, 1);
            .item{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 5px;
                .icon{
                    display: flex;
                    font-size: 35px;
                    line-height: 35px;
                }
                .text{
                    font-size: 17px;
                    line-height: 22px;
                    margin-left: 5px;
                    font-weight: 300;
                    .highlighted{
                        font-weight: 500;
                    }
                }
            }
        }
        .terms{
            margin-top: 35px;
            margin-bottom: 5px;
            .text{
                color: #666666;
                .highlighted{
                    font-weight: 500;
                    text-decoration: underline;
                    font-style: italic;
                }
            }
        }
    }

    @media ${breakpoints.xs} {//576px
        .content{
            
        }
    }
    @media ${breakpoints.sm} {//768px
        .content{
            
        }
    }
    @media ${breakpoints.md} {//992px
        .content{
            
        }
    }
    @media ${breakpoints.lg} {//1200px // Desktop
        .content{
            
        }
        
    }
    @media ${breakpoints.xl} {//1366px
        .content{
            
        }
    }
    @media ${breakpoints.xxl} {//1440px
        .content{
            
        }
    }
    @media ${breakpoints.xxxl} {//1680px
        .content{
            
        }
    }
    @media ${breakpoints.xxxxl} {//1920px
    }
`