import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Overlay } from '../../Atoms/Overlay/Overlay';
import { ContainerModal } from '../../Atoms/Container/ContainerModal';
import { ModalHeader } from '../ModalHeader';
import { ModalBody } from '../ModalBody';
import { ModalFooter } from '../ModalFooter';
import { Typography } from '../../Atoms/Typography/Typography';
import { Icon } from '../../Atoms/Icon/Icon';
import { ActionsForm } from '../ActionsForm';
import { ButtonIcon } from '../../Atoms/ButtonIcon/ButtonIcon';

import { MdClose } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";


const WrapperStyled = styled.div`

    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .title{
            margin-left: 5px;
        }
        .bt_close{
            margin-left: auto!important;
        }
    }

    .box_delete{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 150px;
        border: 1px dashed #ccc;
    }
`

const Modal = ({icon = null, withActions = true, component = null, setComponent, className, data, title, description, open, setOpen, handleSuccess, isloading, blockActions, ...props}) => {

    //const [ open, setOpen ] = useState(true);

    const handleClose = () => {
        setOpen(!open);
        if(setComponent){
            setComponent(null);
        }
    }

    if(!open){
        return null;
    }

    return (
        <WrapperStyled
            className={className}
        >
            <Overlay
                open={open}
                onClick={handleClose}
            >
                <ContainerModal>
                    <ModalHeader className={`header`}>
                        <Icon  
                            icon={icon ? icon : <RiDeleteBin6Line />}
                            size={'large'}
                        />
                        <Typography 
                            variant={`h2`}
                            type={`default`}
                            margins={{left: 2}}
                        >
                            { title } 
                        </Typography>

                        <ButtonIcon 
                            className='bt_close'
                            size={`large`}
                            type={`primary`}
                            icon={<MdClose />}
                            onClick={() => handleClose()}
                        />
                        
                    </ModalHeader>
                    <ModalBody
                        margins={{top: 3}}
                    >
                        {component}
                    </ModalBody>
                    {withActions &&
                    <ModalFooter>
                        <ActionsForm 
                            halign={`right`}
                            valign={`top`}
                            margins={{top: 3}}
                            actions={
                                { 
                                    save: {label: "Accept", type: "default", size: "medium", onClick: (e, data) => handleSuccess(e, data), isloading: isloading, blockActions: blockActions},
                                    cancel: {label: "Cancel", type: "cancel", size: "medium", margins:{left: 1}, onClick: () => handleClose()} 
                                }
                            }
                        />
                    </ModalFooter>}
                </ContainerModal>
            </Overlay>
        </WrapperStyled>
    );
};

Modal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    handleSuccess: PropTypes.func,
    handleCancel: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    data: PropTypes.object,
    component: PropTypes.element,
    setComponent: PropTypes.func,
    isloading: PropTypes.bool,
    blockActions: PropTypes.bool, 
    className: PropTypes.string,
};

export default Modal;